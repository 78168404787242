// productsConfig.js

const productsConfig = {
    "sports-insurance": {
      id: 3,
      title: "Sports Insurance",
      has_health_declaration: true,
      has_spouse: true,
      has_parents: true,
      max_children: 3,
      min_age: 18,
      max_age: 65,
      benefits: [
        "Covers accidental injury treatment cost up to INR {sum_insured}",
        "You can also buy for your family members",
      ]
    },
    "air-ambulance": {
      id: 2,
      title: "Air Ambulance",
      has_spouse: true,
      has_parents: true,
      max_children: 3,
      min_age: 18,
      max_age: 65,
      has_health_declaration: false,
    },
    "psychological-counseling": {
        id: 4,
        title: "Psychological Counselling",
        has_spouse: false,
        has_parents: false,
        max_children: 0,
        min_age: 18,
        max_age: 65,
        has_health_declaration: false,
      },
      "super-topup": {
        id: 5,
        title: "Super Top Up",
        has_health_declaration: true,
        has_spouse: true,
        has_parents: false,
        min_age: 18,
        max_age: 65,
        max_children: 2,
      },

  };
  
  export default productsConfig;
  