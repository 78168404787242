// ParentForm.jsx
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthRedirect from "../../../../hooks/useAuthRedirect";
import { useApi } from "../../../../store/services/useApi";
import ROUTES from "../../../../utils/constants/Routes";
import STRINGS from "../../../../utils/constants/strings";
import Spinner from "../../../Common/Loaders/Spinner";
import Bill from "./Bill";
import HealthDeclarationModal from "./HealthDeclarationModal";
import PricingOptions from "./PricingOptions";
import productsConfig from "./productsConfig";
import SuperTopupPremium from "./SuperTopupPremium";
import UserForm from "./UserForm";
import UserRelationshipsForm from "./UserRelationshipsForm";

const cashfree = window.Cashfree({
  mode: process.env.REACT_APP_ENV === "production" ? "production" : "sandbox",
});
const UserDetailsForm = () => {
  const navigate = useNavigate();
  const methods = useForm({ mode: "onChange" });
  const { setValue, getValues } = methods;
  const values = getValues();
  //get user first name and last name from session data

  const { product_url } = useParams();
  const productConfig = productsConfig[product_url];
  let { search, pathname } = useLocation();
  let params = new URLSearchParams(search);
  const sessionData = useSelector((state) => state.session.sessionData);
  const userProducts = useSelector((state) => state.products.userProducts);
  const currentProduct = useSelector((state) => state.products.currentProduct);
  const products = useSelector((state) => state.products.products);
  useAuthRedirect(sessionData, pathname, products, product_url, userProducts, currentProduct, params);


  const [firstName, setFirstName] = useState(sessionData?.fname || "");
  const [familyMembers, setFamilyMembers] = useState(0);
  const [productDetails, setProductDetails] = useState(null);
  const [productId, setProductId] = useState(null);
  const [pricingId, setPricingId] = useState(null);
  const [pricing, setPricing] = useState(null);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [finalPrice, setFinalPrice] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [couponLengthError, setCouponLengthError] = useState(false);
  const [canHaveRelationships, setCanHaveRelationships] = useState(false);
  const [showHealthDeclaration, setShowHealthDeclaration] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [couponApplied, setCouponApplied] = useState(null)
  const [premiumDetails, setPremiumDetails] = useState(null);
  const [dob, setDob] = useState("");
  const [formData, setFormData] = useState({
    relationships: {
      spouse: {
        include: false,
        full_name: "",
        date_of_birth: "",
        comm_change: false,
        gender: "",
        mobile: "",
        email: "",
        address: "",
      },
      mother: {
        include: false,
        full_name: "",
        date_of_birth: "",
        comm_change: false,
        gender: "female",
        mobile: "",
        email: "",
        address: "",
      },
      father: {
        include: false,
        full_name: "",
        date_of_birth: "",
        comm_change: false,
        gender: "male",
        mobile: "",
        email: "",
        address: "",
      },
      children: { include: false, details: [] },
    },
  });

  const {
    data: orderDetails,
    error: orderError,
    isLoading: creatingOrder,
    makeCall: createOrder,
  } = useApi("POST", "/create-order");

  const {
    data: productPricingDetails,
    error: pricingError,
    isLoading: productPricingloading,
    makeCall: fetchProductPricingDetails,
  } = useApi("GET", "/product/get-details/" + product_url);

  const {
    data: superTopupPricing,
    error: superTopupPricingError,
    isLoading: superTopupPricingLoading,
    makeCall: fetchSuperTopupPricing
  } = useApi("POST", "/product_pricing/supertop");

  const {
    data: couponDetails,
    error: couponError,
    isLoading: couponLoading,
    makeCall: validateDiscountCode,
  } = useApi("POST", "/validate-discount-code");

  useEffect(() => {
    if (couponDetails) {
      setCouponApplied(couponDetails)
    }
  }, [couponDetails])

  useEffect(() => {
    if (product_url) {
      let product_data = STRINGS[product_url]
      if (product_data) {
        setProductDetails(product_data);
      }
    }
  }, [product_url])

  useEffect(() => {
    if (
      orderDetails &&
      orderDetails.order &&
      orderDetails.order.pg_session_id
    ) {
      cashfree.checkout({
        paymentSessionId: orderDetails.order.pg_session_id,
        redirectTarget: "_self",
      });
    }
  }, [orderDetails]);

  useEffect(() => {
    if (orderError) {
      toast.error("Something went wrong, please try again", {
        duration: 5000,
        position: "top-center",
      });
    }
  }, [orderError]);


  useEffect(() => {
    const storedDetails = localStorage.getItem('premiumDetails');
    if (storedDetails) {
      const parsedDetails = JSON.parse(storedDetails);
      setPremiumDetails(parsedDetails);
    } else if (productConfig.id === 5) {
      navigate("/super-topup/premium");
    }
  }, [productConfig.id]);

  useEffect(() => {
    if (premiumDetails) {
      console.log("premiumDetails state", premiumDetails);
    }
  }, [premiumDetails]);


  useEffect(() => {
    if (pricingError) {
      navigate(ROUTES.NOT_FOUND);
    }
    if (product_url !== "super-topup" && productPricingDetails === null && productPricingloading === false) {
      fetchProductPricingDetails();
    } else if (
      product_url !== "super-topup" &&
      productPricingDetails.pricing &&
      productPricingDetails.pricing.length > 0
    ) {
      // console.log(productPricingDetails)
      // Find the first pricing record where price is not 0
      const validPricing = productPricingDetails.pricing.filter(price => parseFloat(price.price) !== 0 && price.is_active === 1);

      setPricing(validPricing)

      // Set initial selected pricing
      if (validPricing.length > 0) {
        const lastValidPricing = validPricing[validPricing.length - 1];
        setSelectedPricing(lastValidPricing);
        setPricingId(lastValidPricing.id);
        setFinalPrice(lastValidPricing.price);
      }

      setProductId(productPricingDetails.id);
      setCanHaveRelationships(productPricingDetails.can_have_relationships);
    }
  }, [productPricingDetails, pricingError]);


  useEffect(() => {
    formData.relationships["spouse"].gender =
      values.gender === "male" ? "female" : "male";
  }, [values.gender]);

  // const {
  //     data: userRelationships,
  //     error: relationshipsError,
  //     isLoading: relationshipsLoading,
  //     makeCall: fetchUserRelationships,
  // } = useApi("GET", "/user/relationships");
  useEffect(() => {
    if (
      orderDetails &&
      orderDetails.order &&
      orderDetails.order.pg_session_id
    ) {
      cashfree.checkout({
        paymentSessionId: orderDetails.order.pg_session_id,
        redirectTarget: "_self",
      });
      // console.log(vlue);
    }
  }, [orderDetails]);
  // const {
  //     data: userRelationships,
  //     error: relationshipsError,
  //     isLoading: relationshipsLoading,
  //     makeCall: fetchUserRelationships,
  // } = useApi("GET", "/user/relationships");

  // useEffect(() => {
  //     if (userRelationships === null && relationshipsLoading === false) {
  //         fetchUserRelationships();
  //     }
  // }, [userRelationships]);

  // let values = getValues();

  // useEffect(() => {
  //     console.log(values);
  // }, [values]);

  useEffect(() => {
    console.log('Updated form data:', formData);
  }, [formData, dob]);

  const onSubmit = (submittedData) => {
    let orderData = Object.assign({}, submittedData)
    //create order details
    orderData.product_pricing_id = pricingId;
    orderData.amount = finalPrice;
    orderData.product_id = productId;
    orderData.discount_id = couponApplied?.id || null;
    orderData.discount_coupon_id = couponApplied?.discount_coupon_id || null;
    orderData.relationships = {}
    const hasHealthDeclaration = productConfig?.has_health_declaration || false;
    // check in formData if comm_change is selected then only include the details
    if (submittedData.relationships) {
      for (let relation of Object.keys(submittedData.relationships)) {
        //   for children in details
        if (relation === "children" && formData.relationships.children.include) {
          orderData.relationships['children'] = formData.relationships.children;
        } else if (relation !== "children") {
          if (formData.relationships[relation]['include'] === true) {
            orderData.relationships[relation] = formData.relationships[relation];
          }
        }
      }
    }

    // createOrder(orderData);
    // console.log("Final order data", orderData)

    if (hasHealthDeclaration) {
      // Show health declaration popup
      setOrderData(orderData); // Store orderData to be used after accepting
      // console.log("Order data if popup:", orderData)
      setShowHealthDeclaration(true);
    } else {
      // Directly create order without showing popup
      createOrder(orderData);
      // console.log("order data if no popup", orderData);
    }
  };

  const handleAccept = () => {
    setShowHealthDeclaration(false);
    createOrder(orderData);
    // console.log("Final order data", orderData);
  };

  const handleDecline = () => {
    setShowHealthDeclaration(false);
  };

  useEffect(() => {
    if (sessionData) {
      setFirstName(sessionData.fname);
      setValue("fullName", sessionData.fname + " " + sessionData.lname);
      setValue("mobile", sessionData.mobile);
      setValue("email", sessionData.email);
      setValue("dob", sessionData.dob ? formatDOB(sessionData.dob) : "None");
      setValue("gender", sessionData.gender);
      setValue("pincode", sessionData.pincode);
      setValue("address", sessionData.address);
      formData.relationships["spouse"].gender = !sessionData.gender;
    }
  }, [sessionData]);

  function formatDOB(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  // useEffect(() => {
  //   if (couponApplied) {
  //     if (couponApplied?.discount_amount && couponApplied?.discount_amount > 0) {
  //       setFinalPrice(finalPrice - (couponApplied.discount_amount * (familyMembers + 1)))
  //     } else if (couponApplied?.discount_percentage && couponApplied?.discount_percentage > 0) {
  //       setFinalPrice(finalPrice - (finalPrice * (couponApplied.discount_percentage / 100)).toFixed(2));
  //     }
  //   } else {
  //     setFinalPrice(selectedPricing ? selectedPricing.price * (familyMembers + 1) : 0);
  //   }
  // }, [couponApplied, familyMembers]);

  useEffect(() => {
    if (productConfig.id !== 5) { //id 5 is for "super top-up"
      if (couponApplied) {
        if (couponApplied?.discount_amount && couponApplied?.discount_amount > 0) {
          setFinalPrice(finalPrice - (couponApplied.discount_amount * (familyMembers + 1)));
        } else if (couponApplied?.discount_percentage && couponApplied?.discount_percentage > 0) {
          setFinalPrice(finalPrice - (finalPrice * (couponApplied.discount_percentage / 100)).toFixed(2));
        }
      } else {
        setFinalPrice(selectedPricing ? selectedPricing.price * (familyMembers + 1) : 0);
      }
    }
    else if (productConfig.id == 5) {
      setFinalPrice(selectedPricing);
    }
  }, [couponApplied, familyMembers, finalPrice, selectedPricing, productConfig]);


  const countIncludedFlags = (relationship) => {
    let count = 0;
    // Loop through each property in the object
    for (const key in relationship) {
      if (relationship.hasOwnProperty(key)) {
        const value = relationship[key];
        if (
          key === "children" &&
          value.details &&
          Array.isArray(value.details)
        ) {
          count += value.details.length;
        }
        // Check if the 'include' flag is true
        else if (
          value &&
          typeof value === "object" &&
          value.hasOwnProperty("include") &&
          value.include === true
        ) {
          count++;
          // Check if the relationship is 'children' and add the length of children details to the count
        }
      }
    }

    return count;
  };

  useEffect(() => {
    let membersCount = countIncludedFlags(formData.relationships);
    setFamilyMembers(membersCount);
  }, [formData]);

  //TODO: get this value from the db, send through the api
  useEffect(() => {
    if (productConfig.id === 5) {
      setCanHaveRelationships(1);
    }

  }, [productConfig]);

  useEffect(() => {
    if (productConfig.id === 5 && dob) {
      const userDOB = dob
      const spouseDOB = formData.relationships.spouse.include
        ? formData.relationships.spouse.date_of_birth
        : null;

      let olderDOB = userDOB;

      if (userDOB && spouseDOB) {
        olderDOB = userDOB < spouseDOB ? userDOB : spouseDOB;
      }

      const payload = {
        cover: premiumDetails?.sumInsured,
        deductible: premiumDetails?.deductible,
        dob: formatDOB(olderDOB),
        family: [
          formData.relationships.spouse.include ? "Spouse" : '',
          formData.relationships.children.include ? "Children" : '',
        ].filter(Boolean),
        kids: formData.relationships.children.include
          ? formData.relationships.children.details.length
          : 0,
      };

      console.log("Payload:", payload);
      fetchSuperTopupPricing(payload);
      console.log("super top up pricing", superTopupPricing);
    }
  }, [formData, dob, productConfig, premiumDetails]);

  useEffect(() => {
    if (superTopupPricing && superTopupPricing.data) {
      const validPricing = superTopupPricing.data.filter(
        (price) => parseFloat(price.price) !== 0 && price.is_active === 1
      );

      if (validPricing.length > 0) {
        const lastValidPricing = validPricing[validPricing.length - 1];
        setSelectedPricing(lastValidPricing.price);
        setPricingId(lastValidPricing.id);
        setFinalPrice(lastValidPricing.price);
        setCanHaveRelationships(1);
        setProductId(lastValidPricing.product_id);
      }
    }
  }, [superTopupPricing]);

  // useEffect(() => {
  //   setFinalPrice(pricing * (familyMembers + 1));
  // }, [familyMembers]);

  useEffect(() => {

    if (couponApplied?.discount_amount && couponApplied?.discount_amount > 0) {
      setFinalPrice(selectedPricing ? ((selectedPricing.price * (familyMembers + 1) - (couponApplied?.discount_amount * (familyMembers + 1)))) : 0);
    } else {
      setFinalPrice(selectedPricing ? selectedPricing.price * (familyMembers + 1) : 0);
    }
  }, [familyMembers, selectedPricing]);

  const updateFormData = (relationship, field, value) => {
    setFormData((prevFormData) => {
      if (relationship === "children" && field === "details") {
        return {
          ...prevFormData,
          relationships: {
            ...prevFormData.relationships,
            children: {
              ...prevFormData.relationships.children,
              details: value,
            },
          },
        };
      } else {
        return {
          ...prevFormData,
          relationships: {
            ...prevFormData.relationships,
            [relationship]: {
              ...prevFormData.relationships[relationship],
              [field]: value,
            },
          },
        };
      }
    });
  };


  const handleDiscountCode = (discountCode) => {
    if (discountCode?.length > 0) {
      setCouponLengthError(false);
      validateDiscountCode({ discount_code: discountCode, product_pricing_id: selectedPricing?.id });
    } else {
      setCouponLengthError(true);
    }
  };

  const handlePricingSelect = (price) => {
    setSelectedPricing(price);
    setPricingId(price.id);
    setFinalPrice(price.price);
  };

  if (!productPricingDetails && product_url !== "super-topup") {
    return <Spinner />;
  } else {
    return (
      <FormProvider {...methods}>
        <Toaster />
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="space-y-8 py-8 md:w-10/12 w-11/12"
        >
          <div className="bg-white shadow-md rounded-lg">
            <UserForm name={firstName}
              productDetails={productDetails}
              productConfig={productConfig}
              onDOBChange={(newDOB) => setDob(newDOB)} />
          </div>
          {/* <div className="bg-white shadow-md rounded-lg">
            <UserRelationshipsForm
              formData={formData}
              updateFormData={updateFormData}
            />
          </div> */}

          {/* Conditionally render UserRelationshipsForm */}
          {canHaveRelationships ? (
            <div className="bg-white shadow-md rounded-lg">
              <UserRelationshipsForm
                formData={formData}
                updateFormData={updateFormData}
                productConfig={productConfig}
              />
            </div>
          ) : null}

          {productPricingDetails && pricing && pricing.length > 1 && (
            <PricingOptions
              product_url={product_url}
              pricing={pricing}
              selectedPricing={selectedPricing}
              handlePricingSelect={handlePricingSelect}
            />
          )}

          {premiumDetails && productConfig.id === 5 && (
            <SuperTopupPremium
              sumInsured={premiumDetails.sumInsured}
              deductible={premiumDetails.deductible}
            />
          )}

          <div className="w-full">
            <Bill
              familyMembers={familyMembers}
              productDetails={productDetails}
              coupon={coupon}
              setCoupon={setCoupon}
              setCouponApplied={setCouponApplied}
              handleDiscountCode={handleDiscountCode}
              couponError={couponError}
              couponLengthError={couponLengthError}
              couponLoading={couponLoading}
              creatingOrder={creatingOrder}
              couponApplied={couponApplied}
              productPricingDetails={productPricingDetails || superTopupPricing}
              finalPrice={finalPrice}
            />
          </div>
        </form>
        {showHealthDeclaration && (
          <HealthDeclarationModal
            onAccept={handleAccept}
            onDecline={handleDecline}
          />
        )}
      </FormProvider>
    );
  }
};

export default UserDetailsForm;
