import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/config";

export const dashboardApi = createApi({
	reducerPath: "dashboardSlice",
	baseQuery: fetchBaseQuery({
		baseUrl: getBaseUrl,
		prepareHeaders: (headers) => {
			// Add Basic Authentication header
			headers.set(
				"Authorization",
				`${window?.localStorage.getItem("userid")}`
			);
			return headers;
		},
	}),
	tagTypes: ["Post"],
	endpoints: (builder) => ({
		dashboardPost: builder.mutation({
			query: (payload) => ({
				url: "/user/dashboard",
				method: "POST",
				body: payload,
				headers: {
					"Content-type": "application/json; charset=UTF-8",
				},
			}),
			invalidatesTags: ["Post"],
		}),
	}),
});

export const { useDashboardPostMutation } = dashboardApi;
