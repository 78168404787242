import STRINGS from "./strings";
import IMAGE_URLS from "./images";

const QUESTIONS = [
    {
        id: STRINGS.Questions.question_0.id,
        title: STRINGS.Questions.question_0.title,
        meta_title: STRINGS.Questions.question_0.meta_title,
        text: STRINGS.Questions.question_0.text,
        options: STRINGS.Questions.question_0.options,
        imageUrl: IMAGE_URLS.questions.question_0,
    },
    {
        id: STRINGS.Questions.question_1.id,
        title: STRINGS.Questions.question_1.title,
        meta_title: STRINGS.Questions.question_1.meta_title,
        text: STRINGS.Questions.question_1.text,
        options: STRINGS.Questions.question_1.options,
        imageUrl: IMAGE_URLS.questions.question_1,
    },
    {
        id: STRINGS.Questions.question_2.id,
        title: STRINGS.Questions.question_2.title,
        meta_title: STRINGS.Questions.question_2.meta_title,
        message: STRINGS.Questions.question_2.message,
        text: STRINGS.Questions.question_2.text,
        options: STRINGS.Questions.question_2.options,
        imageUrl: IMAGE_URLS.questions.question_2,
    },
    {
        id: STRINGS.Questions.question_3.id,
        title: STRINGS.Questions.question_3.title,
        meta_title: STRINGS.Questions.question_3.meta_title,
        message: STRINGS.Questions.question_3.message,
        text: STRINGS.Questions.question_3.text,
        options: STRINGS.Questions.question_3.options,
        imageUrl: IMAGE_URLS.questions.question_3,
    },
    // {
    //     id: STRINGS.Questions.question_4.id,
    //     title: STRINGS.Questions.question_4.title,
    //     meta_title: STRINGS.Questions.question_4.meta_title,
    //     options: STRINGS.Questions.question_4.options,
    //     text: STRINGS.Questions.question_4.text,
    //     message: STRINGS.Questions.question_4.message,
    //     sub_questions: STRINGS.Questions.question_4.sub_questions,
    //     imageUrl: IMAGE_URLS.questions.question_4,
    // },
    // {
    //     id: STRINGS.Questions.question_5.id,
    //     title: STRINGS.Questions.question_5.title,
    //     text: STRINGS.Questions.question_5.text,
    //     sub_questions: STRINGS.Questions.question_5.sub_questions,
    //     plan_questions: STRINGS.Questions.question_5.plan_questions,
    //     imageUrl: IMAGE_URLS.questions.question_5,
    // }
];

export default QUESTIONS;