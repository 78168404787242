import React from 'react';

const Error = () => {

    const refreshPage = () => {
        window.location.reload(); // This reloads the page
    };

    return (
        <>
            Something went wrong
            <button
                className={`text-lg rounded px-10 py-3 w-full lg:w-auto bg-[#F4CD2A] text-black cursor-pointer mt-2 block`}
                onClick={refreshPage}
            >
                Try again
            </button>
        </>
    );
};

export default Error;