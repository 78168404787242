import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import { useApi } from "../../store/services/useApi";
import ORDER_STATUS from '../../utils/constants/order';
import Spinner from '../Common/Loaders/Spinner';

const OrderRedirect = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('orderid');
    const [orderPending, setOrderPending] = useState(true);
    const [orderExisting, setOrderExisting] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [orderFailed, setOrderFailed] = useState(false);

    const { product_url } = useParams();
    let { search, pathname } = useLocation();
    let params = new URLSearchParams(search);
    const navigate = useNavigate();
    const sessionData = useSelector((state) => state?.session?.sessionData) || null;
    const userProducts = useSelector((state) => state.products.userProducts);
    const currentProduct = useSelector((state) => state.products.currentProduct);
    const products = useSelector((state) => state.products.products);

    useAuthRedirect(sessionData, pathname, products, product_url, userProducts, currentProduct, params);

    const {
        data: orderStatusData,
        error: orderStatusError,
        isLoading: orderStatusLoading,
        makeCall: checkOrderStatus,
    } = useApi("POST", "/refresh-order-status");


    useEffect(() => {
        if (orderId && process.env.REACT_APP_ENV === "production") {
            window.gtag('config', 'AW-16621963303');
        }
    }, [])


    useEffect(() => {
        if (sessionData && orderId) {
            checkOrderStatus({ order_id: orderId });
        }
    }, [sessionData, orderId]);


    useEffect(() => {
        if (orderStatusData) {
            let orderData = orderStatusData;

            // set success and failure here.
            if (orderData.order.status === ORDER_STATUS.success) {
                setOrderSuccess(true);
                setOrderPending(false);
            }
            if (orderData.order.status === ORDER_STATUS.failed) {
                setOrderFailed(true);
                setOrderPending(false);
            }
            if (orderData.order.status === ORDER_STATUS.pending) {
                setOrderFailed(true);
                setOrderPending(false);
            }
        }
    }, [orderStatusData]);

    if (orderPending || orderStatusLoading) {
        return <Spinner />;
    }
    if (orderSuccess) {
        navigate(`/${product_url}/order/success?orderid=${orderId}`);
    }

    if (orderExisting) {
        navigate(`/${product_url}/order/success?orderid=${orderId}`);
    }

    if (orderFailed) {
        navigate(`/${product_url}/order/failed?orderid=${orderId}`);
    }

    return null;
};

export default OrderRedirect;
