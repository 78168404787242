import React from 'react'
import { PRE_EXISTING_COUPON_CODES } from '../../../../utils/constants/coupons';
import SpinnerLoader from '../../../Checkout/SpinnerLoader';

import discount from "../../../../assets/images/discount.svg";
import { useSelector } from 'react-redux';

const Coupon = ({ coupon, couponApplied, setCoupon, setCouponApplied, couponError, couponLengthError, couponLoading, productPricingDetails, handleDiscountCode }) => {

    const userProducts = useSelector((state) => state.products.userProducts);

    const productId = productPricingDetails?.data?.[0]?.product_id || productPricingDetails?.id;

    // Check if the productPricingDetails.id exists in userProducts and is converted
    const isProductConverted = userProducts?.some(userProduct => 
        userProduct.product_id === productId && userProduct.is_converted);

    // If the product is converted, don't show the Coupon component or if the product is psychology
    if (isProductConverted || productId === 4) {
        return null;
    }

    const handleCouponRemove = () => {
        setCouponApplied(null);
        setCoupon("");
    }

    return (
        <div>
            <div className="flex items-center justify-between gap-2 sm:gap-4 py-5">
                <div className="flex flex-col gap-1">
                    <p className="text-sm sm:text-base">Coupons</p>
                    <p className="text-xs sm:text-sm text-[#777575]">
                        Have a Gpay Coupon Code?
                    </p>
                </div>
                <div className="text-right">
                    <div className="relative flex items-center gap-2 lg:w-full">
                        <input
                            type="text"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                            className="border-2 rounded-md p-2 w-full sm:w-[200px]"
                        />
                        {couponApplied == null ?
                            (
                                <div className="absolute right-2">
                                    {couponLoading ? (
                                        <SpinnerLoader size="small" color="#066C42" />
                                    ) : (
                                        <button
                                            type="button"
                                            onClick={() => handleDiscountCode(coupon)}
                                            className=" text-[#066C42] rounded-md font-normal text-sm"
                                        >
                                            Apply
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className="absolute right-2">
                                    <button
                                        type="button"
                                        onClick={handleCouponRemove}
                                        className=" text-[#066C42] rounded-md font-normal text-sm"
                                    >
                                        Remove
                                    </button>
                                </div>
                            )
                        }
                    </div>
                    {couponApplied && (
                        <p className="text-sm sm:text-base text-[#066C42]">
                            Coupon applied
                        </p>
                    )}
                    {(couponError || couponLengthError) && !couponApplied && (
                        <p className="text-sm sm:text-base text-[#FF0000] text-center w-[200px] py-2">
                            {couponError}
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-col items-start justify-center gap-2 sm:gap-4 pb-5">
                {!couponApplied &&
                    PRE_EXISTING_COUPON_CODES?.map((coupon, index) => {
                        let isValidForUser = userProducts?.length > 0 ? userProducts?.find(userProduct => (userProduct.product_id === coupon.product_id && userProduct.is_converted)) ?? [] : [];
                        if (isValidForUser?.length === 0) {
                            return (
                                coupon.products.includes(productId) && (
                                    <>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-xs sm:text-sm text-[#777575]">Or use our coupon code</p>
                                    </div>
                                    <div className="border border-[#066C42] w-full flex items-center justify-between gap-2 sm:gap-4 py-4 px-4 sm:px-6">
                                        <div className="flex items-center justify-start gap-2 sm:gap-4">
                                            <div>
                                                <img src={discount} alt="discount" className="w-12 h-14" />
                                            </div>
                                            <div className="flex flex-col items-start justify-center gap-1">
                                                <p className="text-sm sm:text-base text-[#207058] font-bold">{coupon?.discount_code}</p>
                                                <p className="text-xs sm:text-sm text-[#777575]">
                                                    {coupon?.discount_description}
                                                </p>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setCoupon(coupon?.discount_code);
                                                handleDiscountCode(coupon?.discount_code);
                                            }}>
                                            <p className="text-sm sm:text-base text-[#207058] font-bold">APPLY</p>
                                        </button>
                                    </div>
                                    </>
                                )
                            )
                        }
                    })
                }

            </div>
        </div>
    )
}

export default Coupon