// Handling the business logic of relatives question
import React from "react";
import moment from "moment";
import STRINGS from "../constants/strings";

export const handleRelativesClick = (value, selectedValues, setValues) => {
  // Function to handle option selection for questions
  if (selectedValues.includes(value)) {
    // If the value is already selected, remove it
    setValues(selectedValues.filter((v) => v !== value));
  } else {
    if (value === "None") {
      // If 'None' is selected, deselect all others values
      setValues(["None"]);
    } else if (selectedValues.includes("None")) {
      // If 'None' is already selected and another value is selected, deselect 'None'
      setValues([value]);
    } else {
      // If the value is not selected, add it
      setValues([...selectedValues, value]);
    }
  }
};

export const handleYesNoClick = (value, selectedValue, setValue) => {
  // Function to handle yes/no option selection
  if (value === selectedValue) {
    // If the clicked option is already selected, deselect it
    setValue("");
  } else {
    // If the clicked option is not selected, select it and deselect the other option
    setValue(value);
  }
};

export const renderInputBox = (
  type = "",
  value = "",
  onchange = () => { },
  metadata = {},
  inputmode = ""
) => {
  const { placeholder, min, max } = metadata;
  const minVal = type === "number" ? min : null;
  const maxVal = type === "number" ? max : null;
  return (
    <input
      type={type}
      inputmode={inputmode}
      placeholder={placeholder}
      value={value}
      onChange={onchange}
      min={minVal}
      max={maxVal}
      className={"border p-4 w-full rounded-2xl"}
      autoComplete={"off"}
    />
  );
};

export const handleChildAgeValue = (
  event,
  stateKey,
  selectedValue,
  setValues
) => {
  setValues({ ...selectedValue, [stateKey]: event.target.value });
};

export const renderChildInput = (
  label,
  stateKey,
  selectedValues,
  setValues
) => (
  <div className={"mt-5"}>
    <p className={"mb-2"}>{label}</p>
    {renderInputBox(
      "number",
      selectedValues[stateKey],
      (event) =>
        handleChildAgeValue(event, stateKey, selectedValues, setValues),
      {
        placeholder: STRINGS.Questions.question_1.placeholder,
        min: 0,
        max: 100,
      }
    )}
  </div>
);

export const renderChildrenSection = (
  hasChildWithValue1,
  hasChildWithValue2,
  hasChildWithValue3,
  selectedValues,
  setValues,
  isUserBureauVerified = true
) => {
  const _isUserBureauVerified = isUserBureauVerified
    ? STRINGS.Questions.question_1
    : STRINGS.PanQuestions.question_4;
  if (hasChildWithValue1) {
    return renderChildInput(
      _isUserBureauVerified.options[0].sub_questions[0].title,
      _isUserBureauVerified.options[0].sub_questions[0].state_key,
      selectedValues,
      setValues
    );
  } else if (hasChildWithValue2) {
    return (
      <>
        {renderChildInput(
          _isUserBureauVerified.options[1].sub_questions[0].title,
          _isUserBureauVerified.options[1].sub_questions[0].state_key,
          selectedValues,
          setValues
        )}
        {renderChildInput(
          _isUserBureauVerified.options[1].sub_questions[1].title,
          _isUserBureauVerified.options[1].sub_questions[1].state_key,
          selectedValues,
          setValues
        )}
      </>
    );
  } else if (hasChildWithValue3) {
    return (
      <>
        {renderChildInput(
          _isUserBureauVerified.options[2].sub_questions[0].title,
          _isUserBureauVerified.options[2].sub_questions[0].state_key,
          selectedValues,
          setValues
        )}
        {renderChildInput(
          _isUserBureauVerified.options[2].sub_questions[1].title,
          _isUserBureauVerified.options[2].sub_questions[1].state_key,
          selectedValues,
          setValues
        )}
      </>
    );
  } else {
    return null;
  }
};

export const handleQuestionSubmit = (
  currentQuestion,
  totalQuestions,
  setValues,
  previousQuestion,
  setPreviousQuestion,
  relativesValues,
  isNonVerifiedUser = false
) => {
  if (previousQuestion && currentQuestion > 0) {
    if (
      currentQuestion === (!isNonVerifiedUser ? 2 : 5) &&
      !relativesValues.includes("Children")
    ) {
      setPreviousQuestion(true);
      setValues((prevQuestion) => prevQuestion - 2);
    } else {
      setPreviousQuestion(false);
      setValues((prevQuestion) => prevQuestion - 1);
    }
  } else if (currentQuestion < totalQuestions) {
    setPreviousQuestion(false);
    setValues((prevQuestion) => prevQuestion + 1);
  }
};

export const renderSelectBox = (label = "", options = [], metadata = {}) => {
  const { htmlFor, id, name, value, onchange } = metadata;
  return (
    <>
      <label htmlFor={htmlFor} className={"hidden"}>
        {label}
      </label>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onchange}
        className={"border p-4 w-full rounded-2xl"}
      >
        {options.map((item) => {
          const { value, label } = item;
          return (
            <option value={value} key={value}>
              {label}
            </option>
          );
        })}
      </select>
    </>
  );
};

export const handleChildrenClick = (
  value,
  selectedValues,
  setValues,
  isRepeatedUser,
  setIsRepeatedUser
) => {
  if (isRepeatedUser) {
    setIsRepeatedUser(false);
  }
  // Function to handle option selection for questions
  if (selectedValues.includes(value)) {
    // If the value is already selected, remove it
    setValues(selectedValues.filter((v) => v !== value));
  } else {
    // If the value is not selected, add it
    setValues([value]);
  }
};

export const handleValues = (event, setValues, panNumber = false) => {
  if (panNumber) setValues(event.target.value.toUpperCase());
  else setValues(event.target.value);
};

export const eldestYoungestAgeError = (
  eldestChildAge,
  youngestChildAge,
  twoChild = false
) =>
  Number(eldestChildAge) < Number(youngestChildAge)
    ? !twoChild
      ? STRINGS.ErrorMessages.eldestYoungestChildError
      : STRINGS.ErrorMessages.firstSecondChildError
    : "";

//Validate Child Age is below 18 years - allows ages upto 45
export const isChildAgeValidError = (
  firstChildAge,
  youngestChildAge,
  oneChild = false
) => {
  // const validChildAge = (childAge) => /^(1[0-7]|[1-9])$/.test(childAge);
  const validChildAge = (childAge) => /^(4[0-5]|[1-3]?[0-9])$/.test(childAge);
  if (oneChild) {
    if (!validChildAge(Number(firstChildAge)))
      return STRINGS.ErrorMessages.invalidChildAgeError;
  } else {
    if (
      !validChildAge(Number(firstChildAge)) ||
      !validChildAge(Number(youngestChildAge))
    )
      return STRINGS.ErrorMessages.invalidChildAgeError;
  }
};

export const retirementAgeError = (retirementAge = 0, currentAge) => {
  const currentAgeNumber = Number(currentAge);
  const retirementAgeNumber = Number(retirementAge);

  if (retirementAgeNumber > 80) {
    return STRINGS.ErrorMessages.MaxRetirementAgeError;
  }

  if (currentAgeNumber) {
    if (retirementAgeNumber < currentAgeNumber + 5) {
      return STRINGS.ErrorMessages.CurrentRetirementAgeError;
    }
  } else {
    if (retirementAgeNumber < 40 && retirementAgeNumber > 0) {
      return STRINGS.ErrorMessages.MinRetirementAgeError;
    }
  }

  return "";
};

export const isValidDate = (date_of_birth) => {
  const dob = new Date(date_of_birth);
  return dob.getDate() && dob.getMonth() && dob.getFullYear();
};

export const validateAddress = (address) => {
  const addressRegex = /^[A-Za-z0-9#&/.,\s]+$/;
  // Remove spaces from the address for length validation
  const addressWithoutSpaces = address.replace(/\s+/g, '');

  if (addressWithoutSpaces.length < 10) {
    return "Address must be at least 10 characters";
  }
  if (addressWithoutSpaces.length > 120) {
    return "Address must be less than 120 characters";
  }
  return addressRegex.test(address) || "Only letters, numbers, spaces and #, &, /, . are allowed";
};

export const validateName = (value) => {
  const regex = /^[A-Za-z\s]+$/;
  let isValidName = regex.test(value)
  if (isValidName) {
    let valSplit = value?.split(" ");
    console.log(valSplit);
    // now remove entries with empty spaces
    let valueDup = valSplit?.filter((item) => item !== "");
    // count the number of words
    let valLength = valueDup?.length;
    if (valLength <= 1) {
      return "Full name is required";
    } else {
      return true;
    }
  } else {
    return "Only letters and spaces are allowed"
  }
};

export const validateAgeForAdults = (date_of_birth, productConfig) => {
//   const minAge = productConfig?.min_age || 18;
//   const maxAge = productConfig?.max_age || 65;

  const today = moment();
  const minDate = today.clone().subtract(18, 'years');
  const maxDate = today.clone().subtract(65, 'years');

  let isAdultAgeValid = moment(date_of_birth).isBetween(maxDate, minDate);

  return isAdultAgeValid;
};

export const validateAgeForChildren = (birthDate) => {
  const today = moment();

  // Calculate the minimum age date (91 days ago)
  const minAge = today.clone().subtract(91, 'days');

  // Calculate the maximum age date (25 years ago)
  const maxAge = today.clone().subtract(25, 'years');

  let isChildAgeValid = moment(birthDate).isBetween(maxAge, minAge);

  return isChildAgeValid;
}
