import dependants from '../../assets/images/dependants-img.png';
import children from '../../assets/images/children-img.png';
import retire from '../../assets/images/retire-img.png';
import income from '../../assets/images/income-img.png';
import health_1 from '../../assets/images/health-img.png';
import health_2 from '../../assets/images/health-img.png';
import age from '../../assets/images/age-image.png';
import email from '../../assets/images/email-image.png';
import pincode from '../../assets/images/pincode-image.png';



const IMAGE_URLS = {
    questions: {
        question_0: dependants,
        question_1: children,
        question_2: retire,
        question_3: income,
        question_4: health_1,
        question_5: health_2,
    },
    pan_questions: {
        question_0: age,
        question_1: income,
        question_2: pincode,
        question_3: dependants,
        question_4: children,
        question_5: retire,
        question_6: email,
        question_7: health_1,
        question_8: health_2,

    },
};

export default IMAGE_URLS;
