import React, { useEffect, useState } from "react";
import STRINGS from "../../../../utils/constants/strings";
import loginImg from "../../../../assets/images/login.svg";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../../utils/constants/Routes";
import { isPanNumberValid } from "../../../../utils/validations";
import { useRecheckPanPostMutation } from "../../../../store/services/recheckPan";
import Spinner from "../../../Common/Loaders/Spinner";
import { setRecheckPanData } from "../../../../store/slices/recheckPanSlice";
import { useDispatch } from "react-redux";

const Pan = () => {
	const [panNumber, setPanNumber] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const numInputs = 10;

	// Use the useRecheckPanPostMutation hook
	const [recheckPanPost, { data, error, isLoading }] =
		useRecheckPanPostMutation();

	// Calculate whether the button should be disabled or not
	const isButtonDisabled = isPanNumberValid(panNumber.trim().toUpperCase());
	const _addBtnDisableClass = isButtonDisabled ? "disabled:opacity-25" : "";

	const handlePan = () => {
		recheckPanPost({
			// "userid" : localStorage.getItem('userid'),
			panno: panNumber,
		});
	};

	useEffect(() => {
		try {
			if (data) {
				if (data?.isBurea) navigate(ROUTES.IHR_QUESTIONS);
				else if (data?.isBurea !== undefined && !data?.isBurea)
					navigate(ROUTES.IHR_PAN_QUESTIONS);
				else if (data?.maskMobileno) navigate(ROUTES.IHR_PAN_MASK);
				else navigate(ROUTES.IHR_PAN_QUESTIONS);
				dispatch(setRecheckPanData(data));
			}
		} catch (error) {
			console.log(error);
		} finally {
			dispatch(setRecheckPanData(data));
		}
	}, [data]);

	return (
		<>
			{isLoading && <Spinner />}
			<section className="flex justify-between">
				<div className="w-full lg:w-6/12">
					<h2 className="font-bold text-black text-xl sm:text-3xl md:text-4xl lg:text-5xl">
						{STRINGS.Pan.title}
					</h2>
					<div className="lg:pl-10 pt-7">
						<h2 className="text-md">
							{STRINGS.Pan.enter_pan_number}
						</h2>
						<p className="text-sm mt-2 text-gray-600">
							{STRINGS.Pan.ten_digits}
						</p>
						<OtpInput
							value={panNumber}
							onChange={setPanNumber}
							numInputs={numInputs}
							shouldAutoFocus={true}
							renderSeparator={
								<span className={"ml-1 lg:ml-2"} />
							}
							inputStyle={
								"border border-gray-400 w-6 h-7 sm:w-6 md:w-8 lg:w-10 lg:h-10 rounded uppercase"
							}
							containerStyle={"mt-2 mb-3"}
							renderInput={(props) => (
								<input pattern="[A-Za-z0-9]*" {...props} />
							)}
						/>
						<button
							type="submit"
							className={`bg-[#F4CD2A] block rounded py-3 lg:px-20 md:px-20 mt-8 lg:mt-10 w-full md:w-auto lg:w-auto ${_addBtnDisableClass}`}
							disabled={isButtonDisabled}
							onClick={handlePan}
						>
							{STRINGS.Pan.btn_text}
						</button>
					</div>
				</div>

				<div className="hidden lg:block">
					<img src={loginImg} alt="Login" />
				</div>
			</section>
		</>
	);
};

export default Pan;
