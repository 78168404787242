import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {getBaseUrl} from '../../utils/config';

export const loginApi = createApi({
    reducerPath: 'loginSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl,
        prepareHeaders: (headers) => {
            // Add Basic Authentication header
            // headers.set('Authorization', 'Basic ' + btoa('romil.sarna@gmail.com:romil123'));
            return headers;
        },
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        loginPost: builder.mutation({
            query: (payload) => ({
                url: '/user/login',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const { useLoginPostMutation } = loginApi;