import React, { useState, useEffect } from "react";

function CompareMeter({ score }) {
	const [currentScore, setCurrentScore] = useState(0);

	const meterValues = [
		{ label: "Poor", bgColor: "bg-[#EB8180]", borderColor: "border-[#EB8180]", starts: 0, value: 70, range: [-23, -1] },
		{ label: "Standard", bgColor: "bg-[#EBC446]", borderColor: "border-[#EBC446]", starts: 71, value: 85, range: [2, 22] },
		{ label: "Strong", bgColor: "bg-[#AFC949]", borderColor: "border-[#AFC949]", starts: 86, value: 90, range: [23, 45] },
		{ label: "Optimal", bgColor: "bg-[#3FCE80]", borderColor: "border-[#3FCE80]", starts: 91, value: 100, range: [46, 100] }
	];

	const getMeterSection = (score) => {
		return meterValues.find((meter, index) =>
			index === 0 ? score <= meter.value : score <= meter.value
		);
	};

	useEffect(() => {
		const meterSection = getMeterSection(score);
		if (meterSection) {
			const targetPosition = calculatePosition(score, meterSection.starts, meterSection.value, meterSection.range[0], meterSection.range[1]);

			const interval = setInterval(() => {
				setCurrentScore((prevPosition) => {
					if (Math.round(prevPosition - targetPosition) < 1) {
						clearInterval(interval);
						return targetPosition;
					}

					const step = Math.sign(targetPosition - prevPosition) * 0.5; // Adjust step size as needed
					return prevPosition + step;
				});
			}, 50); // Adjust the interval time as needed

			return () => clearInterval(interval); // Cleanup the interval on unmount
		} else {
			console.error("Score out of range");
		}
	}, [score]);

	const calculatePosition = (value, minValue, maxValue, minPosition, maxPosition) => {
		if (value < minValue || value > maxValue) {
			throw new Error("Value out of range");
		}
		return ((value - minValue) / (maxValue - minValue)) * (maxPosition - minPosition) + minPosition;
	};

	return (
		<div className="flex flex-col md:pt-8 py-4 mt-4 rounded-xl bg-slate-200 max-md:max-w-full shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 relative">
			<div className="mb-8 w-full">
				<h2 className="mt-1.5 text-center text-black font-bold text-opacity-70 whitespace-nowrap">
					Where does your health insurance stand?
				</h2>

				<div className="shrink-0 mt-3 h-0.5 border border-solid bg-zinc-500 bg-opacity-20 border-zinc-500 border-opacity-20 max-md:max-w-full"></div>
			</div>
			<div className="flex flex-col md:px-2 relative">
				<div className="flex items-start justify-start max-md:flex-wrap">
					<div
						style={{
							left: `${currentScore}%`,
						}}
						className="absolute -top-6 transform" id="pointer"
					>
						<div className="relative">
							<div className="bg-black p-1 rounded-lg text-white text-xs px-5 py-2">
								{"You are here"}
							</div>
							<div className="absolute w-3 h-3 bg-black left-3/4 bottom-[-4px] transform -translate-x-1/2 rotate-45"></div>
						</div>
					</div>

					{meterValues.map((meter, index) => (
						<div className="relative w-[25%]" key={index}>
							<div
								className={`shrink-0 mt-5 text-center border-solid border-[3px] rounded-[70px] ${meter.bgColor} ${meter.borderColor}`}
								role="progressbar"
								aria-valuenow={meter.value}
								aria-valuemin="0"
								aria-valuemax="100"
							>
								<span className="text-sm text-white">{meter.label}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default CompareMeter;
