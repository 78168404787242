import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import loginImg from "../assets/images/login.svg";
import { useOtpPostMutation } from "../store/services/otp";
import { useResendOtpPostMutation } from "../store/services/resendOtp";
import { setOtpData } from "../store/slices/otpSlice";
import STRINGS from "../utils/constants/strings";
import Error from "./Error/Error";
import Spinner from "./Common/Loaders/Spinner";
import TextLoader from "./Common/Loaders/TextLoader";

const Otp = ({ resetLogin, productUrl }) => {
	const userLoginData = useSelector((state) => {
		return state?.login?.userLoginData;
	});

	const [otp, setOtp] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [minutes, setMinutes] = useState(1);
	const [seconds, setSeconds] = useState(30);
	const [showWhatsapp, setWhatsappShow] = useState(false);
	const numInputs = 6;

	// Calculate whether the button should be disabled or not
	const isButtonDisabled = otp.length !== numInputs;
	const _addBtnDisableClass = isButtonDisabled ? "disabled:opacity-25" : "";

	// Use the useOtpPostMutation hook
	const [otpPost, { data, error, isLoading }] = useOtpPostMutation();

	// Use the useOtpPostMutation hook
	const [resendOtpPost, { data: resendData, isLoading: isResendOtpLoading }] =
		useResendOtpPostMutation();

	const dispatch = useDispatch();

	useEffect(() => {
		if (resendData) {
			if (resendData.status === "success") {
				setWhatsappShow(false);
			}
		}
	}, [resendData]);

	useEffect(() => {
		setTimeout(() => {
			setWhatsappShow(true);
		}, 10000);
	}, []);

	useEffect(() => {
		const saveToken = async (data) => {
			try {
				// Use the bearerToken as needed
			} catch (error) {
				console.error("Error fetching bearer token:", error);
			}
		};

		if (data) {
			if (data.status !== "failed") {
				saveToken(data).catch((error) =>
					console.error("Error saving token:", error)
				); // Add catch block to handle errors
				dispatch(setOtpData(data));
			}

			if (data.status === "failed") {
				dispatch(setOtpData(data));
				setErrorMsg(data?.msg);
			}
		}

		if (error) {
			// Handle error
			console.log(error);
		}
	}, [data, error, isLoading]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval);
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);

	const handleOtp = () => {
		otpPost({
			otp,
			mobile: userLoginData.phoneNumber,
			name: userLoginData.name,
			referral: localStorage.getItem("referralCode"),
			productUrl: productUrl
		});
	};

	const handleBack = () => {
		localStorage.clear();
		resetLogin(false);
	};

	const handleResendOtp = (channel = null) => {
		resendOtpPost({
			name: userLoginData.name,
			mobile: userLoginData.phoneNumber,
			referral: localStorage.getItem("referralCode"),
			productUrl: productUrl,
			channel: channel,
		});
		setMinutes(1);
		setSeconds(30);
	};

	return (
		<>
			{(isLoading || isResendOtpLoading) && <Spinner />}
			{error && (
				<section className="flex w-full justify-center">
					<div className="w-full lg:w-6/12">
						<Error />
					</div>

					<div className="hidden lg:block">
						<img src={loginImg} alt="Login" />
					</div>
				</section>
			)}
			{!error && (
				<section className="flex justify-between">
					<div className="w-full lg:w-6/12">
						<h2 className="font-bold text-black text-lg sm:text-2xl md:text-3xl lg:text-5xl">
							{STRINGS.Otp.title}
						</h2>
						<div className="lg:pl-10 pt-7">
							<h2 className="text-md">
								{STRINGS.Otp.otp_verification}
							</h2>
							<p className="text-sm mt-2 text-gray-600">
								{STRINGS.Otp.enter_otp} sent to{" "}
								{userLoginData?.phoneNumber}
							</p>
							<OtpInput
								value={otp}
								onChange={setOtp}
								numInputs={numInputs}
								shouldAutoFocus={true}
								renderSeparator={
									<span className={"ml-2 lg:ml-2"} />
								}
								inputStyle={
									"border border-gray-400 !w-8 !h-9 !sm:w-8 !md:w-10 !lg:w-10 !lg:h-10 rounded"
								}
								containerStyle={"mt-2 mb-3"}
								renderInput={(props) => <input {...props} />}
								inputType={"tel"}
							/>
							<div className="flex flex-row flex-wrap gap-x-1 text-sm text-gray-600">
								<div className="pr-2">
									{seconds > 0 || minutes > 0 ? (
										<p>
											Time Remaining:{" "}
											{minutes < 10
												? `0${minutes}`
												: minutes}
											:
											{seconds < 10
												? `0${seconds}`
												: seconds}
										</p>
									) : (
										<p>{STRINGS.Otp.dont_receive_otp}</p>
									)}
								</div>
								<div className="px-2">
									<button
										className={
											"underline disabled:text-gray-300"
										}
										disabled={seconds > 0 || minutes > 0}
										onClick={handleResendOtp}
									>
										{STRINGS.Otp.resend}
									</button>
								</div>
							</div>
							{showWhatsapp && (
								<div className="flex flex-row flex-wrap gap-x-1 text-sm text-gray-600 mt-4">
									<div className="pr-2">
										<p>{STRINGS.Otp.whatsapp_text}</p>
									</div>
									<div className="px-2 flex flex-col items-end md:w-auto w-full">
										<button
											className={
												"underline disabled:text-gray-300"
											}
											onClick={() =>
												handleResendOtp("whatsapp")
											}
										>
											{STRINGS.Otp.whatsapp_link}
										</button>
										<span className="md:pl-2 flex flex-col items-end md:w-auto w-full text-xs">
											{STRINGS.Otp.whatsapp_link_sufix}
										</span>
									</div>
								</div>
							)}
							{/* <div className="flex flex-row mt-2 flex-auto gap-x-1 text-sm text-gray-600">
                <button
                  className="flex flex-row text-blue-700 background-transparen pl-2 outline-none focus:outline-dashed"
                  type="button"
                  onClick={handleBack}
                >
                  Click here
                </button>
                <p>to change number {userLoginData?.phoneNumber}</p>
              </div> */}
							{errorMsg && (
								<p
									className={
										"absolute text-red text-red-600 text-sm mt-2"
									}
								>
									{errorMsg}
								</p>
							)}
							<button
								type="submit"
								className={`bg-[#F4CD2A] block rounded py-3 sm:px-20 md:px-20 lg:px-20 mt-10 w-full sm:w-auto md:w-auto lg:w-auto ${_addBtnDisableClass}`}
								disabled={isButtonDisabled}
								onClick={handleOtp}
							>
								{STRINGS.Otp.btn_text}
							</button>
						</div>
					</div>

					<div className="hidden lg:block">
						<img src={loginImg} alt="Login" />
					</div>
				</section>
			)}
		</>
	);
};

export default Otp;
