import React from 'react';

const UserDetailsHeader = ({ panNo, age, pincode }) => {
  return (
    <div className="bg-white pt-8">
      <div className="flex flex-col-reverse sm:flex-row items-center justify-between max-w-6xl mx-auto">
        <h1 className="text-md md:text-3xl text-lg font-bold text-[#020064] mb-4 sm:mb-0 text-center md:text-left">
          Know Your Health Insurance Requirements
        </h1>
        <div className="flex items-center border border-gray-300 rounded-2xl p-2 text-sm md:text-md mb-4 sm:mb-0">
          {panNo && (
            <span className="m-2 text-gray-500">
              PAN No: <span className="text-black">{panNo}</span>
            </span>
          )}
          <span className="m-2 text-gray-500">Age: <span className="text-black">{age}</span></span>
          <span className="m-2 text-gray-500">Pincode: <span className="text-black">{pincode}</span></span>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsHeader;
