import {createSlice} from "@reduxjs/toolkit";

const otpSlice = createSlice({
    name: "otp",
    initialState: {
        token: null,
    },
    reducers: {
        setOtpData: (state, action) => {
            state.otpData = action.payload;
            state.token = action.payload.token;
        },
    },
});

export const { setOtpData } = otpSlice.actions;
export const selectToken = (state) => state.otp.token;

export default otpSlice.reducer;


