import AccidentalDeathIcon from "../../assets/images/accidental-death-icon.png";
import AddedProtection from "../../assets/images/added-protection.png";
import AirAmbulanceMobile from "../../assets/images/air-ambulance-details-mob-img.png";
import AirAmbulanceWeb from "../../assets/images/air-ambulance-right.png";
import AirAmbulanceIcon from "../../assets/images/air-ambulance-icon.svg";
import CarImage from "../../assets/images/car.png";
import CoverMultipleOptions from "../../assets/images/cover-multiple-options.png";
import FamilyMembersIcon from "../../assets/images/family-members-icon.png";
import FamilyImage from "../../assets/images/family.png";
import HelplineIcon from "../../assets/images/helpline-icon.png";
import HouseImage from "../../assets/images/house.png";
import InternationalCoverageIcon from "../../assets/images/international-cover-icon.png";
import MeticulousSetup from "../../assets/images/meticulous-setup.png";
import MoneyIcon from "../../assets/images/money-icon.png";
import OrganTransportation from "../../assets/images/organ-transportation-icon.png";
import PsyIcon from "../../assets/images/psy-icon.png";
import RoadAmbulanceIcon from "../../assets/images/road-ambulance-icon.png";
import SafeComfortable from "../../assets/images/safe-comfortable.png";
import TruckImage from "../../assets/images/truck.png";

import Anxiety from "../../assets/images/anxiety.png";
import Cashless from "../../assets/images/cashless.png";
import Development from "../../assets/images/development.svg";
import Expenses from "../../assets/images/expenses.png";
import Finance from "../../assets/images/finance.svg";
import Hands from "../../assets/images/hands.png";
import Medication from "../../assets/images/medication.png";
import PsyMobile from "../../assets/images/psymobile.png";
import PsyUnlock from "../../assets/images/psyunlock.png";
import PsyWeb from "../../assets/images/psyweb.png";
import Telemed from "../../assets/images/telemed.png";
import Uncertainty from "../../assets/images/uncertainty.svg";
import Unlimited from "../../assets/images/unlimited.png";

import AddOnIcon from "../../assets/images/AddOn.png";
import AlcoholIcon from "../../assets/images/Alcohol.png";
import CashlessHospitalIcon from "../../assets/images/CashlessHospital.png";
import ChildBirthIcon from "../../assets/images/ChildBirth.png";
import ClaimSettlementIcon from "../../assets/images/ClaimSettlement.png";
import DisabilityIcon from "../../assets/images/Disability.png";
import FamilyCoverageIcon from "../../assets/images/FamilyCoverage.png";
import SportsMobile from "../../assets/images/SportsMobile.png";
import WarIcon from "../../assets/images/War.png";
import InflationIcon from "../../assets/images/inflation.png";
import InjuryIcon from "../../assets/images/injury.png";
import PovertyIcon from "../../assets/images/poverty.png";
import SportsUnlockImage from "../../assets/images/sportsUnlock.png";
import SportsWeb from "../../assets/images/sportsWeb.png";
import sportsIcon from "../../assets/images/sports-icon.svg";


import STUCancer from "../../assets/images/stuCancer.svg";
import STUCashless from "../../assets/images/stuCashless.svg";
import STUClaim from "../../assets/images/stuClaim.svg";
import STUCover from "../../assets/images/stuCover.svg";
import STUDaycare from "../../assets/images/stuDaycare.svg";
import STUDisease from "../../assets/images/stuDisease.svg";
import STUMobile from "../../assets/images/stuMobileImage.svg";
import STUPocket from "../../assets/images/stuPocket.svg";
import STUPoverty from "../../assets/images/stuPoverty.svg";
import STUPreH from "../../assets/images/stuPreH.svg";
import STUUnlockImage from "../../assets/images/stuUnlockImage.svg";
import STUWeb from "../../assets/images/stuWebImage.svg";
import STUCondition from "../../assets/images/stucondition.svg";
import STUPatient from "../../assets/images/stupatient.png";
import STUStd from "../../assets/images/stustd.svg";
import STUSterilization from "../../assets/images/stusterilization.svg";
import STUSurrogacy from "../../assets/images/stusurrogacy.svg";
import stuIcon from "../../assets/images/stuicon.png";


const PRODUCT_DETAILS = {
  "air-ambulance": {
    heading: "Do you have Air Ambulance Cover? No?",
    title: "Assess Your Air Ambulance Needs",
    feature1: "Get Peace of Mind with Affordable Coverage",
    feature2: <>As a member of IP Family, get your <span className="text-[#FFB300]"> Air Ambulance </span> at just INR 199 per annum </>,
    buttontext: "Enroll Now",
    subtext: "Leave the worry to us and get Air Ambulance cover for you &your family",
    webimage: AirAmbulanceWeb,
    mobileheading: "Air Ambulance Cover",
    mobiletitle: "Regular health insurance covers ambulances, but air ambulances,often needed for serious emergencies, can leave you with a hefty bill",
    mobileimage: AirAmbulanceMobile,
    mobiledesc: <>As a member of IP Family, get your <span className="text-[#FFB300]"> Air Ambulance </span> at just INR 199 per annum </>,
    doyouknowheading: <>Do you know about <span className="text-[#FFB300]"> Air Ambulance </span> Cover?</>,
    proTip: "Pro Tip",
    proTipdesc: "Only 57% of vehicles in India are insured, despite mandatory laws, amid 150,000 annual road deaths.",
    facts: [
      {
        icon: TruckImage,
        description: "Every hour, 53 road accidents and 19 fatalities occur. You should Secure your loved one's future.",
      },
      {
        icon: CarImage,
        description: "Only 57% of vehicles in India are insured, despite mandatory laws, amid 150,000 annual road deaths.",
      },
      {
        icon: HouseImage,
        description: "Stay ahead with Timely Planning and Informed Decisions.",
      },
    ],
    unlockheading: <>Unlock Details about<span className="text-[#FFB300]"> Air Ambulance </span> Cover</>,
    unlocksubheading: "Let us simplify your Air Ambulance Need for you",
    point1header: " Air Ambulance Services",
    point1desc: "Air ambulance cover ensures you have access to rapid medical evacuation in case of emergencies, providing timely medical attention when you need it most.",
    point2header: "Wide Range of Transportation Options",
    point2desc: "Typically includes transportation by flight or helicopter and may also cover transport by boat or train in specific situations.",
    point3header: "24/7 Availability",
    point3desc: " Air ambulance cover provides 24/7 availability to ensure you receive immediate assistance regardless of when an emergency occurs.",
    point4header: "Financial Protection",
    point4desc: "Air ambulance services can be extremely costly. This cover helps to significantly reduce out-of-pocket expenses by covering the cost of emergency medical transport.",
    unlockimage: FamilyImage,
    requiredheading: "Why Air Ambulance Cover is required?",
    requiredsubheading: "We're not your typical insurance platform. What sets us apart is our unwavering commitment to —",
    box1header: "Added Protection",
    box1desc: "Get Air Ambulance cover at just INR 199 per annum for upto INR 5 lakh",
    box1image: AddedProtection,
    box2header: "Cover Multiple Options",
    box2desc: "The plan covers a plane, helicopter, train or boat",
    box2image: CoverMultipleOptions,
    box3header: "Safe & Comfortable",
    box3desc: "Air ambulance services provide comfortable, hassle-free transport, minimizing the risks of long road trips",
    box3image: SafeComfortable,
    box4header: "Meticulous Set-up",
    box4desc: " Specialists manage air ambulance services, ensuring tailored schedules and optimal patient care during transit",
    box4image: MeticulousSetup,
    coveredheading: "What is Covered under Air Ambulance Cover?",
    coveredsubheading: "Check the list of what's all are covered in your Air Ambulance cover",
    include1image: MoneyIcon,
    include1header: "INR 5,00,000 Cover",
    include1desc: " Get up to INR 5,00,000 in air ambulance coverage for peace of mind during emergencies.",
    include2image: PsyIcon,
    include2header: "Psychological counselling",
    include2desc: "Facing challenges at work or home? Don't worry. Get unlimited psychological counselling sessions.",
    include3image: AccidentalDeathIcon,
    include3header: "Accidental Death Cover",
    include3desc: "Get INR 100,000 Accidental Death Cover for peace of mind and financial protection.",
    include4image: HelplineIcon,
    include4header: "Claim helpline 24*7",
    include4desc: "You can reach to 1800-102-4488/ 1800-102-6655 for claim intimation.",
    exclude1image: OrganTransportation,
    exclude1header: "Organ Transportation",
    exclude1desc: "Organ transportation services are not included in the plan.",
    exclude2image: RoadAmbulanceIcon,
    exclude2header: "Road Ambulance",
    exclude2desc: "This plan excludes road ambulance coverage; costs for such services won't be reimbursed.",
    exclude3image: InternationalCoverageIcon,
    exclude3header: "International Coverage",
    exclude3desc: "This plan doesn't cover international medical expenses.",
    exclude4image: FamilyMembersIcon,
    exclude4header: "Any other family members other than insured person",
    exclude4desc: "The coverage applies solely to insured members and does not extend to any other family members.",
    claimheader: "How to File a claim under Air Ambulance Cover?",
    faqs: [{
      ques1: "What does the Advance Air Ambulance Insurance policy cover?",
      ans1: "This policy covers expenses incurred for Air Ambulance services through various modes of transport, including air, train, and boat, however road transport is not included. The coverage is for necessary transportation due to medical emergencies as certified by a treating medical practitioner.",
      ques2: "What are the conditions for the Air Ambulance service coverage?",
      ans2: "Air Ambulance service coverage applies if:\n- Transportation is from the place of occurrence of a medical emergency to the nearest hospital.\n- Transportation is from one hospital to another for better medical aid following an emergency.\n- The necessity of Air Ambulance transportation is certified by the treating medical practitioner.\n- Only if Hospitalization expenses for the same incident are admissible, the cover amount will be paid.",
      ques3: "What is included under the Personal Accident Cover?",
      ans3: "The Personal Accident Cover includes benefits for accidental death. In case of unfortunate event due to an injury within 12 months from the date of the accident, the company will pay the coverage amount specified in the Certificate of Insurance.",
      ques4: "What are the permanent exclusions under this policy?",
      ans4: "The policy has few permanent exclusions, including but not limited to: \n- Pre-existing injuries or illnesses unless agreed otherwise in writing. \n- Treatment from excluded providers. \n- Conditions related to sexually transmitted diseases, maternity, birth control, and infertility. \n- Cosmetic or plastic surgery unless medically necessary. \n- Treatment related to obesity, change-of-gender, and hazardous sports. \n- Treatment received outside India or domiciliary hospitalization. \n- Expenses for unproven treatments and non-allopathic treatments. \n- Charges for routine eye and ear examinations, dental treatments, and dietary supplements unless prescribed.",
      ques5: "Are there any specific exclusions related to the usage of Air Ambulance services?",
      ans5: "Yes, the policy excludes the following: \n- Any charges incurred for transportation from a remote location with no or limited access. \n- Expenses for services not considered reasonable and customary. \n- Bank or credit charges if claims payment is made in a currency other than the policy currency.",
    }]
  },

  "sports-insurance": {
    heading: "Are injuries in sports or gym covered for you or your kid?",
    title: "Secure medical cost of small injuries with our sports cover",
    feature1: "Play or exercise with peace of mind",
    feature2: <>Get your <span className="text-[#FFB300]"> Sports Cover </span> starting at INR 79 per Annum only </>,
    buttontext: "Enroll Now",
    subtext: "Leave the worry to us and get Sports cover for you & your family",
    webimage: SportsWeb,
    mobileheading: "Sports Cover",
    mobiletitle: "Are injuries in sports or gym covered for you or your kid?",
    mobileimage: SportsMobile,
    mobiledesc: "Secure medical cost of small injuries with our sports cover",
    doyouknowheading: <>Do you know about <span className="text-[#FFB300]"> Sports </span> Cover? </>,
    proTip: "Pro Tip",
    proTipdesc: "Medical inflation of 14% is doubling your medical bill every 5 years",
    facts: [
      {
        icon: PovertyIcon,
        description: "70% of Indians are one medical bill away from slipping into poverty.",
      },
      {
        icon: InflationIcon,
        description: "Medical inflation of 14% is doubling your medical bill every 5 years",
      },
      {
        icon: InjuryIcon,
        description: "The chances of injuries at sports & gym are highest.",
      },
    ],
    unlockheading: <>Unlock Details about<span className="text-[#FFB300]"> Sports </span> Cover</>,
    unlocksubheading: "Let us simplify your Injury Protection Need for you",
    point1header: "Coverage for Sports Injuries",
    point1desc: "Ensures protection from various sports-related injuries, covering medical treatments and physiotherapy expenses",
    point2header: "Treatment and Physiotherapy Expenses",
    point2desc: "Sports cover typically includes costs for necessary treatments and physiotherapy to aid in recovery from sports injuries.",
    point3header: "Leisure Activities",
    point3desc: "Enjoy leisure sports activities with the confidence that you are protected against potential injuries.",
    point4header: "Adventure and Endurance Sports",
    point4desc: "Our policy covers injuries sustained during adventure and endurance sports, providing specialized coverage for high-risk activities.",
    unlockimage: SportsUnlockImage,
    requiredheading: "Why Sports Cover is required?",
    requiredsubheading: "We're not your typical insurance platform. What sets us apart is our unwavering commitment to —",
    box1header: "Fast Claim Settlement",
    box1desc: "We ensure that you receive funds swiftly, allowing you to focus on your recovery and get back on your feet more quickly.",
    box1image: ClaimSettlementIcon,
    box2header: "OPD Coverage For You & Your Family",
    box2desc: "Get OPD benefit worth INR 5,000 for your OPD coverage, medication & others for you & your family",
    box2image: AddOnIcon,
    box3header: "Family Coverage",
    box3desc: "You can extend coverage to include your spouse and children, ensuring your entire family is protected by the comprehensive insurance plan.",
    box3image: FamilyCoverageIcon,
    box4header: "Cashless Hospital",
    box4desc: "More than 9400 number of cashless hospitals where you can treat yourself",
    box4image: CashlessHospitalIcon,
    coveredheading: "What is Covered under Sports Cover?",
    coveredsubheading: "Check the list of what's all are covered in your Sports cover",
    include1image: MoneyIcon,
    include1header: "Accidental Death Included",
    include1desc: "Accidental Death worth INR 10,000 is available with sports cover",
    include2image: PsyIcon,
    include2header: "Physiotherapy Covered",
    include2desc: "Physiotherapy covered worth INR 5,000 through the sports cover",
    include3image: AccidentalDeathIcon,
    include3header: "Accidental Injury",
    include3desc: "Any accidental injury caused by sports or GYM activity is covered",
    include4image: HelplineIcon,
    include4header: "Claim helpline 24*7",
    include4desc: "You can reach to 1800-102-4488/ 1800-102-6655 for claim intimation",
    exclude1image: DisabilityIcon,
    exclude1header: "Existing disabilities",
    exclude1desc: "Covering  treatments or surgeries that do not require an overnight hospital stay.",
    exclude2image: ChildBirthIcon,
    exclude2header: "Losses due to childbirth or pregnancy",
    exclude2desc: "Covering  treatments or surgeries that do not require an overnight hospital stay.",
    exclude3image: WarIcon,
    exclude3header: "Accidents due to war or nuclear perils",
    exclude3desc: "Avail cashless hospitalisation at any of our healthcare providers.",
    exclude4image: AlcoholIcon,
    exclude4header: "Losses due to consumption of drugs or alcohol",
    exclude4desc: "Avail cashless hospitalisation at any of our healthcare providers.",
    claimheader: "How to File a claim under Sports Cover?",
    faqs: [{
      ques1: "What does the Sports Cover insurance policy include?",
      ans1: "The Sports Cover insurance policy includes benefits such as Accidental Out-patient Care of INR 2,000 or INR 5,000 per annum, Health Services like unlimited Doctor on Call/Chat & Personal Accident Cover upto INR 10,000. The cost of Sports Cover Insurance is INR 79 or INR 129 inclusive of taxes depending on benefits and you may include your family members as well.",
      ques2: "Who is eligible for coverage under this policy?",
      ans2: "Coverage is provided to any insured member under the policy. If there is an unfortunate event happen during policy tenure, the family members who are also insured under the policy will continue to be covered.",
      ques3: "What is covered under Personal Accident Cover?",
      ans3: "Personal Accident Cover includes coverage for accidental death and accidental out-patient care.",
      ques4: "What is included in the Accidental Out-patient Care benefit?",
      ans4: "This benefit covers medically necessary out-patient treatment of INR 2,000 or 5,000 per annum due to an injury, provided the treatment is on the written advice of a medical practitioner and commences within seven days from the date of the injury. The product also covers accidental death of INR 10,000.",
      ques5: "I sustained an injury while playing cricket. Will the accidental OPD cover this?",
      ans5: "Yes, any injury due to sports or gym activity is covered under this product, provided the injury occurred within 7 days of the claim.",
    }]
  },

  "psychological-counseling": {
    heading: "Having issues with your boss, family, or worried about the future?",
    title: <>Get unlimited online psychology counselling with Insurance<span className="text-[#FFB300]">Padosi</span>!</>,
    feature1: "Get Peace of Mind with Zero Charges",
    feature2: "To avail this cover for free, enroll for Air Ambulance Cover, Sports Cover or Super Top-Up Cover",
    buttontext: "Enroll Now",
    subtext: "Leave the worry to us and get Psychology cover for You",
    webimage: PsyWeb,
    mobileheading: "Psychology Cover",
    mobiletitle: "Having issues with your boss, family, or worried about the future?",
    mobiledesc: "Get free unlimited psychology consultation!",
    mobileimage: PsyMobile,
    doyouknowheading: <>Do you know about <span className="text-[#FFB300]"> Psychology </span> Cover?</>,
    proTip: "Pro Tip",
    proTipdesc: "Approximately 10% of India's population is estimated to suffer from anxiety at some point in their lives.",
    facts: [
      {
        icon: Hands,
        description: "Anxiety disorders affect over 38 million people in India, making them one of the most common mental health issues.",
      },
      {
        icon: Anxiety,
        description: "Approximately 10% of India's population is estimated to suffer from anxiety at some point in their lives.",
      },
      {
        icon: Telemed,
        description: "Telemedicine and online therapy platforms are emerging as important tools to increase access to mental health care, especially in underserved areas.",
      },
    ],
    unlockheading: <>Unlock Details about<span className="text-[#FFB300]"> Psychology </span> Cover with InsurancePadosi!</>,
    unlocksubheading: "Let us simplify your problems with Psychology Cover",
    point1header: "Access to Certified Counsellors",
    point1desc: "Access to certified counsellors ensures professional mental health support and guidance for individuals.",
    point2header: "Unlimited Counselling",
    point2desc: "While we do not compromise on quality, we also offer unlimited number of sessions with our certified counsellors.",
    point3header: "Private Sessions",
    point3desc: "The psychology cover includes private sessions, offering personalized one-on-one counseling services.",
    point4header: "Fast Claim Settlement",
    point4desc: "Get fast claim settlement for your psychology cover with our efficient process, ensuring timely support and peace of mind.",
    unlockimage: PsyUnlock,
    requiredheading: "Why you need a Psychology  Cover?",
    requiredsubheading: "We're not your typical insurance platform. What sets us apart is our unwavering commitment to —",
    box1header: "Work-Related Stress",
    box1desc: "The challenge of balancing demanding jobs with personal life can lead to stress and burnout.",
    box1image: Anxiety,
    box2header: "Financial Concerns",
    box2desc: "Fear of job loss or unemployment can cause anxiety and stress.",
    box2image: Finance,
    box3header: "Future Uncertainties",
    box3desc: "Concerns about career growth and job stability can lead to anxiety.",
    box3image: Uncertainty,
    box4header: "Personal Development",
    box4desc: "Many seek psychological consulting to enhance personal growth and improve their overall well-being.",
    box4image: Development,
    coveredheading: "What is Covered under Psychology Cover?",
    coveredsubheading: "Check the list of what's all are covered in your Psychology cover",
    include1image: Unlimited,
    include1header: "Unlimited psychology cover",
    include1desc: " While we do not compromise on quality, we also offer unlimited number of sessions with our certified counsellors.",
    include2image: Cashless,
    include2header: "Wide options of cashless hospitals",
    include2desc: "Avail cashless hospitalisation at any of our healthcare providers.",
    include3image: AccidentalDeathIcon,
    include3header: "Accidental Death Cover",
    include3desc: "Get INR 100,000 Accidental Death Cover for peace of mind and financial protection.",
    include4image: HelplineIcon,
    include4header: "Claim helpline 24*7",
    include4desc: "You can reach to 1800-102-4488/ 1800-102-6655 for claim intimation.",
    exclude1image: Medication,
    exclude1header: "Medication",
    exclude1desc: "Medication is not included in the psychology cover, focusing solely on therapeutic and counseling services.",
    exclude2image: FamilyMembersIcon,
    exclude2header: "Consulting for any other family member",
    exclude2desc: "Consulting for other family members is not included in the psychology cover, which is limited to individual services only.",
    exclude3image: Expenses,
    exclude3header: "Any additional spent other than consulting",
    exclude3desc: "Any additional expenses beyond consulting are not included in the psychology cover.",
    exclude4image: InternationalCoverageIcon,
    exclude4header: "Treatment received outside India",
    exclude4desc: "This plan cannot be availed outside India.",
    claimheader: "How to File a claim under Psychology Cover?",
    faqs: [{
      ques1: "What is the Sponsored Psychology Insurance?",
      ans1: "The Sponsored Psychology Insurance is a complementary product offered by Insurance Padosi to all its paid users for one month. It provides access to psychological consultations and certain health services.",
      ques2: "How much does this insurance cost?",
      ans2: "This insurance is complementary and provided at no additional cost to all paid users of Insurance Padosi for one month.",
      ques3: "What services are covered under this policy?",
      ans3: "The policy includes access to psychological consultations and the benefit of health services such as Doctor on Call and Doctor on Chat.",
      ques4: "What are the conditions for using the Doctor on Call/Chat service?",
      ans4: "The Doctor on Call/Chat service is available upon the insured member’s request. The medical advice provided is based solely on the information and documentation shared by the insured member. It is for informational purposes only and does not substitute a physical visit to a medical practitioner.",
      ques5: "Is there any limitation on the type of consultations available?",
      ans5: "Yes, consultations with a psychologist are only available within the company’s or Assistance Service Provider’s network.",
    }]
  },

  "super-topup": {
    heading: "Is your basic health policy coverage sufficient?",
    title: "Add extra protection to your health insurance",
    feature1: "Get Peace of Mind with Affordable Coverage",
    feature2: <>As an InsurancePadosi member, get  <span className="text-[#FFB300]">Super Top up Cover </span>at affordable price</>,
    buttontext: "Enroll Now",
    subtext: "Leave the worry to us and get Super Top up cover for you & your family",
    webimage: STUWeb,
    mobileheading: "Super Top Up Health Insurance",
    mobiletitle: "Is your basic health policy coverage sufficient?",
    mobiledesc: "Add extra protection to your health insurance",
    mobileimage: STUMobile,
    doyouknowheading: <>Let us simplify your<span className="text-[#FFB300]"> Health Insurance </span> for you</>,
    proTip: "Pro Tip",
    proTipdesc: "The Times of India reports that healthcare expenses push over 63 million Indians into poverty each year due to lack of financial protection.",
    facts: [
      {
        icon: STUPocket,
        description: "In India, 62% of healthcare costs are paid out of pocket, leading to delayed treatment or debt.",
      },
      {
        icon: STUPoverty,
        description: "The Times of India reports that healthcare expenses push over 63 million Indians into poverty each year due to lack of financial protection.",
      },
      {
        icon: STUCancer,
        description: "Experts say corporate insurance is insufficient, covering up to ₹5 lakh, while critical illness treatments like cancer can cost ₹25-₹30 lakh.",
      },
    ],
    unlockheading: <>Unlock Beneifts of Insurance<span className="text-[#FFB300]">Padosi </span> Super Top up Cover</>,
    unlocksubheading: "Let us simplify Super Top up need for you",
    point1header: "Extra Layer of Protection",
    point1desc: "A super top-up plan acts as an extra layer of protection for your existing health insurance.",
    point2header: "Coverage Beyond Primary Insurance",
    point2desc: "It helps cover medical expenses that exceed the amount covered by your primary health insurance policy.",
    point3header: "Cost-Effective Coverage",
    point3desc: "Instead of purchasing a more expensive primary insurance policy, you can opt for a super top-up to increase your coverage at a lower cost.",
    point4header: "Ensuring Comprehensive Coverage",
    point4desc: "A super top-up ensures you're covered for significant medical expenses without high upfront payments.",
    unlockimage: STUUnlockImage,
    requiredheading: "Why you need a Super Top up Cover?",
    requiredsubheading: "We're not your typical insurance platform. What sets us apart is our unwavering commitment to —",
    box1header: "Boost Your & Your Family’s Cover",
    box1desc: "Boost your family's health insurance with extra coverage for expenses beyond your primary policy's limits.",
    box1image: STUCover,
    box2header: "Pre & Post Hospitalization Covered",
    box2desc: "Covers pre and post-hospitalization expenses, including diagnostic tests and follow-up treatments.",
    box2image: STUPreH,
    box3header: "Effortless Claim Process",
    box3desc: "Enjoy a hassle-free, efficient claim process to receive benefits quickly",
    box3image: STUClaim,
    box4header: "Day Care Benefits",
    box4desc: "Get coverage for day care procedures and short-term treatments that don't require an overnight stay.",
    box4image: STUDaycare,
    coveredheading: "What is covered in under Super Top up cover?",
    coveredsubheading: "Check the list of what's all are covered in your Super Top up cover",
    include1image: STUDaycare,
    include1header: "Day Care Treatments",
    include1desc: "Covering treatments or surgeries that do not require an overnight hospital stay. ",
    include2image: STUDisease,
    include2header: "Pre-Hospitalization Medical Expenses",
    include2desc: "Covers medical expenses incurred before hospitalization, including diagnostic tests and consultations.",
    include3image: STUCashless,
    include3header: "Cashless Hospital Network",
    include3desc: "Avail cashless hospitalisation at any of our healthcare providers. List of hospital is here",
    include4image: STUPatient,
    include4header: "In-Patient Care",
    include4desc: "Covers medical expenses incurred during hospitalization where an overnight stay is required for treatment.",
    exclude1image: STUCondition,
    exclude1header: "Pre-Existing Conditions",
    exclude1desc: "Excludes any pre-existing injury, illness, or disability, including associated medical conditions and complications",
    exclude2image: STUStd,
    exclude2header: "Sexually Transmitted Diseases",
    exclude2desc: "Excludes conditions related to sexually transmitted diseases, including AIDS and its complications",
    exclude3image: STUSterilization,
    exclude3header: "Reversal of Sterilization",
    exclude3desc: "Excludes any treatment related to the reversal of sterilization.",
    exclude4image: STUSurrogacy,
    exclude4header: "Gestational Surrogacy",
    exclude4desc: "Excludes any expenses related to gestational surrogacy.",
    claimheader: "How to File a claim under Super Top up Cover?",
    faqs: [{
      ques1: "What is Insurance Padosi Super Top Up Health Insurance?",
      ans1: "Insurance Padosi Super TopUp is a health insurance plan designed for registered customers of Insurance Padosi Private Limited. It provides coverage for hospitalization expenses, including in-patient care, day care treatments, pre- and post-hospitalization expenses, and more.",
      ques2: "Who is eligible for this plan?",
      ans2: "The plan is available to individuals and families (floater cover) with the following age criteria: \n- Adults: 18 to 65 years \n- Children: 91 days to 24 years (coverage continues up to 25 years)",
      ques3: "What types of coverage does the plan offer?",
      ans3: "The plan covers: \n- Hospitalization expenses up to the Sum Insured (SI) options of Rs. 20 Lakhs, 25 Lakhs, or 50 Lakhs. \n- In-patient care and day care treatments up to the SI. \n- Pre-hospitalization medical expenses for 60 days and post-hospitalization expenses for 90 days. \n- Domestic road ambulance expenses up to Rs. 5,000 per hospitalization. \n- Donor expenses (excluding the cost of the organ). \n- Domiciliary hospitalization and alternative treatments (IPD basis) up to the SI.",
      ques4: "Is there a requirement for a pre-policy medical check-up?",
      ans4: "No, a pre-policy medical check-up is not required. The policy is issued based on a good health declaration.",
      ques5: "What is the policy tenure?",
      ans5: "The policy is valid for 1 year and can be renewed annually.",
    }]
  },






};

export const RECOMMENDATION_DETAILS = {
  "air-ambulance": {
    icon: AirAmbulanceIcon,
    title: "Air Ambulance",
    url: "/air-ambulance",
    description: "Based on your data points we recommend you to buy Air Ambulance Cover for you and your family for swift emergency transport."
  },
  "sports-cover": {
    icon: sportsIcon,
    title: "Sports Cover",
    url: "/sports-insurance",
    description: "Based on your data points we recommend you to buy Sports Cover for you and your family for swift recoveries from gym-related injuries."
  },
  "psychological-conver": {
    icon: PsyIcon,
    title: "Psychological Cover",
    url: "/psychological-counseling",
    description: "Based on your profile and needs, we've matched you with a psychology cover that provides unlimited online sessions with a counselor."
  },
  "super-top-up": {
    icon: stuIcon,
    title: "Super Top Up",
    url: "/super-topup",
    description: "Based on your data points we recommend you to buy Super Top Up Cover for you and your family for large medical expenses at low cost."
  }
}


export default PRODUCT_DETAILS;
