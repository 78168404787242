import React from 'react';

const HealthDeclarationModal = ({ onAccept, onDecline }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-gray-200 p-6 rounded-md shadow-md w-full max-w-lg relative">
      <button onClick={onDecline} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
        &times;
      </button>
      <h2 className="text-sm text-gray-500 mb-2">Accept to proceed payment</h2>
      <h2 className="text-xl font-bold mb-4">Good Health Declaration</h2>
      <p className="mb-4 text-sm">
        I hereby declare that all proposed members are in good health and entirely free from any mental or physical impairments or deformities, disease/condition. Also, none of the proposed members are habitual consumers of alcohol, tobacco, gutka, or any recreational drugs.
      </p>
      <div className="flex justify-between mt-4">
        <button onClick={onDecline} className="px-6 py-3 bg-gray-300 text-black font-bold text-xs rounded-xl">
          REJECT
        </button>
        <button onClick={onAccept} className="px-6 py-3 bg-[#F4CD2A] text-black font-bold text-xs rounded-xl">
          ACCEPT
        </button>
      </div>
    </div>
  </div>
);

export default HealthDeclarationModal;
