import { configureStore } from "@reduxjs/toolkit";
import { loginApi } from "./services/login";
import loginReducer from '../store/slices/loginSlice';
import otpReducer from '../store/slices/otpSlice';
import { otpApi } from "./services/otp";
import answerReducer from '../store/slices/answerSlice';
import { answerApi } from "./services/answer";
import { resendOtpApi } from "./services/resendOtp";
import { sessionApi } from "./services/session";
import sessionReducer from '../store/slices/sessionSlice';
import productReducer from '../store/slices/productsSlice';
import recheckPanReducer from '../store/slices/recheckPanSlice';
import { recheckPanApi } from "./services/recheckPan";
import maskedMobileReducer from '../store/slices/maskedMobileSlice';
import { maskedMobileApi } from "./services/maskedMobile";
import { dashboardApi } from "./services/dashboard";
import { dashboardEmailUpdateApi } from "./services/dashboardEmailUpdate";
import { dashboardPhoneUpdateApi } from "./services/dashboardPhoneUpdate";
import { healthInsuranceApi } from "./services/healthproducts";
import { productsApi } from "./services/products";
import { userProductsApi } from "./services/userProducts";

const appStore = configureStore({
    reducer: {
        login: loginReducer,
        otp: otpReducer,
        answer: answerReducer,
        session: sessionReducer,
        recheckPan: recheckPanReducer,
        maskedMobile: maskedMobileReducer,
        products: productReducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [userProductsApi.reducerPath]: userProductsApi.reducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [otpApi.reducerPath]: otpApi.reducer,
        [answerApi.reducerPath]: answerApi.reducer,
        [resendOtpApi.reducerPath]: resendOtpApi.reducer,
        [sessionApi.reducerPath]: sessionApi.reducer,
        [recheckPanApi.reducerPath]: recheckPanApi.reducer,
        [maskedMobileApi.reducerPath]: maskedMobileApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [dashboardEmailUpdateApi.reducerPath]: dashboardEmailUpdateApi.reducer,
        [dashboardPhoneUpdateApi.reducerPath]: dashboardPhoneUpdateApi.reducer,
        [healthInsuranceApi.reducerPath]: healthInsuranceApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            loginApi.middleware,
            otpApi.middleware,
            answerApi.middleware,
            resendOtpApi.middleware,
            sessionApi.middleware,
            recheckPanApi.middleware,
            maskedMobileApi.middleware,
            dashboardApi.middleware,
            dashboardEmailUpdateApi.middleware,
            dashboardPhoneUpdateApi.middleware,
            healthInsuranceApi.middleware,
            productsApi.middleware,
            userProductsApi.middleware
        ),
});

export default appStore;