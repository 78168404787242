import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/config";

export const productsApi = createApi({
    reducerPath: "productSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl,
        prepareHeaders: (headers) => {
            headers.set(
                "Authorization",
                `${window?.localStorage.getItem("userid")}`
            );
            return headers;
        },
    }),
    tagTypes: ["Post"],
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: () => ({
                url: "/product/get-list",
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            providesTags: ["Post"],
        }),
    }),
});

export const { useGetProductsQuery } = productsApi;
