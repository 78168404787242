import {createSlice} from "@reduxjs/toolkit";

const recheckPanSlice = createSlice({
    name: "recheckPan",
    initialState: {},
    reducers: {
        setRecheckPanData: (state, action) => {
            state.recheckPanData = action.payload;
        },
    },
});

export const { setRecheckPanData } = recheckPanSlice.actions;

export default recheckPanSlice.reducer;


