import {createSlice} from "@reduxjs/toolkit";

//TODO: Check and remove loginData as it is simply success resp of login api call
const loginSlice = createSlice({
    name: "login",
    initialState: {
        loginData: null,
        userLoginData: null,
    },
    reducers: {
        setLoginData: (state, action) => {
            state.loginData = action.payload;
        },
        setUserLoginData: (state, action) => {
            state.userLoginData = action.payload;
        }
    },
});

export const { setLoginData, setUserLoginData } = loginSlice.actions;
export default loginSlice.reducer;


