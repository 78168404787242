import STRINGS from "./strings";
import IMAGE_URLS from "./images";

const PAN_QUESTIONS = [
	{
		id: STRINGS.PanQuestions.question_0.id,
		title: STRINGS.PanQuestions.question_0.title,
		// meta_title: STRINGS.PanQuestions.question_0.meta_title,
		text: STRINGS.PanQuestions.question_0.text,
		message: STRINGS.PanQuestions.question_0.message,
		options: STRINGS.PanQuestions.question_0.options,
		imageUrl: IMAGE_URLS.pan_questions.question_0,
		sub_questions: STRINGS.PanQuestions.question_0.sub_questions,
	},
	{
		id: STRINGS.PanQuestions.question_1.id,
		title: STRINGS.PanQuestions.question_1.title,
		meta_title: STRINGS.PanQuestions.question_1.meta_title,
		text: STRINGS.PanQuestions.question_1.text,
		message: STRINGS.PanQuestions.question_1.message,
		options: STRINGS.PanQuestions.question_1.options,
		imageUrl: IMAGE_URLS.pan_questions.question_1,
	},
	{
		id: STRINGS.PanQuestions.question_2.id,
		title: STRINGS.PanQuestions.question_2.title,
		// meta_title: STRINGS.PanQuestions.question_2.meta_title,
		text: STRINGS.PanQuestions.question_2.text,
		message: STRINGS.PanQuestions.question_2.message,
		options: STRINGS.PanQuestions.question_2.options,
		imageUrl: IMAGE_URLS.pan_questions.question_2,
		sub_questions: STRINGS.PanQuestions.question_2.sub_questions,
	},
	{
		id: STRINGS.PanQuestions.question_3.id,
		title: STRINGS.PanQuestions.question_3.title,
		meta_title: STRINGS.PanQuestions.question_3.meta_title,
		text: STRINGS.PanQuestions.question_3.text,
		options: STRINGS.PanQuestions.question_3.options,
		imageUrl: IMAGE_URLS.pan_questions.question_3,
	},
	{
		id: STRINGS.PanQuestions.question_4.id,
		title: STRINGS.PanQuestions.question_4.title,
		meta_title: STRINGS.PanQuestions.question_4.meta_title,
		text: STRINGS.PanQuestions.question_4.text,
		options: STRINGS.PanQuestions.question_4.options,
		imageUrl: IMAGE_URLS.pan_questions.question_4,
	},
	{
		id: STRINGS.PanQuestions.question_5.id,
		title: STRINGS.PanQuestions.question_5.title,
		meta_title: STRINGS.PanQuestions.question_5.meta_title,
		text: STRINGS.PanQuestions.question_5.text,
		message: STRINGS.PanQuestions.question_5.message,
		options: STRINGS.PanQuestions.question_5.options,
		imageUrl: IMAGE_URLS.pan_questions.question_5,
	},
	{
		id: STRINGS.PanQuestions.question_6.id,
		title: STRINGS.PanQuestions.question_6.title,
		meta_title: STRINGS.PanQuestions.question_6.meta_title,
		text: STRINGS.PanQuestions.question_6.text,
		message: STRINGS.PanQuestions.question_6.message,
		options: STRINGS.PanQuestions.question_6.options,
		imageUrl: IMAGE_URLS.pan_questions.question_6,
	},
	// {
	// 	id: STRINGS.PanQuestions.question_7.id,
	// 	title: STRINGS.PanQuestions.question_7.title,
	// 	meta_title: STRINGS.PanQuestions.question_7.meta_title,
	// 	options: STRINGS.PanQuestions.question_7.options,
	// 	text: STRINGS.PanQuestions.question_7.text,
	// 	message: STRINGS.PanQuestions.question_7.message,
	// 	sub_questions:STRINGS.PanQuestions.question_7.sub_questions,
	// 	imageUrl: IMAGE_URLS.pan_questions.question_7,
	// },
	// {
	// 	id: STRINGS.PanQuestions.question_8.id,
	// 	title: STRINGS.PanQuestions.question_8.title,
	// 	text: STRINGS.PanQuestions.question_8.text,
	// 	sub_questions: STRINGS.PanQuestions.question_8.sub_questions,
	// 	plan_questions: STRINGS.PanQuestions.question_8.plan_questions,
	// 	imageUrl: IMAGE_URLS.pan_questions.question_8,
	// },
];

export default PAN_QUESTIONS;
