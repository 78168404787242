import React, { useState, useEffect } from 'react';
import { useAnswerPostMutation } from "../../../../store/services/answer";
import { useGetHealthProductsQuery } from "../../../../store/services/healthproducts";
import { addCommas, removeCommas, numberToIndianCurrencyText } from '../../../../utils/common';
import Spinner from '../../../Common/Loaders/Spinner';

const HealthInsurancePopup = ({ onClose, refreshDashboard }) => {
  const [selectedInsurer, setSelectedInsurer] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [planValue, setPlanValue] = useState('');

  // Fetch insurer and product data from the API
  const {
    data: productsData,
    error: productsError,
    isLoading: productsLoading,
  } = useGetHealthProductsQuery();

  // Answer post mutation
  const [answerPost, { isLoading: isPosting, error: postError }] = useAnswerPostMutation();

  // Convert object keys to array of options
  const insurerOptions = Object.keys(productsData || {}).map((insurer) => ({
    value: insurer,
    label: insurer,
  }));

  // Add a default option for "Select Insurer Provider"
  insurerOptions.unshift({
    value: '',
    label: 'Select Insurer Provider',
  });

  const handleInsurerSelect = (insurer) => {
    setSelectedInsurer(insurer);
    setSelectedProduct(''); // Reset selected product when a new insurer is selected
  };

  const filteredProducts = selectedInsurer
    ? productsData?.[selectedInsurer] || []
    : [];

  const productOptions = filteredProducts.map((product) => ({
    value: product.id,
    label: product.product_name,
  }));

  // Add a default option for "Select Product"
  productOptions.unshift({
    value: '',
    label: 'Select Product',
  });

  const formateandSetPlanValue = (value) => {
    setPlanValue(removeCommas(value));
  };

  const handleSubmit = async () => {
    try {
      await answerPost({
        quesid: "13",
        anws: {
          selectedProduct,
          planValue,
        },
      });
      refreshDashboard(); // Refresh the dashboard data
      onClose(); // Close the popup
    } catch (error) {
      console.error("Failed to submit answer:", error);
    }
  };

  useEffect(() => {
    console.log('prod', selectedProduct);
    console.log('val', planValue);
  }, [selectedProduct, planValue]);

    const isButtonDisabled = !selectedInsurer || !planValue || !selectedProduct;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-8 rounded-md shadow-md w-full max-w-lg sm:max-w-xl md:max-w-2xl relative">
          <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl md:text-2xl">
            &times;
          </button>
          <h2 className="text-sm font-bold mb-2 text-blue-950 md:text-xl">Do you have a Health Insurance? Yes!</h2>
          <p className="mb-6 text-xs text-gray-600 text-blue-950 font-bold md:text-sm">Check if your current Health Insurance covers all your needs — health, family, and future.</p>
          <form>
            <div className="mb-4 flex flex-col sm:flex-row sm:items-center">
              <label className="block text-gray-700 mb-2 sm:mb-0 sm:mr-4 w-full sm:w-3/3 text-blue-950 font-bold text-xs md:text-sm">What's your Insurer Name*</label>
              <select
                className="w-full p-2 border border-gray-300 rounded sm:w-2/3 text-xs md:text-sm"
                value={selectedInsurer}
                onChange={(e) => handleInsurerSelect(e.target.value)}
              >
                <option value="" disabled>Select Insurer Provider</option>
                {insurerOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="mb-4 flex flex-col sm:flex-row sm:items-center">
              <label className="block text-gray-700 mb-2 sm:mb-0 sm:mr-4 w-full sm:w-3/3 text-blue-950 font-bold text-xs md:text-sm">What's your sum insured?*</label>
              <div className="flex flex-col sm:w-2/3">
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded text-xs md:text-sm"
                  placeholder="Enter Amount"
                  value={addCommas(planValue)}
                  onChange={(e) => formateandSetPlanValue(e.target.value)}
                />
                <p className="text-xs mt-2">{numberToIndianCurrencyText(planValue)}</p>
              </div>
            </div>
            <div className="mb-6 flex flex-col sm:flex-row sm:items-center">
              <label className="block text-gray-700 mb-2 sm:mb-0 sm:mr-4 w-full sm:w-3/3 text-blue-950 font-bold text-xs md:text-sm">What's your insurance plan's name?</label>
              <select
                className="w-full p-2 border border-gray-300 rounded sm:w-2/3 text-xs md:text-sm"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
              >
                <option value="" disabled>Select Plan</option>
                {productOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <button
              type="button"
              className={`w-full py-3 font-bold rounded text-xs md:text-sm ${isButtonDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-[#F4CD2A] text-black'}`}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              {isPosting ? (
                <>
                  Checking...
                  <Spinner />
                </>
              ) : (
                'Check Now'
              )}
            </button>
            {postError && <p className="text-red-500 mt-2 text-xs md:text-sm">Failed to submit the answer. Please try again.</p>}
          </form>
        </div>
      </div>
    );
  };    


export default HealthInsurancePopup;
