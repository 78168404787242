import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import airAmbulanceIcon from "../assets/images/air-ambulance-icon.svg";
import ihrIcon from "../assets/images/ihr-icon.svg";
import psychologyIcon from "../assets/images/psychology-icon.svg";
import sportsIcon from "../assets/images/sports-icon.svg";
import stuIcon from "../assets/images/stuicon.png";
import Card from "./Common/Card";

function Dashboard() {
  const userProducts = useSelector((state) => state.products.userProducts);
  const products = useSelector((state) => state.products.products);



  const initialCardsData = [
    {
      id: 1,
      src: ihrIcon,
      isRecommended: true,
      isComingSoon: false,
      is_converted: false,
      alt: "Insurance Health Report",
      title: "Insurance Health Report",
      product_url: '/ihr',
      btnText: "Try Now",
      isConvertedBtnText: "See Details",
      text: "Get your customised IHR Report for free now from us.",
      disabled: false
    },
    {
      id: 2,
      src: airAmbulanceIcon,
      isRecommended: false,
      isComingSoon: false,
      is_converted: false,
      alt: "Air Ambulance Cover",
      title: "Air Ambulance Cover",
      product_url: '/air-ambulance',
      btnText: "Enroll Now",
      isConvertedBtnText: "See Details",
      text: "Secure peace of mind with air ambulance coverage for swift emergency transport.",
      disabled: false
    },
    {
      id: 3,
      src: sportsIcon,
      isRecommended: false,
      isComingSoon: false,
      is_converted: false,
      alt: "Sports Cover",
      title: "Sports Insurance Cover",
      product_url: "/sports-insurance",
      btnText: "Enroll Now",
      isConvertedBtnText: "See Details",
      text: "Stay protected with sports cover, ensuring swift recoveries from gym-related injuries.",
      disabled: false
    },
    {
      id: 4,
      src: psychologyIcon,
      isRecommended: false,
      isComingSoon: false,
      is_converted: false,
      alt: "Psychology Cover",
      title: "Psychology Cover",
      product_url: "/psychological-counseling",
      btnText: "Enroll Now",
      isConvertedBtnText: "See Details",
      text: "Get unlimited support for stress with complementary psychology consulting.",
      disabled: false
    },
    {
      id: 5,
      src: stuIcon,
      isRecommended: false,
      isComingSoon: false,
      is_converted: false,
      alt: "Super Top-up",
      title: "Super Top-up Cover",
      product_url: "/super-topup",
      btnText: "Enroll Now",
      isConvertedBtnText: "See Details",
      text: "Boost coverage with higher limits and protection for large medical expenses at low cost.",
      disabled: false
    },
  ];

  const [cardsData, setCardsData] = useState(initialCardsData);

  const getIsConverted = (products, userProducts, product_id) => {
    if (userProducts) {
      const userProduct = userProducts.find(userProduct => userProduct.product_id === product_id);
      if (userProduct) {
        return userProduct.is_converted ? true : false;
      }
    }
    return false;
  }

  useEffect(() => {
    if (userProducts && products) {
      setCardsData(cardsData.map((card) => {
        return {
          ...card,
          is_converted: getIsConverted(products, userProducts, card.id),
        }
      }));
    }
  }, [userProducts, products]);

  return (
    <div className="flex flex-col items-start justify-center py-8">
      {/* <div className="text-black text-xs flex items-center gap-1 justify-center px-0 py-1 md:py-2 md:px-10">
        <BoltIcon className="size-3" />
        <p>Quick actions</p>
      </div> */}
      <div className="grid grid-cols-2 md:grid-cols-2 gap-4 md:gap-10 px-0 pb-10 md:pb-10 md:px-10 items-center items-stretch justify-center">
        {cardsData.map((card) => (
          <Card {...card} />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
