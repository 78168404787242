export default function Card({ src, alt, title, text, subtitle, isRecommended, isComingSoon, is_converted, product_url, btnText, isConvertedBtnText, disabled }) {

    return (
        <a className={(is_converted || !disabled) ? `cursor-pointer` : `cursor-not-allowed pointer-events-none`} href={`${product_url}${is_converted ? '/dashboard' : ''}`} >
            <section className={`relative flex flex-col h-full gap-4 max-w-sm flex-1 px-2 pt-10 pb-6 md:px-5 md:py-10 bg-white rounded-xl shadow-lg text-blue-950 transition-all duration-300 hover:scale-105 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                {isRecommended && (
                    <span className="absolute top-2 left-4 px-4 py-1 md:px-8 text-[8px] md:text-xs font-bold text-left rounded-xl w-max text-white"
                        style={{
                            background: 'linear-gradient(to right, rgba(208, 149, 229, 1) 0%, rgba(4, 0, 202, 1) 90%)'
                        }}>
                        Most Recommended
                    </span>
                )}
                {
                    isComingSoon && (
                        <div className="absolute top-2 left-2 px-4 py-1 md:px-8 text-[8px] md:text-xs font-bold text-left rounded-xl w-max text-[#020064]"
                            style={{
                                background: 'linear-gradient(270deg, rgba(160, 229, 149, 0.8) 0%, rgba(0, 202, 202, 0.8) 100%)'
                            }}>
                            Coming Soon
                        </div>
                    )
                }
                <div className="flex items-start md:items-center gap-2 text-xs font-bold">
                    <img loading="lazy" src={src} alt={alt} className="shrink-0 aspect-[0.8]" />
                    <div className="font-bold text-sm md:text-base ml-1.5 md:ml-2">{title}</div>
                </div>
                <div className=" text-xs md:text-sm font-medium min-h-16">
                    {text}
                </div>
                {(is_converted || !disabled) && (
                    <div className="w-full flex items-center justify-center md:justify-start">
                        <p className="bg-[#F4CD2A] text-black px-4 py-2 rounded-xl font-bold text-xs">
                            {is_converted ? isConvertedBtnText : btnText}
                        </p>
                    </div>
                )}
            </section>
        </a>
    );
}