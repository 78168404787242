import React, { useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import {
  validateAgeForChildren,
  validateAgeForAdults,
  validateAddress,
  validateName
} from "../../../../utils/helpers/questions";

const UserRelationshipsForm = ({ formData, updateFormData, productConfig }) => {
  const {
    register,
    control,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "relationships.children.details",
  });

  // console.log("config", productConfig);

  const handleAddChild = () => {
    if (fields.length < productConfig.max_children) {
      const newChild = {
        full_name: "",
        date_of_birth: "",
        gender: "",
        comm_change: false,
        mobile: "",
        email: "",
        address: "",
      };
      append(newChild);
      const updatedChildren = [
        ...formData.relationships.children.details,
        newChild,
      ];
      updateFormData("children", "details", updatedChildren);
    } else {
      alert(`You can only add up to ${productConfig.max_children} children.`);
    }
  };

  const handleRemoveChild = (index) => {
    const updatedChildren = formData.relationships.children.details.filter(
      (_, i) => i !== index
    );
    updateFormData("children", "details", updatedChildren);
    remove(index);
  };

  const handleCheckboxChange = (relationship) => {
    updateFormData(
      relationship,
      "include",
      !formData.relationships[relationship].include
    );
    if (relationship === "children") {
      if (fields.length === 0 && !formData.relationships.children.include) {
        handleAddChild();
      }
      if (formData.relationships.children.include) {
        remove();
        updateFormData("children", "details", []);
      }
    }
  };

  const relationshipsToRender = ["spouse"];
  if (productConfig.has_parents) {
    relationshipsToRender.push("mother", "father");
  }

  const handleCommCheckboxChange = (relationship) => {
    updateFormData(
      relationship,
      "comm_change",
      !formData.relationships[relationship].comm_change
    );
  };

  const handleCommCheckboxChangeForChildren = (relationship, index) => {
    const updatedChildren = formData.relationships.children.details.map(
      (child, i) => {
        if (i === index) {
          return { ...child, comm_change: !child.comm_change };
        }
        return child;
      }
    );
    updateFormData("children", "details", updatedChildren);
  };

  const handleInputChange = async (relationship, field, value) => {
    if (relationship === "children.details") {
      const [childIndex, childField] = field.split(".");
      const updatedChildren = formData.relationships.children.details.map(
        (child, index) => {
          if (index === parseInt(childIndex)) {
            return { ...child, [childField]: value };
          }
          return child;
        }
      );
      console.log(updatedChildren);
      updateFormData("children", "details", updatedChildren);
      setValue(`relationships.children.details[${childIndex}].${childField}`, value);
      await trigger(`relationships.children.details[${childIndex}].${childField}`);
    } else {
      updateFormData(relationship, field, value);
      setValue(`relationships.${relationship}.${field}`, value);
      await trigger(`relationships.${relationship}.${field}`);
    }
  };

  return (
    <div>
      <div className="pl-8 pt-8">
        <h2 className="text-xl font-bold mb-4">Add your Family Members</h2>
        <p className="mb-4 text-xs">
          Fill details of your family members to enroll them.
        </p>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2 p-8 pt-0">
        {relationshipsToRender.map((relationship) => (
          <div key={relationship}>
            <div className="mb-4 border border-gray-200 rounded-xl">
              <div className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 focus:ring-4 focus:ring-gray-200 gap-3">
                <label className="block text-sm font-medium text-gray-700">
                  {relationship.charAt(0).toUpperCase() + relationship.slice(1)}
                </label>
                <input
                  type="checkbox"
                  checked={formData.relationships[relationship].include}
                  onChange={() => handleCheckboxChange(relationship)}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
              {formData.relationships[relationship].include && (
                <div className="flex flex-col justify-between gap-6 w-full p-5 font-medium rtl:text-right border-t">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Full Name <span className="text-xs text-[#838080] opacity-80">(As written in AADHAR card)</span>
                    </label>
                    <input
                      type="text"
                      {...register(`relationships.${relationship}.full_name`, {
                        required: "Full name is required",
                        validate: (value) => validateName(value) === true || "Only letters and spaces are allowed",
                      })}
                      value={formData.relationships[relationship].full_name}
                      onChange={(e) =>
                        handleInputChange(
                          relationship,
                          "full_name",
                          e.target.value
                        )
                      }
                      className="h-10 p-3 mt-1 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.relationships?.[relationship]?.full_name && (
                      <span className="text-red-600 text-sm">
                        {errors.relationships[relationship].full_name.message}
                      </span>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      {...register(
                        `relationships.${relationship}.date_of_birth`,
                        { required: "Date of birth is required", validate: (value) => validateAgeForAdults(value, productConfig) }
                      )}
                      value={formData.relationships[relationship].date_of_birth}
                      onChange={(e) =>
                        handleInputChange(
                          relationship,
                          "date_of_birth",
                          e.target.value
                        )
                      }
                      className="h-10 p-3 mt-1 block border w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.relationships?.[relationship]?.date_of_birth && (
                      <span className="text-red-600 text-sm">
                        {
                          errors.relationships[relationship].date_of_birth
                            .message
                        }
                      </span>
                    )}
                    {errors.relationships?.[relationship]?.date_of_birth?.type === "validate" && <span className="text-red-600 text-sm">
                      Age should be greater than 18 years and less than 65 years
                    </span>}
                  </div>

                  {/* use same communication */}
                  <div
                    onClick={() => handleCommCheckboxChange(relationship)}
                    className="flex items-start justify-start gap-2 md:px-2 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={
                        !formData.relationships[relationship].comm_change
                      }
                      onChange={() => handleCommCheckboxChange(relationship)}
                      className="h-4 w-4 text-gray-600 border-gray-300 rounded default:bg-gray-300"
                    />
                    <p className="block text-xs font-medium text-gray-700">
                      Use same communication details as the primary user
                    </p>
                  </div>

                  {formData.relationships[relationship].comm_change && (
                    <div className="flex flex-col gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Mobile
                        </label>
                        <input
                          type="number"
                          {...register(`relationships.${relationship}.mobile`, {
                            required: "Mobile number is required",
                            minLength: 10,
                            maxLength: 10,
                            message: "Invalid phone number",
                          })}
                          value={formData.relationships[relationship].mobile}
                          onChange={(e) =>
                            handleInputChange(
                              relationship,
                              "mobile",
                              e.target.value
                            )
                          }
                          className="h-10 p-3 mt-1 block border w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {errors.relationships?.[relationship]?.mobile && (
                          <span className="text-red-600 text-sm">
                            {formData.relationships[relationship].mobile
                              ?.length > 0
                              ? "Invalid phone number"
                              : "Mobile number is required"}
                          </span>
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          {...register(`relationships.${relationship}.email`, {
                            required: "Email is required",
                            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          })}
                          value={formData.relationships[relationship].email}
                          onChange={(e) =>
                            handleInputChange(
                              relationship,
                              "email",
                              e.target.value
                            )
                          }
                          className="h-10 p-3 mt-1 block border w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {errors.relationships?.[relationship]?.email && (
                          <span className="text-red-600 text-sm">
                            {errors.relationships[relationship].email.message}
                          </span>
                        )}
                      </div>

                      {/* gender */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Gender
                        </label>

                        <select
                          {...register(`relationships.${relationship}.gender`, {
                            required: "Gender is required",
                          })}
                          value={formData.relationships[relationship].gender}
                          onChange={(e) =>
                            handleInputChange(
                              relationship,
                              "gender",
                              e.target.value
                            )
                          }
                          className="h-10 px-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="">Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>

                      {/* Address */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Address
                        </label>
                        <textarea
                          {
                            ...register(
                              `relationships.${relationship}.address`, 
                              { 
                                required: "Address is required",
                                maxLength: {
                                  value: 120,
                                  message: "Address cannot exceed 120 characters"
                                },
                                validate: (value) => validateAddress(value) === true || "Only letters, numbers, spaces and #, &, /, . are allowed",
                              }
                            )
                          }
                          value={formData.relationships[relationship].address}
                          onChange={(e) =>
                            handleInputChange(
                              relationship,
                              "address",
                              e.target.value
                            )
                          }
                          className=" p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {errors.relationships?.[relationship]?.address && (
                          <span className="text-red-600 text-sm">
                            {errors.relationships[relationship].address.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Children */}
        <div className="mb-4 border border-gray-200 rounded-xl">
          <div className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 focus:ring-4 focus:ring-gray-200 gap-3">
            <label className="block text-sm font-medium text-gray-700">
              Children
            </label>
            <input
              type="checkbox"
              checked={formData.relationships.children.include}
              onChange={() => handleCheckboxChange("children")}
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
          </div>
          {formData.relationships.children.include && (
            <div className="flex flex-col mb-5 justify-between w-full p-5 font-medium rtl:text-right border-t">
              {fields.map((item, index) => (
                <div key={item.id} className="mb-4 flex flex-col gap-6">
                  <div className="flex flex-col justify-between items-start">
                    <div className="flex flex-row justify-between items-center w-full">
                      <label className="block text-sm font-medium text-gray-700">
                        Child {index + 1} Full Name <span className="text-xs text-[#838080] opacity-80">(As written in AADHAR card)</span>
                      </label>
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveChild(index)}
                          className="my-2 w-6 flex justify-center py-1 px-1 border border-transparent rounded-md shadow-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                    <input
                      type="text"
                      {...register(
                        `relationships.children.details[${index}].full_name`,
                        { required: "Child full name is required" ,
                          validate: (value) => validateName(value) === true || "Only letters and spaces are allowed",
                        }
                      )}
                      value={
                        formData.relationships.children.details[index]
                          ?.full_name
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "children.details",
                          `${index}.full_name`,
                          e.target.value
                        )
                      }
                      className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.relationships?.children?.details?.[index]
                      ?.full_name && (
                        <span className="text-red-600 text-sm">
                          {
                            errors.relationships.children.details[index].full_name
                              .message
                          }
                        </span>
                      )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mt-2">
                      Child {index + 1} Date of Birth
                    </label>
                    <input
                      type="date"
                      {...register(
                        `relationships.children.details[${index}].date_of_birth`,
                        { required: "Child date of birth is required", validate: validateAgeForChildren }
                      )}
                      value={
                        formData.relationships.children.details[index]
                          ?.date_of_birth
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "children.details",
                          `${index}.date_of_birth`,
                          e.target.value
                        )
                      }
                      className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.relationships?.children?.details?.[index]
                      ?.date_of_birth && (
                        <span className="text-red-600 text-sm">
                          {
                            errors.relationships.children.details[index]
                              .date_of_birth.message
                          }
                        </span>
                      )}
                    {errors.relationships?.children?.details?.[index]?.date_of_birth?.type === "validate" && <span className="text-red-600 text-sm">Age should be greater than 91 days and less than 24 years</span>}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mt-2">
                      Gender
                    </label>
                    <select
                      {...register(
                        `relationships.children.details[${index}].gender`,
                        { required: "Gender is required" }
                      )}
                      value={
                        formData.relationships.children.details[index]?.gender
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "children.details",
                          `${index}.gender`,
                          e.target.value
                        )
                      }
                      className="h-10 px-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errors.relationships?.children?.details?.[index]
                      ?.gender && (
                        <span className="text-red-600 text-sm">
                          {
                            errors.relationships.children.details[index].gender
                              .message
                          }
                        </span>
                      )}
                  </div>

                  {/* use same communication */}
                  <div
                    className="flex items-start justify-start gap-2 md:px-2 cursor-pointer"
                    onClick={() =>
                      handleCommCheckboxChangeForChildren("children", index)
                    }
                  >
                    <input
                      type="checkbox"
                      checked={
                        !formData.relationships.children.details[index]
                          .comm_change
                      }
                      className="h-4 w-4 text-gray-600 border-gray-300 rounded default:bg-gray-300"
                    />
                    <p className="block text-xs font-medium text-gray-700">
                      Use same communication details as the primary user
                    </p>
                  </div>

                  {formData.relationships.children.details[index]
                    .comm_change && (
                      <div className="flex flex-col gap-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Mobile
                          </label>
                          <input
                            type="text"
                            {...register(
                              `relationships.children.details[${index}].mobile`,
                              {
                                required: "Mobile number is required",
                                minLength: 10,
                                maxLength: 10,
                              }
                            )}
                            value={
                              formData.relationships.children.details[index]
                                .mobile
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "children.details",
                                `${index}.mobile`,
                                e.target.value
                              )
                            }
                            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {errors.relationships?.children?.details?.[index]
                            ?.mobile && (
                              <span className="text-red-600 text-sm">
                                {
                                  errors.relationships.children.details[index]
                                    .mobile.message?.length > 0
                                    ? errors.relationships.children.details[index]
                                      .mobile.message
                                    : "Mobile number is required"
                                }
                              </span>
                            )}
                        </div>

                        {/* email */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <input
                            type="email"
                            {...register(
                              `relationships.children.details[${index}].email`,
                              { required: "Email is required" }
                            )}
                            value={
                              formData.relationships.children.details[index].email
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "children.details",
                                `${index}.email`,
                                e.target.value
                              )
                            }
                            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {errors.relationships?.children?.details?.[index]
                            ?.email && (
                              <span className="text-red-600 text-sm">
                                {
                                  errors.relationships.children.details[index].email
                                    .message
                                }
                              </span>
                            )}
                        </div>

                        {/* address */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Address
                          </label>
                          <textarea
                            {...register(
                              `relationships.children.details[${index}].address`,
                              {
                                required: "Address is required",
                                maxLength: {
                                  value: 120,
                                  message: "Address cannot exceed 120 characters"
                                },
                                validate: (value) => validateAddress(value) === true || "Only letters, numbers, spaces and #, &, /, . are allowed"
                              }

                            )}
                            value={
                              formData.relationships.children.details[index]
                                .address
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "children.details",
                                `${index}.address`,
                                e.target.value
                              )
                            }
                            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {errors.relationships?.children?.details?.[index]
                            ?.address && (
                              <span className="text-red-600 text-sm">
                                {
                                  errors.relationships.children.details[index]
                                    .address.message
                                }
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                </div>
              ))}

              <button
                type="button"
                onClick={() => handleAddChild()}
                className="my-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Child
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRelationshipsForm;