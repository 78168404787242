import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/config';

export const healthInsuranceApi = createApi({
    reducerPath: 'healthInsuranceSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl,
        prepareHeaders: (headers) => {
            // Add Basic Authentication header
            headers.set('Authorization', `${window?.localStorage.getItem('userid')}`);
            return headers;
        },
    }),
    tagTypes: ['HealthInsurance'],
    endpoints: (builder) => ({
        getHealthProducts: builder.query({
            query: () => ({
                url: '/health_insurance/products',
                method: 'GET',
            }),
            providesTags: ['HealthInsurance'],
        }),
    }),
});

export const { useGetHealthProductsQuery } = healthInsuranceApi;
