import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";


let firebaseConfig = {
  apiKey: "AIzaSyBDNR2Gau1EPKbQSBPE3XoLDo23MwYbCbI",
  authDomain: "insurance-padosi-demo.firebaseapp.com",
  projectId: "insurance-padosi-demo",
  storageBucket: "insurance-padosi-demo.appspot.com",
  messagingSenderId: "8900656111",
  appId: "1:8900656111:web:66d3ee5df97f817e2d941a",
  measurementId: "G-2FFWZYZD3P"
};

if(process.env.REACT_APP_ENV === 'production'){
  firebaseConfig = {
    apiKey: "AIzaSyDuyHVCldMY_x1vEMLMgkxMwQKWkWV3I3s",
    authDomain: "insurance-padosi.firebaseapp.com",
    projectId: "insurance-padosi",
    storageBucket: "insurance-padosi.appspot.com",
    messagingSenderId: "927519818570",
    appId: "1:927519818570:web:be3507a84b67a51256b7b3",
    measurementId: "G-GCWG4WMFYG"
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)

const getBearerToken = async (idToken) => { 
  try {
    const userCredential = await signInWithCustomToken(auth, idToken);
    // Signed in
    const user = userCredential.user;
    return user.accessToken;
  } catch (error) {
    console.error(error);
    const errorCode = error.code;
    const errorMessage = error.message;
    // Handle error
    throw error;
  }
};



export { auth, analytics, getBearerToken };
