import React from "react";
import Check from "../../../../assets/images/port-icons/check-circle.svg";
import { formatCash, getCorpusRequiredAge } from "../../../../utils/common";

const ChildPlanCard = ({
	childOneCorpus,
	childTwoCorpus,
	childOneAge,
	childTwoAge,
	annualInvestment,
	canShow,
}) => {
	const insuranceFeatures = (text) => (
		<div className="flex w-full items-center">
			<img
				loading="lazy"
				src={Check}
				alt="Feature icon"
				className="w-8 aspect-square"
			/>
			<p
				className={`ml-2 text-sm text-[#207058] ${!canShow ? "blur-sm" : ""
					}`}
			>
				{text}
			</p>
		</div>
	);

	function calculateChildCorpusYear(childAge) {
		var now = new Date();
		var currentYear = now.getFullYear();
		var corpusRequiredAge = getCorpusRequiredAge(childAge);
		var childAgeInt = parseInt(childAge);

		var childOneCorpusYear =
			currentYear + (corpusRequiredAge - childAgeInt);
		return childOneCorpusYear;
	}

	return (
		<div className="pl-1.5 rounded-2xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-11/12 md:w-10/12 mt-10 bg-green-300">
			<div className="bg-white rounded-2xl p-5">
				<div className="flex flex-col pl-0 md:pl-5 w-full   max-md:max-w-full">
					<header className="self-start ml-2 text-xl text-green-700 max-md:max-w-full">
						<h1 className="text-2xl font-bold leading-[62px]">
							{childTwoAge != null
								? "Children Plan"
								: "Child Plan"}
						</h1>
						<p className="text-zinc-500">
							Securing the{" "}
							{childTwoAge != null ? "children" : "child's"}{" "}
							Future with sufficient corpus to support their
							education
						</p>
					</header>
					<hr className="shrink-0 mt-6 border-solid bg-teal-800 bg-opacity-80 border-[1px] border-teal-800 border-opacity-80 h-[1px] max-md:max-w-full" />
					<main className="flex flex-col md:flex-row items-center gap-5 self-center mt-6 w-full max-w-screen-lg max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
						<article className="flex flex-col items-center flex-1">
							<h2 className="mx-6 text-xl text-center font-bold tracking-widest text-teal-800 max-md:mx-2.5">
								Corpus Requirement
							</h2>
							<div
								className="flex flex-row p-3 mt-3 text-lg text-center justify-around w-11/12 md:w-6/12 md-10/12
						rounded-xl border-teal-800 border-solid border-[3px] text-indigo-950"
							>
								<p className="mt-2 flex flex-col items-start leading-10">
									<span className="text-indigo-950">
										Corpus
									</span>
									<span
										className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
											}`}
									>
										{formatCash(childOneCorpus)}
									</span>
									{childTwoCorpus > 0 &&
										<span
											className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
												}`}
										>
											{formatCash(childTwoCorpus)}
										</span>
									}
								</p>
								<p className="mt-2 flex flex-col items-start leading-10">
									<span className="text-indigo-950">
										Year
									</span>
									<span
										className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
											}`}
									>
										{calculateChildCorpusYear(childOneAge)}
									</span>
									{childTwoCorpus > 0 &&
										<span
											className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
												}`}
										>
											{calculateChildCorpusYear(childTwoAge)}
										</span>
									}
								</p>
							</div>
							{annualInvestment != null &&
								annualInvestment > 0 && (
									<div className="flex flex-col pl-0 md:pl-5 mt-2 w-full max-md:max-w-full">
										<h2 className="self-start mt-5 ml-2 text-xl font-semibold leading-10 text-indigo-950 max-md:mt-10 max-md:max-w-full">
											Recommendation –
										</h2>
										<div className="self-center mt-2.5 w-full  max-md:max-w-full">
											<div className="flex flex-col items-center mx-10 my-5 max-md:ml-0 max-md:w-full">
												<div className="w-full ">
													<div className="grid grid-cols-2 md:grid-cols-3 gap-4">
														<div className="flex flex-col col-span-1">
															<p className="font-bold">
																Annual
																Investment
															</p>
															<div className="col-span-1">
																{formatCash(
																	annualInvestment
																)}
															</div>
														</div>
														<div className="flex flex-col col-span-1">
															<p className="font-bold">
																Plan
															</p>
															<div className="col-span-1">
																Traditional/ULIP
															</div>
														</div>
														<div className="flex flex-col col-span-1">
															<p className="font-bold">
																Recalibration
															</p>
															<div className="col-span-1">
																Every 4 years
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							<div className="flex flex-col pl-0 md:pl-5 mt-2 w-full max-md:max-w-full">
								<h2 className="self-start mt-5 ml-2 text-xl font-semibold leading-10 text-indigo-950 max-md:mt-10 max-md:max-w-full">
									Why do we recommend it –
								</h2>
								<div className="self-center mt-2.5 w-full  max-md:max-w-full">
									<div className="flex flex-col items-center w-10/12 mx-10 my-5 max-md:ml-0 max-md:w-full">
										<div className="w-full ">
											<div className="grid grid-cols-1 md:grid-cols-1 gap-5">
												{insuranceFeatures(
													"Let your child’s dreams take flight, accumulate funds for their college, marriage or business"
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</article>
					</main>
				</div>
			</div>
		</div>
	);
};

export default ChildPlanCard;
