import React from 'react';
import { useNavigate } from 'react-router-dom';

const coverMapReverse = {
  2000000.00: '20 Lakhs',
  2500000.00: '25 Lakhs',
  5000000.00: '50 Lakhs'
};

const deductibleMapReverse = {
  500000.00: '5 Lakhs',
  700000.00: '7 Lakhs',
  1000000.00: '10 Lakhs'
};

const SuperTopupPremium = ({ sumInsured, deductible }) => {
  const navigate = useNavigate();

  const handleRecalculatePremium = () => {
    navigate('/super-topup/premium');
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4 py-8 sm:p-8 border-t">
      {/* Title */}
      <div className="text-lg font-semibold mb-2">Super Top-Up Details</div>
      
      {/* Sum Insured */}
      <div className="mb-2 flex justify-between">
        <span className="font-medium">Sum Insured</span>
        <span>{coverMapReverse[sumInsured]}</span>
      </div>

      {/* Deductible */}
      <div className="mb-4 flex justify-between">
        <span className="font-medium">Deductible</span>
        <span>{deductibleMapReverse[deductible]}</span>
      </div>

      {/* Button */}
      <div className="flex justify-end">
        <button
          onClick={handleRecalculatePremium}
          className="text-xs lg:text px-2 lg:px-10 mr-4 py-2 lg:py-3 w-full ml-8 lg:ml-0 lg:w-auto bg-[#F4CD2A] cursor-pointer font-semibold text-black rounded-xl shadow-xl"
        >
          Recalculate Premium
        </button>
      </div>
    </div>
  );
};

export default SuperTopupPremium;
