import React from 'react';
import { useNavigate } from 'react-router-dom';

function EnrollButton({ redirectUrl }) {
    const navigate = useNavigate();
    return (
        <button onClick={() => navigate(redirectUrl)} className="bg-[#F4CD2A] text-blue-950 py-1 md:py-2 px-8 md:px-16 rounded-lg inline-block md:mb-4 text-lg md:text-xl font-bold">
            Enroll Now
        </button>
    );
}

export default EnrollButton;