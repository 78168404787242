import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PROPERTIES } from '..';
import ROUTES from '../utils/constants/Routes';


const isProductisValid = (allProducts, product_url) => {
    if (product_url && allProducts) {
        return allProducts.some(item => item.url_suffix === product_url);
    }
}

export const findDynamicRoute = (path) => {
    return ROUTE_PROPERTIES.find(route => {
        // Convert the route path into a regular expression
        const regex = new RegExp('^' + route.path.replace(/\/:[^/]+/g, '/([^/]+)') + '$');

        // Test if the provided path matches the regular expression
        return regex.test(path);
    });
};



const useAuthRedirect = (sessionData, currentPath, products, productUrl, userProducts, currentProduct, searchParams) => {
    const navigate = useNavigate();
    const dynamicRoute = findDynamicRoute(currentPath); // Find the dynamic route

    useEffect(() => {
        const paramsObject = {};
        searchParams.forEach((value, key) => {
            paramsObject[key] = value;
        });
        if (!sessionData) {
            // User is not logged in
            if (dynamicRoute.requiredAuth) {
                if (isProductisValid(products, productUrl)) {
                    navigate(`/${productUrl}/login`);
                } else {
                    navigate(ROUTES.LOGIN);
                }
            }
        } else {
            // User is logged in
            if (userProducts && currentPath.includes('login')) {
                if (currentProduct) {
                    const productId = currentProduct.id;
                    const userProduct = userProducts.find((p) => p.product_id === productId);
                    if (userProduct && userProduct.is_converted) {
                        navigate(`/${currentProduct.url_suffix}/dashboard`, { state: paramsObject });
                    } else {
                        navigate(`/${currentProduct.url_suffix}/questions`, { state: paramsObject });
                    }
                } else {
                    navigate(ROUTES.DASHBOARD, { state: paramsObject });
                }
            }
        }
    }, [products, productUrl, userProducts, currentProduct, navigate, sessionData, currentPath]);
};

export default useAuthRedirect;
