import React from 'react';

const FooterNavBar = () => {
    return (
        <div className="flex flex-col items-end justify-center">
            <a href="https://www.insurancepadosi.in/experian-terms-and-conditions.html" className="text-white block mb-4 font-bold">Legal</a>
            <a href="https://www.insurancepadosi.in/terms-of-use.html" className="text-white block mb-4">Terms of Use</a>
            <a href="https://www.insurancepadosi.in/privacy-policy.html" className="text-white block mb-4">Privacy Policy</a>
            <a href="https://www.insurancepadosi.in/refund-policy.html" className="text-white block">Refund Policy</a>
        </div>
    );
};

export default FooterNavBar;
