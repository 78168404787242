import React from 'react';
import Logo from '../../../assets/images/logo.png';
import FooterNavBar from './FooterNavBar';
import FooterFollowUs from './FooterFollowUs';
import MobileFooter from './MobileFooter';

const Footer = () => {
    return (
        <>
            <footer className="bg-emerald-700 p-6 py-10 mt-4 px-4 lg:px-28 hidden md:block">
                <div className="container mx-auto">
                    <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center">
                        <div className="w-full lg:w-auto flex justify-center lg:justify-start">
                            <div className="flex items-start flex-col mb-4">
                                <img src={Logo} alt="Logo" className="mr-2 h-12 w-18" />
                                <p className="text-white mt-2">Product of Karnodakshayi Insurance Pvt Ltd</p>
                                <p className="text-white mt-2">Email us: customersupport@insurancepadosi.in</p>
                                <FooterFollowUs />
                                <a href="https://www.insurancepadosi.in/blog/" className="text-white block mt-2">Blogs</a>
                                <a href="https://www.insurancepadosi.in/about-us.html" className="text-white mt-2">About Us</a>
                                <div className="md:hidden mt-2">
                                    <FooterNavBar />
                                </div>
                                
                            </div>
                        </div>
                        <div className="w-full lg:w-auto flex justify-center lg:justify-end mt-4 lg:mt-0 hidden md:block">
                            <FooterNavBar />
                        </div>
                    </div>
                    <p className="text-white mt-2 font-bold">Karnodakshayi Insurance Private Limited is recognized as a Finance Technology startup by DPIIT | Certificate No. DIPP143332</p>
                    <p className="text-white mt-2 font-bold">Copyright @ insurancepadosi. 2023</p>
                </div>
            </footer>
            <MobileFooter />
        </>
    );
};

export default Footer;
