import React from "react";
import Check from "../../../../assets/images/port-icons/check-circle.svg";
import { formatCash } from "../../../../utils/common";

const AlimentCard = ({
	cancerCareValue,
	cardiacCareValue,
	retirementYears,
	canShow,
}) => {
	return (
		<div className="pl-1.5 rounded-2xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-11/12 md:w-10/12 mt-10 bg-green-300">
			<div className="bg-white rounded-2xl p-5">
				<div className="flex flex-col pl-0 md:pl-5 w-full   max-md:max-w-full">
					<header className="self-start ml-2 text-xl text-green-700 max-md:max-w-full">
						<h1 className="text-2xl font-bold leading-[62px]">
							Ailment Related
						</h1>
						<p className="text-zinc-500">
							Financial Protection for Critical Illness
							Conditions.
						</p>
					</header>
					<hr className="shrink-0 mt-6 border-solid bg-teal-800 bg-opacity-80 border-[1px] border-teal-800 border-opacity-80 h-[1px] max-md:max-w-full" />
					<main className="flex flex-col md:flex-row items-center gap-5 self-center mt-6 w-full max-w-screen-lg max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
						<article className="flex flex-col items-center flex-1">
							<h2 className="mx-6 text-xl text-center font-bold tracking-widest text-teal-800 max-md:mx-2.5">
								Cancer Care
							</h2>
							<div
								className="flex flex-col p-3 mt-3 text-lg text-center md:w-6/12 md-10/12
						rounded-xl border-teal-800 border-solid border-[3px] text-indigo-950"
							>
								<p className="justify-center px-2 py-3">
									<span className="text-indigo-950">
										Cover :{" "}
									</span>
									<span
										className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
											}`}
									>
										{formatCash(cancerCareValue)}
									</span>
								</p>
								<p className="mt-2 leading-10">
									<span className="text-indigo-950">
										Tenure :{" "}
									</span>
									<span
										className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
											}`}
									>
										{retirementYears} Years
									</span>
								</p>
							</div>
						</article>
						<article className="flex flex-col items-center flex-1">
							<h2 className="mx-6 text-xl text-center font-bold tracking-widest text-teal-800 max-md:mx-2.5">
								Cardiac Care
							</h2>
							<div
								className="flex flex-col px-4 py-3 mt-3 text-lg text-center md:w-6/12 md-10/12
						rounded-xl border-teal-800 border-solid border-[3px] text-indigo-950"
							>
								<p className="justify-center px-2 py-3">
									<span className="text-indigo-950">
										Cover :{" "}
									</span>
									<span
										className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
											}`}
									>
										{formatCash(cardiacCareValue)}
									</span>
								</p>
								<p className="mt-2 leading-10">
									<span className="text-indigo-950">
										Tenure :{" "}
									</span>
									<span
										className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
											}`}
									>
										{retirementYears} Years
									</span>
								</p>
							</div>
						</article>
					</main>
				</div>
			</div>
		</div>
	);
};

export default AlimentCard;
