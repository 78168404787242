import React from 'react';
import failedIcon from '../../assets/images/order-failed.svg';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../utils/constants/Routes';

const PaymentSuccess = ({ product_url }) => {
    console.log(product_url);
    let navigate = useNavigate();
    return (
        <div className="text-center py-8">
            <div className="bg-green-100 rounded-full w-24 h-24 mx-auto flex items-center justify-center">
                <img src={failedIcon} alt="" />
            </div>
            <h1 className="text-2xl font-bold mt-4">Transaction Failed!</h1>
            <p className="text-gray-500 mt-10">Oops! It seems there was an issue processing your payment.</p>

            <p className="mt-6 font-semibold underline cursor-pointer">
                <a onClick={() => navigate(product_url ? `/${product_url}/login` : `/dashboard`)} className="text-blue-600">Go back</a>
            </p>
        </div>
    );
}

export default PaymentSuccess;