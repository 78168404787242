import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	handleValues,
	handleChildrenClick,
	handleQuestionSubmit,
	handleRelativesClick,
	// handleYesNoClick,
	renderSelectBox,
	renderChildrenSection,
	renderInputBox,
	eldestYoungestAgeError,
	retirementAgeError,
	isChildAgeValidError,
} from "../../../../utils/helpers/questions";
import { useSelector } from "react-redux";
import { isMobile } from "../../../../utils/validations";
import TextLoader from "../../../Common/Loaders/TextLoader";
import Coupon from "../../Common/UserDetailsForm/Coupon";
import { useApi } from "../../../../store/services/useApi";
import { handleYesNoClick as handleYesNoClickHelper } from '../../../../utils/helpers/questions';

import {
	addCommas,
	numberToIndianCurrencyText,
	removeCommas,
} from "../../../../utils/common";
import ProgressBar from "../../../Common/Loaders/ProgressBar";
import STRINGS from "../../../../utils/constants/strings";
import QUESTIONS from "../../../../utils/constants/questions";
import ROUTES from "../../../../utils/constants/Routes";
import { useLocation } from 'react-router-dom';
import { useAnswerPostMutation } from "../../../../store/services/answer";
import { useGetHealthProductsQuery } from "../../../../store/services/healthproducts";
import Spinner from "../../../Common/Loaders/Spinner";

const Questions = () => {
	const sessionData = useSelector((state) => state?.session?.sessionData);

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const location = useLocation();
	const [previousQuestion, setPreviousQuestion] = useState(false);
	const [selectedChildren, setSelectedChildren] = useState([]);
	const [relativesValues, setRelativesValues] = useState([]);
	const [userAge, setUserAge] = useState(null);
	const [childAges, setChildAges] = useState({
		singleChildAge: "",
		firstChildAge: "",
		secondChildAge: "",
		eldestChildAge: "",
		youngestChildAge: "",
	});
	const [retirementAge, setRetirementAge] = useState(""); // Add state for retirement age
	const [annualIncome, setAnnualIncome] = useState("");
	const [isHandleChildrenClickInvoked, setIsHandleChildrenClickInvoked] =
		useState(false);
	const [triggerSelectedChildrenValue, setSelectedChildrenValue] = useState(
		{}
	);
	const [totalQuestions, setTotalQuestions] = useState(4);

	const [healthValues, setHealthValues] = useState([]);
	const [isEmployer, setEmployer] = useState([]);
	const [insuranceProviderValue, setInsuranceProviderValue] = useState("");
	const [employerValue, setEmployerValue] = useState("");
	const [showInsuranceProviderQuestion, setShowInsuranceProviderQuestion] = useState(false);

	const product_url = "ihr"
	const [coupon, setCoupon] = useState(null);
	const [couponApplied, setCouponApplied] = useState(null)
	const [couponLengthError, setCouponLengthError] = useState(false);
	const [showCoupon, setShowCoupon] = useState(false);
	const [productId, setProductId] = useState(null);
	const [pricingId, setPricingId] = useState(null);
	const [pricing, setPricing] = useState(null);
	const [selectedPricing, setSelectedPricing] = useState(null);
	const [finalPrice, setFinalPrice] = useState(0);
	const [orderData, setOrderData] = useState(null);
	const [fullName, setFullName] = useState(null);
	const [mobile, setMobile] = useState(null);
	const [dob, setDob] = useState(null);
	const [loading, setLoading] = useState(false);
	const [emailIdValue, setEmailIdValue] = useState("");

	const userProducts = useSelector((state) => state.products.userProducts);
	const [isProductConverted, setIsProductConverted] = useState(false);

	const currentProduct = useSelector((state) => state.products.currentProduct);
	const products = useSelector((state) => state.products.products);

	const {
		data: couponDetails,
		error: couponError,
		isLoading: couponLoading,
		makeCall: validateDiscountCode,
	} = useApi("POST", "/validate-discount-code");

	const {
		data: orderDetails,
		error: orderError,
		isLoading: creatingOrder,
		makeCall: createOrder,
	} = useApi("POST", "/create-order");

	const {
		data: productPricingDetails,
		error: pricingError,
		isLoading: productPricingloading,
		makeCall: fetchProductPricingDetails,
	} = useApi("GET", "/product/get-details/" + product_url);


	useEffect(() => {
		if (sessionData) {
			setFullName(sessionData.fname + " " + sessionData.lname);
			setMobile(sessionData.mobile);
			setDob(sessionData.dob ? formatDOB(sessionData.dob) : null);
			setEmailIdValue(sessionData?.email || "");
		}
	}, [sessionData]);

	function formatDOB(dateString) {
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are zero-based
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	useEffect(() => {
		if (couponDetails) {
			// console.log(couponApplied)
			setCouponApplied(couponDetails)
			//   console.log(couponApplied)
		}
	}, [couponDetails])

	useEffect(() => {
		if (pricingError) {
			navigate(ROUTES.NOT_FOUND);
		}
		if (productPricingDetails === null && productPricingloading === false) {
			fetchProductPricingDetails();
		} else if (
			productPricingDetails.pricing &&
			productPricingDetails.pricing.length > 0
		) {
			console.log(productPricingDetails)
			const validPricing = productPricingDetails.pricing
			setPricing(validPricing)

			if (validPricing.length > 0) {
				const lastValidPricing = validPricing[validPricing.length - 1];
				setSelectedPricing(lastValidPricing);
				setPricingId(lastValidPricing.id);
				setFinalPrice(lastValidPricing.price);
			}

			setProductId(productPricingDetails.id);
		}
	}, [productPricingDetails, pricingError]);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const isEmployerParam = params.get('isEmployer');
		const healthValuesParam = params.get('healthValues');

		if (isEmployerParam !== null) {
			setEmployer(isEmployerParam === 'true'); // converting to bool
			console.log("Employer", isEmployerParam);
			console.log(isEmployer);
		}
		if (healthValuesParam) {
			setHealthValues(healthValuesParam);
			console.log("health", healthValuesParam);
			console.log(healthValues);
		}
	}, [location.search]);

	// Check if the product is converted
	useEffect(() => {
		if (productPricingDetails && userProducts) {
			const isConverted = userProducts.some(
				(userProduct) =>
					userProduct.product_id === productPricingDetails.id && userProduct.is_converted
			);
			setIsProductConverted(isConverted);
		}
	}, [productPricingDetails, userProducts]);


	const handleYesNoClick = (option) => {
		handleYesNoClickHelper(option, healthValues, setHealthValues);
		if (option === "No") {
			// setShowCoupon(true);
			setShowInsuranceProviderQuestion(true);
			setEmployer([])
		} else {
			setShowCoupon(false);
			setShowInsuranceProviderQuestion(false);
			setEmployer([])
		}
	};

	const handleProviderYesNoClick = (option) => {
		// handleYesNoClickHelper(option, isEmployer, setEmployer);
		setEmployerValue(option);
		if (option === "No") {
			setShowCoupon(true);
			setEmployer(false);
		} else {
			setShowCoupon(false);
			setEmployer(true);
		}
	};

	const handleInsuranceSourceClick = (value) => {
		setInsuranceProviderValue(value);
		if (value === "You" || value === "Both") {
			setEmployer(false);
		} else {
			setEmployer(true);
		}
	};

	const handleDiscountCode = (discountCode) => {
		if (discountCode?.length > 0) {
			setCouponLengthError(false);
			validateDiscountCode({ discount_code: discountCode, product_pricing_id: selectedPricing?.id });
		} else {
			setCouponLengthError(true);
		}
	};

	// useEffect(() => {
	// 	if (healthValues === "No") {
	// 		setTotalQuestions(5);
	// 	}
	// 	if (healthValues === "Yes" && totalQuestions === 5) {
	// 		setTotalQuestions(6);
	// 	}
	// }, [healthValues]);

	useEffect(() => {
		if (healthValues === "No") {
			if (isEmployer === false) {
				setTotalQuestions(5);
			} else if (isEmployer === true) {
				setTotalQuestions(6);
			}
		} else if (healthValues === "Yes") {
			setTotalQuestions(6);
		}
	}, [healthValues, isEmployer]);

	// useEffect(() => {
	// 	if (relativesValues.includes("Children")) {
	// 		setTotalQuestions(totalQuestions + 1);
	// 	}
	// }, [relativesValues]);
	// const [insurerName, setInsurerName] = useState('');
	const [planValue, setPlanValue] = useState("");

	const formateandSetPlanValue = (value) => {
		setPlanValue(removeCommas(value));
	};
	// const [planName, setPlanName] = useState('');

	const [selectedInsurer, setSelectedInsurer] = useState("");
	const [selectedProduct, setSelectedProduct] = useState("");

	const [isRepeatedUser, setIsRepeatedUser] = useState(false);

	const navigate = useNavigate();

	// const loginData = useSelector((state) => {
	// 	return state?.login?.loginData;
	// });

	// Use the useAnswerPostMutation hook
	const [answerPost] = useAnswerPostMutation();
	let childrenOption = {};

	// Fetch insurer and product data from the API
	const {
		data: productsData,
		error: productsError,
		isLoading: productsLoading,
	} = useGetHealthProductsQuery();

	// Convert object keys to array of options
	const insurerOptions = Object.keys(productsData || {}).map((insurer) => ({
		value: insurer,
		label: insurer,
	}));

	// Add a default option for "Select Insurer Provider"
	insurerOptions.unshift({
		value: "",
		label: "Select Insurer Provider",
	});

	const handleInsurerSelect = (insurer) => {
		setSelectedInsurer(insurer);
		setSelectedProduct(""); // Reset selected product when a new insurer is selected
	};

	const filteredProducts = selectedInsurer
		? productsData?.[selectedInsurer] || []
		: [];

	const productOptions = filteredProducts.map((product) => ({
		value: product.id,
		label: product.product_name,
	}));

	// Add a default option for "Select Product"
	productOptions.unshift({
		value: "",
		label: "Select Product",
	});

	function findProductById(data, id) {
		// Loop through each insurer in the data
		for (const insurerName in data) {
			const products = data[insurerName];

			// Loop through each product offered by the insurer
			for (const product of products) {
				if (product.id === parseInt(id)) {
					return product; // Product found, return it
				}
			}
		}

		return null; // Product not found
	}

	useEffect(() => {
		setIsRepeatedUser(true);
	}, [triggerSelectedChildrenValue]);

	useEffect(() => {
		// Check if sessionData is available
		if (sessionData) {
			renderQuestionScreen(sessionData?.quesanws);
			setRelativesValues(sessionData?.quesanws?.[5] || []);
			setSelectedChildren([childrenOption] || []);
			setRetirementAge(sessionData?.quesanws?.[11] || "");
			setChildAges(
				sessionData?.quesanws?.[6] || {
					singleChildAge: "",
					firstChildAge: "",
					secondChildAge: "",
					eldestChildAge: "",
					youngestChildAge: "",
				}
			);
			setAnnualIncome(sessionData?.quesanws?.[20] || "");
			setUserAge(sessionData?.age || null);

			// // setHealthValues(sessionData?.quesanws?.[12] || "");
			// if (sessionData?.quesanws?.[12] !== undefined) {
			// 	let health_employer = JSON.parse(sessionData?.quesanws?.[12]);
			// 	setHealthValues(health_employer.healthValues || "");
			// 	setEmployer(health_employer.isEmployer || "");
			// }
			// if (sessionData?.quesanws?.[13] !== undefined) {
			// 	let insuranceetails = JSON.parse(sessionData?.quesanws?.[13]);
			// 	setPlanValue(insuranceetails.planValue || "");
			// 	let product = findProductById(
			// 		productsData,
			// 		insuranceetails.selectedProduct
			// 	);
			// 	setSelectedInsurer(product?.insurer_name || "");
			// 	setSelectedProduct(insuranceetails.selectedProduct || "");
			// }
		}
	}, [productsData, sessionData]);

	const renderQuestionScreen = (answer) => {
		const answers = Object.keys(answer);
		if (answers.includes("20")) {
			setCurrentQuestion(3);
		} else if (answers.includes("11")) {
			setCurrentQuestion(2);
		} else if (answers.includes("6")) {
			setCurrentQuestion(1);
		} else if (answers.includes("5")) {
			setCurrentQuestion(0);
		} else {
			setCurrentQuestion(0);
		}
	};

	const buttonRef = useRef();

	// useEffect(() => {
	//     const button = buttonRef.current;
	//
	//     if (!button) return; // Check if the ref is available
	//     const childrenObj = sessionData?.quesanws?.[6] ? JSON.parse(sessionData?.quesanws?.[6]) : {};
	//
	//     const observer = new IntersectionObserver(
	//         (entries) => {
	//             if (entries[0].isIntersecting) {
	//                 // The button is in the viewport
	//                 if (childrenObj.singleChildAge !== undefined && childrenObj.singleChildAge !== null && childrenObj.singleChildAge !== '') {
	//                     childrenOption = STRINGS.PanQuestions.question_4.options[0];
	//                 } else if (childrenObj.firstChildAge !== undefined && childrenObj.firstChildAge !== null && childrenObj.firstChildAge !== '') {
	//                     childrenOption = STRINGS.PanQuestions.question_4.options[1];
	//                 } else if (childrenObj.eldestChildAge !== undefined && childrenObj.eldestChildAge !== null && childrenObj.eldestChildAge !== '') {
	//                     childrenOption = STRINGS.PanQuestions.question_4.options[2];
	//                 } else {
	//                     return childrenOption;
	//                 }
	//                 if(childrenOption) {
	//                     setSelectedChildren([childrenOption] || []);
	//                     handleChildrenClick(childrenOption, selectedChildren, setSelectedChildren); // Trigger your function
	//                 }
	//                 observer.disconnect(); // Disconnect the observer to prevent multiple triggers
	//             }
	//         },
	//         { threshold: 0.5 } // Adjust the threshold as needed
	//     );
	//
	//     observer.observe(button);
	//
	//     return () => {
	//         // Clean up the observer when the component unmounts
	//         if (observer) {
	//             observer.disconnect();
	//         }
	//     };
	// }, [currentQuestion]);

	useEffect(() => {
		const button = buttonRef.current;

		if (!button) return; // Check if the ref is available
		const childrenObj = sessionData?.quesanws?.[6]
			? JSON.parse(sessionData?.quesanws?.[6])
			: {};

		const observer = new IntersectionObserver(
			(entries) => {
				if (
					entries[0].isIntersecting &&
					!isHandleChildrenClickInvoked
				) {
					// Check if it's not invoked already
					// The button is in the viewport
					let childrenOption = null;

					if (
						childrenObj.singleChildAge !== undefined &&
						childrenObj.singleChildAge !== null &&
						childrenObj.singleChildAge !== ""
					) {
						childrenOption =
							STRINGS.Questions.question_1.options[0];
					} else if (
						childrenObj.firstChildAge !== undefined &&
						childrenObj.firstChildAge !== null &&
						childrenObj.firstChildAge !== ""
					) {
						childrenOption =
							STRINGS.Questions.question_1.options[1];
					} else if (
						childrenObj.eldestChildAge !== undefined &&
						childrenObj.eldestChildAge !== null &&
						childrenObj.eldestChildAge !== ""
					) {
						childrenOption =
							STRINGS.Questions.question_1.options[2];
					}

					if (childrenOption) {
						setSelectedChildren([childrenOption] || []);
						setSelectedChildrenValue(childrenOption);
						handleChildrenClick(
							childrenOption,
							selectedChildren,
							setSelectedChildren
						); // Trigger your function
						setIsHandleChildrenClickInvoked(true);
					}

					observer.disconnect(); // Disconnect the observer to prevent multiple triggers
				}
			},
			{ threshold: 0.5 } // Adjust the threshold as needed
		);

		observer.observe(button);

		return () => {
			// Clean up the observer when the component unmounts
			if (observer) {
				observer.disconnect();
			}
		};
	}, [currentQuestion]);

	const answer = () => {
		if (currentQuestion === 0) {
			return relativesValues;
		} else if (currentQuestion === 1) {
			const {
				singleChildAge,
				firstChildAge,
				secondChildAge,
				youngestChildAge,
				eldestChildAge,
			} = childAges;
			return {
				singleChildAge,
				firstChildAge,
				secondChildAge,
				eldestChildAge,
				youngestChildAge,
			};
		} else if (currentQuestion === 2) {
			return retirementAge;
		} else if (currentQuestion === 3) {
			return annualIncome;
		} else if (currentQuestion === 4) {
			return {
				healthValues,
				isEmployer,
			};
		} else if (currentQuestion === 5) {
			return {
				selectedProduct,
				planValue,
			};
		}
		return "";
	};

	const sendAnswer = () => {
		answerPost({
			// userid: loginData?.userid || localStorage.getItem('userid') || '',
			quesid: QUESTIONS[currentQuestion]?.id,
			anws: answer(),
		});
	};

	const handleCreateOrder = async () => {
		setLoading(true);
		const orderData = {
			fullName: fullName,
			mobile: mobile,
			dob: dob,
			email: emailIdValue,
			product_id: productId,
			product_pricing_id: pricingId,
			amount: finalPrice,
			discount_id: couponApplied?.id || null,
			discount_coupon_id: couponApplied?.discount_coupon_id || null,
			relationships: {}
		};

		try {
			await createOrder(orderData);
			console.log("Order created successfully", orderData);

			// Navigate to the success screen
			navigate(ROUTES.IHR_DASHBOARD, {
				replace: true,
				state: { refreshSession: true },
			});
			console.log("Navigation to success screen");
		} catch (error) {
			console.error("Error while handling questionnaire completion:", error);
		}
	};

	useEffect(() => {
		// Check if the user has completed all questions
		if (currentQuestion === totalQuestions) {
			handleCreateOrder();
		}
	}, [currentQuestion]);

	useEffect(() => {
		if (hasChildWithValue1) {
			setChildAges({
				singleChildAge:
					childAges.singleChildAge || sessionData?.quesanws?.[6]
						? JSON.parse(sessionData?.quesanws?.[6]).singleChildAge
						: "",
				firstChildAge: "",
				secondChildAge: "",
				eldestChildAge: "",
				youngestChildAge: "",
			});
		} else if (hasChildWithValue2) {
			setChildAges({
				singleChildAge: "",
				firstChildAge:
					childAges.firstChildAge || sessionData?.quesanws?.[6]
						? JSON.parse(sessionData?.quesanws?.[6]).firstChildAge
						: "",
				secondChildAge:
					childAges.secondChildAge || sessionData?.quesanws?.[6]
						? JSON.parse(sessionData?.quesanws?.[6]).secondChildAge
						: "",
				eldestChildAge: "",
				youngestChildAge: "",
			});
		} else if (hasChildWithValue3) {
			setChildAges({
				singleChildAge: "",
				firstChildAge: "",
				secondChildAge: "",
				eldestChildAge:
					childAges.eldestChildAge || sessionData?.quesanws?.[6]
						? JSON.parse(sessionData?.quesanws?.[6]).eldestChildAge
						: "",
				youngestChildAge:
					childAges.youngestChildAge || sessionData?.quesanws?.[6]
						? JSON.parse(sessionData?.quesanws?.[6])
							.youngestChildAge
						: "",
			});
		}
	}, [selectedChildren]);

	const hasChildWithValue1 = selectedChildren?.some(
		(child) => child.value === "1"
	);
	const hasChildWithValue2 = selectedChildren?.some(
		(child) => child.value === "2"
	);
	const hasChildWithValue3 = selectedChildren?.some(
		(child) => child.value === "3+"
	);

	const isButtonDisabled = () => {
		// Function to check if the next button should be disabled based on the current question
		if (currentQuestion === 0) {
			return relativesValues.length === 0;
		} else if (currentQuestion === 1) {
			return (
				selectedChildren.length === 0 ||
				(childAges?.singleChildAge?.length === 0 &&
					(childAges?.firstChildAge?.length === 0 ||
						childAges?.secondChildAge?.length === 0) &&
					(childAges?.eldestChildAge?.length === 0 ||
						childAges?.youngestChildAge?.length === 0)) ||
				eldestYoungestAgeError(
					childAges.eldestChildAge,
					childAges.youngestChildAge
				) ||
				eldestYoungestAgeError(
					childAges.firstChildAge,
					childAges.secondChildAge
				) ||
				(childAges?.singleChildAge?.length !== 0 &&
					isChildAgeValidError(childAges.singleChildAge, 0, true)) ||
				((childAges?.firstChildAge?.length !== 0 ||
					childAges?.secondChildAge?.length !== 0) &&
					isChildAgeValidError(
						childAges.firstChildAge,
						childAges.secondChildAge
					)) ||
				((childAges?.eldestChildAge?.length !== 0 ||
					childAges?.youngestChildAge?.length !== 0) &&
					isChildAgeValidError(
						childAges.eldestChildAge,
						childAges.youngestChildAge
					))
			);
		} else if (currentQuestion === 2) {
			return (
				retirementAge.length === 0 ||
				retirementAgeError(retirementAge, userAge)
			);
		} else if (currentQuestion === 3) {
			return !annualIncome || annualIncome.trim() === "";
		} else if (currentQuestion === 4) {
			return healthValues.length === 0 || isEmployer.length === 0;
		} else if (currentQuestion === 5) {
			return (
				// selectedInsurer.length === 0 ||
				planValue.length === 0
			);
		}
		return false;
	};

	// Calculate whether the button should be disabled or not
	const isDisabled = isButtonDisabled();
	const _addBtnDisableClass = isDisabled
		? "bg-gray-200 text-gray-500 cursor-not-allowed font-bold rounded-xl shadow-xl"
		: "bg-[#f5c147] cursor-pointer font-bold text-black rounded-xl shadow-xl";

	const percentage = ((currentQuestion + 1) / totalQuestions) * 100;

	return (
		<>
			{loading && <Spinner />}
			<div
				className={
					"lg:relative lg:flex lg:flex-row gap-8 bg-white p-2"
				}
			>
				{/* Conditional rendering of questions */}
				{currentQuestion < totalQuestions ? (
					<>
						<div className="lg:w-1/2 flex flex-row lg:flex lg:flex-col bg-green-100 lg:bg-white lg:p-6 justify-center items-center lg:shadow-lg lg:m-8">
							{/* Progress bar */}
							<div className="lg:w-auto lg:h-auto ">
								<ProgressBar percentage={percentage} />
							</div>

							<div className="px-2">
								{/* Description */}
								<p className="text-xs lg:text-sm text-bold font-bold text-center text-gray-600">
									{QUESTIONS[currentQuestion].text}
								</p>
							</div>

							{/* Image */}
							<img
								// src={journeyimage}
								src={QUESTIONS[currentQuestion].imageUrl}
								alt="Image"
								className="mt-4 hidden lg:block"
								style={{ width: "250px", height: "250px" }}
							/>
						</div>

						<>
							<div
								className={
									"lg:w-1/2 lg:flex mt-4 lg:mt-7 lg:mr-8  lg:flex-col p-2"
								}
							>
								<>
									{/* Question title */}
									<p className="font-bold text-blue-950 text-xl lg:text-3xl">
										{QUESTIONS[currentQuestion].title}
									</p>

									{/* Meta title */}
									<p className="font-bold text-sm lg:text-xl text-blue-950 mt-6">
										{QUESTIONS[currentQuestion].meta_title}
									</p>

									<div className={`lg:mt-4`}>
										{currentQuestion === 0 ? (
											QUESTIONS[currentQuestion].options.map(
												(option, index) => (
													<button
														key={index}
														onClick={() =>
															handleRelativesClick(
																option,
																relativesValues,
																setRelativesValues
															)
														}
														className={`border border-gray-400 rounded-md text-sm font-bold text-gray-600 lg:px-12 px-24 py-3 mr-6 lg:mr-4 lg:ml-4 mt-5 w-full lg:w-auto  ${relativesValues.includes(
															option
														)
															? "bg-green-100 text-gray-600"
															: ""
															}`}
													>
														{option}
													</button>
												)
											)
										) : currentQuestion === 1 &&
											!previousQuestion ? (
											relativesValues.includes("Children") ? ( // Only show if 'Children' is selected
												<>
													{QUESTIONS[
														currentQuestion
													].options.map((option, index) => (
														<button
															key={index}
															onClick={() =>
																handleChildrenClick(
																	option,
																	selectedChildren,
																	setSelectedChildren,
																	isRepeatedUser,
																	setIsRepeatedUser
																)
															}
															ref={buttonRef}
															className={`border border-gray-400 rounded-2xl font-bold text-gray-600 text-lg rounded lg:px-8 px-5 py-3 mr-5 lg:mr-0 lg:ml-4 mt-3 lg:[&:nth-child(1)]:ml-0 lg:[&:nth-child(4)]:ml-0 
                                                ${selectedChildren.includes(
																option
															)
																	? "bg-green-100 text-gray-600"
																	: ""
																}
                                                ${triggerSelectedChildrenValue.value ===
																	option.value &&
																	isRepeatedUser
																	? "bg-green-100 text-gray-600"
																	: ""
																}
                                                `}
														>
															{option.value}
														</button>
													))}
													{renderChildrenSection(
														hasChildWithValue1,
														hasChildWithValue2,
														hasChildWithValue3,
														childAges,
														setChildAges
													)}
													{eldestYoungestAgeError(
														childAges.eldestChildAge,
														childAges.youngestChildAge
													) && (
															<div className="text-red-500 text-sm">
																{eldestYoungestAgeError(
																	childAges.eldestChildAge,
																	childAges.youngestChildAge
																)}
															</div>
														)}
													{eldestYoungestAgeError(
														childAges.firstChildAge,
														childAges.secondChildAge
													) && (
															<div className="text-red-500 text-sm">
																{eldestYoungestAgeError(
																	childAges.firstChildAge,
																	childAges.secondChildAge,
																	true
																)}
															</div>
														)}
													{childAges.singleChildAge &&
														isChildAgeValidError(
															childAges.singleChildAge
														) && (
															<div className="text-red-500 text-sm">
																{isChildAgeValidError(
																	childAges.singleChildAge,
																	0,
																	true
																)}
															</div>
														)}
													{childAges.firstChildAge &&
														childAges.secondChildAge &&
														isChildAgeValidError(
															childAges.firstChildAge,
															childAges.secondChildAge
														) && (
															<div className="text-red-500 text-sm">
																{isChildAgeValidError(
																	childAges.firstChildAge,
																	childAges.secondChildAge
																)}
															</div>
														)}
													{childAges.eldestChildAge &&
														childAges.youngestChildAge &&
														isChildAgeValidError(
															childAges.eldestChildAge,
															childAges.youngestChildAge
														) && (
															<div className="text-red-500 text-sm">
																{isChildAgeValidError(
																	childAges.eldestChildAge,
																	childAges.youngestChildAge
																)}
															</div>
														)}
												</>
											) : (
												// Skip to the third question if 'Children' is not selected
												setCurrentQuestion(2)
											)
										) : currentQuestion === 2 ? (
											<>
												{renderInputBox(
													"number",
													retirementAge,
													(event) =>
														handleValues(
															event,
															setRetirementAge
														),
													{
														placeholder:
															STRINGS.Questions.question_2
																.placeholder,
														min: userAge
															? parseInt(userAge) + 5
															: 40,
														max: 80,
													}
												)}
												{retirementAgeError(
													retirementAge,
													userAge
												) && (
														<div className="text-red-500 text-xs">
															{retirementAgeError(
																retirementAge,
																userAge
															)}
														</div>
													)}
											</>
										) : currentQuestion === 3 ? (
											<>
												{
													renderSelectBox(
														STRINGS.Questions.question_3.label,
														STRINGS.Questions.question_3.options,
														{
															htmlFor:
																STRINGS.Questions.question_3.id,
															id: STRINGS.Questions.question_3.id,
															name: STRINGS.Questions.question_3
																.id,
															value: annualIncome || "",
															onchange: (event) =>
																handleValues(
																	event,
																	setAnnualIncome
																),
														}
													)
												}
												<div className="">
													<Coupon
														coupon={coupon}
														couponApplied={couponApplied}
														setCoupon={setCoupon}
														setCouponApplied={setCouponApplied}
														couponError={couponError}
														couponLengthError={couponLengthError}
														couponLoading={couponLoading}
														productPricingDetails={productPricingDetails}
														handleDiscountCode={handleDiscountCode}
													/>
												</div>
												{couponApplied && (
													<div className="pb-4 lg:pb-0">
														<p className="text-sm sm:text-base text-[#706FC1] mt-2 lg:mt-0">
															Coupon - ({coupon})
														</p>
														<p className="text-xs sm:text-sm font-bold text-[#777575]">
															{couponApplied?.discount_description || "Your coupon has been applied successfully!"}
														</p>
													</div>
												)}
											</>
										) : currentQuestion === 4 ? (
											<>
												{
													QUESTIONS[currentQuestion].options.map(
														(option, index) => (
															<button
																key={index}
																onClick={() => {
																	handleYesNoClick(
																		option,
																		healthValues,
																		setHealthValues
																	);
																	if (option === "No") {
																		setShowInsuranceProviderQuestion(true);
																	} else {
																		setShowInsuranceProviderQuestion(false);
																		setShowCoupon(false);
																	}
																}}
																className={`border border-gray-400 rounded-md text-sm font-bold text-gray-600 px-6 py-3 my-2 w-full lg:w-1/2 lg:my-1 lg:mx-2 flex-1 ${healthValues.includes(option) ? "bg-green-100 text-gray-600" : ""}`}
																style={{ minWidth: '150px' }}
															>
																{option}
															</button>
														)
													)}
												{showInsuranceProviderQuestion && (
													<>
														<p className="font-bold text-blue-950 text-xl lg:text-2xl m-4">
															{
																STRINGS.Questions
																	.question_4
																	.sub_questions[0]
																	.title
															}
														</p>
														{STRINGS.Questions.question_4.sub_questions[0].options.map((subOption, index) => (
															<button
																key={index}
																onClick={() => handleProviderYesNoClick(subOption)}
																className={`border border-gray-400 rounded-md text-sm font-bold text-gray-600 px-6 py-3 my-2 w-full lg:w-1/2 lg:my-1 lg:mx-2 flex-1 ${employerValue === subOption ? "bg-green-100 text-gray-600" : ""}`}
																style={{ minWidth: '150px' }}
															>
																{subOption}
															</button>
														))}
													</>
												)}
												{!showInsuranceProviderQuestion && healthValues.includes("Yes") && (
													<>
														<p className="font-bold text-blue-950 text-xl lg:text-2xl m-4">
															{
																STRINGS.Questions
																	.question_4
																	.sub_questions[1]
																	.title
															}
														</p>
														{STRINGS.Questions.question_4.sub_questions[1].options.map((subOption, index) => (
															<button
																key={index}
																onClick={() => handleInsuranceSourceClick(subOption)}
																className={`border border-gray-400 rounded-md text-sm font-bold text-gray-600 px-6 py-3 my-2 w-full lg:w-1/2 lg:my-1 lg:mx-2 flex-1 ${insuranceProviderValue === subOption ? "bg-green-100 text-gray-600" : ""}`}
																style={{ minWidth: '150px' }}
															>
																{subOption}
															</button>
														))}
													</>
												)}
												{/* <div style={isMobile() ? {} : { marginTop: '200px' }}> */}
												{showCoupon && (
													<Coupon
														coupon={coupon}
														couponApplied={couponApplied}
														setCoupon={setCoupon}
														setCouponApplied={setCouponApplied}
														couponError={couponError}
														couponLengthError={couponLengthError}
														couponLoading={couponLoading}
														productPricingDetails={productPricingDetails}
														handleDiscountCode={handleDiscountCode}
													/>
												)}
												{couponApplied && (
													<div className="pb-4">
														<p className="text-sm sm:text-base text-[#706FC1] mt-2">
															Coupon - ({coupon})
														</p>
														<p className="text-xs sm:text-sm font-bold text-[#777575]">
															{couponApplied?.discount_description || "Your coupon has been applied successfully!"}
														</p>
													</div>
												)}
												{/* </div> */}
											</>

										) : currentQuestion === 5 ? (
											(healthValues.includes("Yes") && isEmployer) || (!healthValues.includes("Yes") && isEmployer) ? (
												<>
													<p className="font-bold text-xs lg:text-sm text-blue-950">
														{STRINGS.Questions.question_5.sub_questions[1].title}
													</p>
													{renderInputBox(
														"text",
														addCommas(planValue),
														(event) => handleValues(event, formateandSetPlanValue),
														{
															placeholder: STRINGS.Questions.question_5.sub_questions[1].placeholder,
														},
														"numeric"
													)}
													<p className="text-xs">
														{numberToIndianCurrencyText(planValue)}
													</p>
													<>
														<div style={isMobile() ? {} : { marginTop: '200px' }}>
															<div className="mt-6 lg:mt-0">
																<Coupon
																	coupon={coupon}
																	couponApplied={couponApplied}
																	setCoupon={setCoupon}
																	setCouponApplied={setCouponApplied}
																	couponError={couponError}
																	couponLengthError={couponLengthError}
																	couponLoading={couponLoading}
																	productPricingDetails={productPricingDetails}
																	handleDiscountCode={handleDiscountCode}
																/>
															</div>
															{couponApplied && (
																<div className="pb-4 lg:pb-0">
																	<p className="text-sm sm:text-base text-[#706FC1] mt-2 lg:mt-0">
																		Coupon - ({coupon})
																	</p>
																	<p className="text-xs sm:text-sm font-bold text-[#777575]">
																		{couponApplied?.discount_description || "Your coupon has been applied successfully!"}
																	</p>
																</div>
															)}
														</div>
													</>
												</>
											) : (healthValues.includes("Yes") && !isEmployer) ? (
												<>
													<div className="flex flex-col gap-2">
														<p className="font-bold text-xs lg:text-sm text-blue-950">
															{STRINGS.Questions.question_5.plan_questions[0].title}
														</p>
														{renderSelectBox(
															"Select Insurer",
															insurerOptions,
															{
																htmlFor: "insurer-select",
																id: "insurer-select",
																name: "insurer-select",
																value: selectedInsurer,
																onchange: (event) => handleInsurerSelect(event.target.value),
															}
														)}
														<p className="text-xs font-bold lg:text-sm text-blue-950 mt-6 lg:mt-0">
															{STRINGS.Questions.question_5.plan_questions[1].title}
														</p>
														{renderInputBox(
															"text",
															addCommas(planValue),
															(event) => handleValues(event, formateandSetPlanValue),
															{
																placeholder: STRINGS.Questions.question_5.plan_questions[1].placeholder,
															},
															"numeric"
														)}
														<p className="text-xs">
															{numberToIndianCurrencyText(planValue)}
														</p>
														<p className="font-bold text-xs lg:text-sm text-blue-950 mt-6 lg:mt-0">
															{STRINGS.Questions.question_5.plan_questions[2].title}
														</p>
														{renderSelectBox(
															"Select Product",
															productOptions,
															{
																htmlFor: "product-select",
																id: "product-select",
																name: "product-select",
																value: selectedProduct,
																onchange: (event) => setSelectedProduct(event.target.value),
															}
														)}
														<div className="mt-6 lg:mt-0">
															<Coupon
																coupon={coupon}
																couponApplied={couponApplied}
																setCoupon={setCoupon}
																setCouponApplied={setCouponApplied}
																couponError={couponError}
																couponLengthError={couponLengthError}
																couponLoading={couponLoading}
																productPricingDetails={productPricingDetails}
																handleDiscountCode={handleDiscountCode}
															/>
														</div>
														{couponApplied && (
															<div className="pb-4 lg:pb-0">
																<p className="text-sm sm:text-base text-[#706FC1] mt-2 lg:mt-0">
																	Coupon - ({coupon})
																</p>
																<p className="text-xs sm:text-sm font-bold text-[#777575]">
																	{couponApplied?.discount_description || "Your coupon has been applied successfully!"}
																</p>
															</div>
														)}
													</div>
												</>
											) : null
										) : (
											""
										)}
									</div>
									{QUESTIONS[currentQuestion].message && (
										<p className="text-xs lg:text-sm lg:rounded text-gray-500 text-left lg:text-center p-2 bg-white lg:bg-green-100 text-md mt-6">
											{QUESTIONS[currentQuestion].message}
										</p>
									)}
								</>
								<div
									className={
										"lg:flex  justify-between flex mt-10 mb-6  lg:right-0 lg:bottom-0 w-full flex-row-reverse"
									}
								>
									<>
										<button
											onClick={() => {
												sendAnswer();
												handleQuestionSubmit(
													currentQuestion,
													totalQuestions,
													setCurrentQuestion,
													false,
													setPreviousQuestion,
													relativesValues
												);
											}}
											className={`text-xs lg:text-lg rounded px-2 lg:px-10 py-2 lg:py-3 w-full ml-8 lg:ml-0 lg:w-auto ${_addBtnDisableClass}`}
											disabled={isDisabled}
										>
											{/* {STRINGS.Questions.next_question} */}
											{currentQuestion === totalQuestions - 1
												? isProductConverted
													? STRINGS.Questions.regenerate_ihr
													: STRINGS.Questions.submit
												: STRINGS.Questions.next_question}
										</button>

										{currentQuestion > 0 && (
											<button
												onClick={() =>
													handleQuestionSubmit(
														currentQuestion,
														totalQuestions,
														setCurrentQuestion,
														true,
														setPreviousQuestion,
														relativesValues
													)
												}
												className="text-xs lg:text-lg py-3 w-full lg:w-auto text-gray-500 cursor-pointer ml-4"
											>
												&larr; Previous Question
											</button>
										)}
									</>
								</div>
							</div>
						</>
					</>
				) : (
					""
				)}
			</div>
		</>
	);
};

export default Questions;
