import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/config";

export const userProductsApi = createApi({
    reducerPath: "userProductSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl,
        prepareHeaders: (headers) => {
            headers.set(
                "Authorization",
                `${window?.localStorage.getItem("userid")}`
            );
            return headers;
        },
    }),
    tagTypes: ["Post"],
    endpoints: (builder) => ({
        getUserProducts: builder.query({
            query: () => ({
                url: "/user/productDetails",
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            providesTags: ["Post"],
        }),
    }),
});

export const { useGetUserProductsQuery } = userProductsApi;
