const ROUTES = {
    NOT_FOUND: '/404',
    LOGIN: '/',
    DASHBOARD: '/dashboard',
    //until ihr gets a details page.
    IHR_LOGIN: '/ihr',
    IHR_PAN: '/ihr/pan',
    IHR_PAN_MASK: '/ihr/pan/mask',
    IHR_QUESTIONS: '/ihr/questions',
    IHR_PAN_QUESTIONS: '/ihr/pan/questions',
    IHR_DASHBOARD: '/ihr/dashboard',
    PRODUCT_LOGIN: '/:product_url/login',
    PRODUCT_DETAILS: '/:product_url',
    PRODUCT_QUESTIONNAIRE: '/:product_url/questions',
    PRODUCT_DASHBOARD: '/:product_url/dashboard',
    PRODUCT_CHECKOUT: '/:product_url/checkout',
    PRODUCT_ORDER: '/:product_url/order',
    AIR_AMBULANCE_DETAILS: '/air-ambulance',
    SUPER_TOP_UP_PREMIUM: '/super-topup/premium',
    PRODUCT_ORDER_STATUS: '/:product_url/order/:order_status',
};


export default ROUTES;
