import React from "react";

const STRINGS = {
	common: {
		title: "Unlock Your Insurance Insights",
		title_text_2: "with insurancepadosi !",
		// sub_text_2: 'in under a minute.',
		// sub_text_3:<>Behind the scenes, we analyse over <b className="text-black">2400 variables</b> to tailor a personalized report just for you. </>,
		point_1: "Ideal Coverage & Tailored Duration",
		point_2: "Health, Retirement & Child's Future Planning",
		point_3: "Personalized Recommendations from 50+ Years of Insurance Wisdom",
		point_4: "Streamlined Insurance: Simplicity, Integrity, Unbiased Guidance",
		as_per_pan: "(As written in PAN card)",
		form_title: "Let’s work together",
		form_sub_title:
			"",
		spam_warning: "Don't worry! We won't Spam you with unwanted calls",
		spam_warning_2: "No Spam Calling & No Ads",
		certicates: "Certified by: ISO & India Insurtech Association",
		name: "Your Full Name *",
		name_field_placeholder: "For Eg: Abhay Nath Singh",
		mobile: "Mobile*",
		mobile_field_placeholder: "Enter your Mobile Number",
		dropdown_header: "How Insurance Padosi uses credit data to help you find right insurance solutions?",
		dropdown_point_1: <>At <span className="text-[#066c42] font-bold">Insurance Padosi</span>, credit data is crucial for determining the right insurance coverage. In India, credit bureaus maintain over 2,400 variables for each individual, which are invaluable for profiling users, understanding their aspirations, and predicting future needs. For example, the type of loans a user has or their credit card usage can provide insights into their desired lifestyle post-retirement or the aspirations they have for their children. This data can also be significant in underwriting processes.</>,
		dropdown_point_2: <>In the US market, credit data is even used to determine mortality in insurance. Munich Re has published a paper on this topic, which can be accessed <a href="https://www.munichre.com/content/dam/munichre/marc/pdf/stratifying-mortality-risk/truerisk/Transunion_TrueRisk_IA_Final_6.15.17.pdf/_jcr_content/renditions/original./Transunion_TrueRisk_IA_Final_6.15.17.pdf" target="_blank" className="underline" rel="noreferrer">here</a>. Our goal is to leverage credit data to provide you with a comprehensive insurance requirement assessment in just 60 seconds. Rest assured, this process does not impact your credit score or report.</>,
		terms_conditions: (
			<>
				I agree to{" "}
				<a
					href={"https://www.insurancepadosi.in/privacy-policy"}
					target={"_blank"}
					rel="noreferrer"
					className={"text-blue-800"}
				>
					privacy policy
				</a>{" "}
				and{" "}
				<a
					href={"https://insurancepadosi.in/terms-of-use"}
					target={"_blank"}
					rel="noreferrer"
					className={"text-blue-800"}
				>
					terms & conditions
				</a>
			</>
		),
		user_consent: (
			<>
				You hereby consent to Insurance Padosi being appointed as your
				authorized representative to receive your credit information from
				Experian for the purpose of generating your personalised Insurance
				Health Report. I also agree to{" "}
				<a
					href={"https://insurancepadosi.in/experian-terms-and-conditions"}
					target={"_blank"}
					rel="noreferrer"
					className={"text-blue-800"}
				>
					Experian T&C.
				</a>
			</>
		),
		credit_score_warning:
			"*This journey does not impact your experian score or report.",
		get_otp: "Get OTP",

		title2: (
			<>
				Unlock your <span className="text-yellow-400"> Insurance </span>{" "}
				Insights
			</>
		),
		sub_title_1: "Unlock Your Ideal Coverage",
		text_1:
			"Dive deep into your financial safety net and discover the perfect sum assured for your term plan – no more guesswork, just peace of mind.",
		sub_title_2: "Custom-Fit Protection Timeline",
		text_2:
			"Get a crystal-clear view of the precise coverage span you need, ensuring you're shielded exactly when it matters most.",
		sub_title_3: "Health Insurance, Decoded",
		text_3:
			"Navigate the maze of health insurance with tailored insights, pinpointing the features that matter to you – your health plan, personalized.",
		sub_title_4: "Craft Your Financial Future",
		text_4:
			"Embark on a journey to your dream retirement with a bespoke saving strategy, tailored to light up your golden years with ease and grace.",
		quote:
			"Personalized recommendations to maximize your insurance benefits and financial security. Know your options and make informed decisions for comprehensive coverage.",
	},
	ihr: {
		title: "Unlock Your Insurance Insights",
		title_text_2: "with insurancepadosi !",
		// sub_text_2: 'in under a minute.',
		// sub_text_3:<>Behind the scenes, we analyse over <b className="text-black">2400 variables</b> to tailor a personalized report just for you. </>,
		point_1: "Ideal Coverage & Tailored Duration",
		point_2: "Health, Retirement & Child's Future Planning",
		point_3: "Personalized Recommendations from 50+ Years of Insurance Wisdom",
		point_4: "Streamlined Insurance: Simplicity, Integrity, Unbiased Guidance",
		as_per_pan: "(As written in PAN card)",
		form_title: "Let’s work together",
		form_sub_title:
			"Fill in the details to get your personalized Insurance Health Report in 60 Seconds",
		title2: (
			<>
				Unlock your <span className="text-yellow-400"> Insurance </span>{" "}
				Insights
			</>
		),
		sub_title_1: "Unlock Your Ideal Coverage",
		text_1:
			"Dive deep into your financial safety net and discover the perfect sum assured for your term plan – no more guesswork, just peace of mind.",
		sub_title_2: "Custom-Fit Protection Timeline",
		text_2:
			"Get a crystal-clear view of the precise coverage span you need, ensuring you're shielded exactly when it matters most.",
		sub_title_3: "Health Insurance, Decoded",
		text_3:
			"Navigate the maze of health insurance with tailored insights, pinpointing the features that matter to you – your health plan, personalized.",
		sub_title_4: "Craft Your Financial Future",
		text_4:
			"Embark on a journey to your dream retirement with a bespoke saving strategy, tailored to light up your golden years with ease and grace.",
		quote:
			"Personalized recommendations to maximize your insurance benefits and financial security. Know your options and make informed decisions for comprehensive coverage.",
	},
	Login: {
		title: "Unlock Your Insurance Insights",
		title_text_2: "with insurancepadosi !",
		// sub_text_2: 'in under a minute.',
		// sub_text_3:<>Behind the scenes, we analyse over <b className="text-black">2400 variables</b> to tailor a personalized report just for you. </>,
		point_1: "Ideal Coverage & Tailored Duration",
		point_2: "Health, Retirement & Child's Future Planning",
		point_3: "Personalized Recommendations from 50+ Years of Insurance Wisdom",
		point_4: "Streamlined Insurance: Simplicity, Integrity, Unbiased Guidance",
		as_per_pan: "(As written in PAN card)",
		form_title: "Let’s work together",
		form_sub_title:
			"Fill in the details to get your personalized Insurance Health Report in 60 Seconds",
		spam_warning: "Dont worry! We won't Spam you with unwanted calls",
		spam_warning_2: "No Spam Calling & No Ads",
		certicates: "Certified by: ISO & India Insurtech Association",
		name: "Your Full Name *",
		name_field_placeholder: "For Eg: Abhay Nath Singh",
		mobile: "Mobile",
		mobile_field_placeholder: "Enter your Mobile Number",
		terms_conditions: (
			<>
				I agree to{" "}
				<a
					href={"https://www.insurancepadosi.in/privacy-policy"}
					target={"_blank"}
					rel="noreferrer"
					className={"text-blue-800"}
				>
					privacy policy
				</a>{" "}
				and{" "}
				<a
					href={"https://insurancepadosi.in/terms-of-use"}
					target={"_blank"}
					rel="noreferrer"
					className={"text-blue-800"}
				>
					terms & conditions
				</a>
			</>
		),
		user_consent: (
			<>
				You hereby consent to Insurance Padosi being appointed as your
				authorized representative to receive your credit information from
				Experian for the purpose of generating your personalised Insurance
				Health Report. I also agree to{" "}
				<a
					href={"https://insurancepadosi.in/experian-terms-and-conditions"}
					target={"_blank"}
					rel="noreferrer"
					className={"text-blue-800"}
				>
					Experian T&C.
				</a>
			</>
		),
		credit_score_warning:
			"*This journey does not impact your experian score or report.",
		get_otp: "Get OTP",

		title2: (
			<>
				Unlock your <span className="text-yellow-400"> Insurance </span>{" "}
				Insights
			</>
		),
		sub_title_1: "Unlock Your Ideal Coverage",
		text_1:
			"Dive deep into your financial safety net and discover the perfect sum assured for your term plan – no more guesswork, just peace of mind.",
		sub_title_2: "Custom-Fit Protection Timeline",
		text_2:
			"Get a crystal-clear view of the precise coverage span you need, ensuring you're shielded exactly when it matters most.",
		sub_title_3: "Health Insurance, Decoded",
		text_3:
			"Navigate the maze of health insurance with tailored insights, pinpointing the features that matter to you – your health plan, personalized.",
		sub_title_4: "Craft Your Financial Future",
		text_4:
			"Embark on a journey to your dream retirement with a bespoke saving strategy, tailored to light up your golden years with ease and grace.",
		quote:
			"Personalized recommendations to maximize your insurance benefits and financial security. Know your options and make informed decisions for comprehensive coverage.",
	},

	'air-ambulance': {
		title: " Air Ambulance Cover",
		title_text_2: "Get Air Ambulance at only INR 199",
		form_title: "Enroll for Air Ambulance Cover now!",
		form_sub_title: "Fill in the details to enroll for ambulance cover for you and your family.",
		point_1: "Get Air Ambulance cover at just INR 199 for upto INR 5 lakh",
		point_2: "Covers Multiple Options",
		point_3:
			"Safe and Comfortable",
		point_4:
			"Meticulous Set-up",
		spam_warning: "Don't worry! We won't Spam you with unwanted calls",
		spam_warning_2: "No Spam Calling & No Ads",
		certicates: "Certified by: ISO & India Insurtech Association",
		quote: "Regular health insurance covers ambulances, but air ambulances, often needed for serious emergencies, can leave you with a hefty billons to maximize your insurance benefits and financial security. Know your options and make informed decisions for comprehensive coverage.",
	},
	'sports-insurance': {
		title: "Sports Insurance Cover",
		title_text_2: "Get your Sports Cover starting at INR 79 per Annum",
		form_title: "Enroll for Sports Cover now!",
		form_sub_title: "Fill in the details to enroll for sports cover for you and your family.",
	},
	'psychological-counseling': {
		title: "Psychology Cover",
		title_text_2: "Unlock unlimited psychology consultation with Insurance Padosi!",
		form_title: "Enroll for Psychology Cover now!",
		form_sub_title: "Fill in the details to enroll for psychology cover for you and your family.",
	},
	'super-topup': {
		title: "Super Top-Up",
		title_text_2: "Add extra protection to your Health Insurance",
		form_title: "Enroll for Super Top-up now!",
		form_sub_title: "Fill in the details to get extra coverage for you and your family.",
	},

	airambulancedetails: {
		title: "Air Ambulance Cover",
		description: "Regular health insurance covers ambulances, but air ambulances, often needed for serious emergencies, can leave you with a hefty bill",
		offer: "Get Air Ambulance at only INR 199",
		buttonText: "ENROLL NOW",
		claim: "How to File a claim under Air  Ambulance Cover?",
		steps: [
			{
				step: "STEP 1",
				title: "Claim Intimation",
				description: "Fill the claim form here or Call CARE service at 24*7helpline number- 1800-102-4488/ 1800-102-6655"
			},
			{
				step: "STEP 2",
				title: "Upload Documents",
				description: "Upload all documents in seconds"
			},
			{
				step: "STEP 3",
				title: "Claim Tracking",
				description: "Track your Claim Status / Know your claim status"
			}
		],
		proTip: "Pro Tip",
		details: "In India, 53 road accidents occur daily. Moreover, with worsening traffic conditions in urban cities, the need for Air Ambulance becomes all the more relevant to ensure timely transportation and reduce loss of life.",
		airAmbulanceCover: {
			title: "What is Air Ambulance Cover?",
			details: [
				"It covers costs of air ambulance services during medical emergencies",
				"It includes transportation by flight, helicopter, boat, or train",
				"It provides crucial protection for a nominal fee of ₹199"
			]
		},
		insurancePad: {
			title: "Why choose Insurance",
			details: [
				"Unbiased, Customer-First",
				"Tailor made solution only for you",
				"Seamless Experience",
				"No SPAM & No Ad"
			]
		},
		carousel: [
			{
				icon: "c1.png",
				title: "Added Protection",
				details: "Get Air Ambulance cover at just INR 199 for upto INR 5 lakh"
			},
			{
				icon: "c2.png",
				title: "Covers Multiple Options",
				details: "The plan covers a plane, helicopter, train or boat"
			},
			{
				icon: "c3.png",
				title: "Safe and Comfortable",
				details: "Air ambulance services provide comfortable, hassle-free transport, minimizing the risks of long road trips."
			},
			{
				icon: "c4.png",
				title: "Meticulous Set-up",
				details: "Specialists manage air ambulance services, ensuring tailored schedules and optimal patient care during transit."
			}
		],
		coverHeader: "What is Covered under Air Ambulance Cover?",
		cover: {
			included: [
				{
					text: "Air Ambulance cover upto INR 5,00,000",
					description: "Get up to INR 5,00,000 in air ambulance coverage for peace of mind during emergencies.",
					icon: "tick.png"
				},
				{
					text: "Unlimited psychological consulting for one month",
					description: "Facing challenges at work or home? Don't worry. Get unlimited psychological counseling sessions",
					icon: "tick.png"
				},
				{
					text: "Accidental Death Cover of INR 100,000",
					description: "Get INR 100,000 Accidental Death Cover for peace of mind and financial protection.",
					icon: "tick.png"
				},
				{
					text: "Claim helpline 24*7",
					description: "You can reach to 1800-102-4488/1800-102-6655 for claim intimation",
					icon: "tick.png"
				}
			],
			excluded: [
				{
					text: "Organ Transportation",
					description: "Organ transportation services are not included in the plan.",
					icon: "cross.png"
				},
				{
					text: "Road Ambulance",
					description: "This plan excludes road ambulance coverage; costs for such services won't be reimbursed.",
					icon: "cross.png"
				},
				{
					text: "International Coverage",
					description: "This plan doesn't cover international medical expenses.",
					icon: "cross.png"
				},
				{
					text: "Any other family members other than insured members",
					description: "The coverage applies solely to insured members and does not extend to any other family members.",
					icon: "cross.png"
				}
			]
		}

	},
	Otp: {
		title: "Need to confirm you are real!",
		otp_verification: "OTP Verification",
		enter_otp: "Enter OTP verification number",
		dont_receive_otp: "Did not receive OTP? ",
		resend: "Resend",
		btn_text: "Verify & Proceed",
		whatsapp_text: "Did not recieve sms? ",
		whatsapp_link: "Try Whatsapp",
		whatsapp_link_sufix: "on same number",
	},
	Pan: {
		title: "Sorry! We could not find you on Credit bureau. Pls share your PAN",
		enter_pan_number: "Enter your PAN Number",
		ten_digits: "10 Digits",
		btn_text: "Verify & Proceed",
	},
	Mask: {
		title: "Confirm your mobile",
		sub_title: "Please fill in the mobile number shown below.",
		placeholder: "Enter your 10 digit mobile number",
		btn_text: "Confirm your number",
	},
	Questions: {
		title: "We want to learn more about you",
		description:
			"With a short questionnaire we've developed. Just answer 4 simple questions and get your personal risk profile instantly.\n",
		question_0: {
			id: "5",
			title: "Please choose the individuals that are part of your immediate family",
			meta_title: "You can select multiple options",
			text: "Your dependents are crucial for insurance and financial planning",
			options: ["Spouse", "Parents", "Children", "None"],
		},
		question_1: {
			id: "6",
			title: "How many children do you have?",
			text: "This guides tailored recommendations based on your family structure",
			// meta_title: "Select one",
			placeholder: "Enter age",
			options: [
				{
					id: "7",
					value: "1",
					label: "1 child",
					sub_questions: [
						{
							title: "What is the age of your child?",
							state_key: "singleChildAge",
						},
					],
				},
				{
					id: "8",
					value: "2",
					label: "2 children",
					sub_questions: [
						{
							title: "What is the age of your first child?",
							state_key: "firstChildAge",
						},
						{
							title: "What is the age of your second child?",
							state_key: "secondChildAge",
						},
					],
				},
				{
					id: "9",
					value: "3+",
					label: "3 or more children",
					sub_questions: [
						{
							title: "What is the age of your eldest child?",
							state_key: "eldestChildAge",
						},
						{
							title: "What is the age of your youngest child?",
							state_key: "youngestChildAge",
						},
					],
				},
			],
		},
		question_2: {
			id: "11",
			title: "At what age are you planning to retire?",
			meta_title: "Enter Retirement Age",
			placeholder: "Enter your Retirement age",
			text: "Age impacts risk assessment & insurance need in Insurance health report",
			message: "85% of users have expressed desire to retire at 60",
			options: [],
		},
		question_3: {
			id: "20",
			title: "What is your current annual income?",
			meta_title: "Amount",
			text: "Your Incomes helps us understand financial liability & aspirations",
			message: "",
			label: "Chose annual income:",
			options: [
				{
					value: "",
					label: "Select Annual Income",
				},
				{
					value: ">3",
					label: "0-3 Lakh",
				},
				{
					value: "3-6",
					label: "3-6 Lakh",
				},
				{
					value: "6-10",
					label: "6-10 Lakh",
				},
				{
					value: "10-15",
					label: "10-15 Lakh",
				},
				{
					value: "15-25",
					label: "15-25 Lakh",
				},
				{
					value: "25-50",
					label: "25-50 Lakh",
				},
				{
					value: "50+",
					label: "50+ Lakh",
				},
			],
		},
		question_4: {
			id: "12",
			title: "Do you have a Health Insurance?",
			// meta_title: "Select one",
			message: "",
			options: ["Yes", "No"],
			text: "Your response will help advise you on whether to consider porting your health insurance policy",
			sub_questions: [
				{
					id: "17",
					title: "Is your health insurance provided by your employer?",
					options: ["Yes", "No"]
				},
				{
					id: "18",
					title: "Your health insurance is purchased by:",
					options: ["You", "Your Employer", "Both"]
				}
			]
		},
		question_5: {
			id: "13",
			title: "We want to learn more about you",
			text: "Your response will help advise you on whether to consider porting your health insurance policy",
			plan_questions: [
				{
					id: "14",
					title: "Personal Health Insurer Name*",
				},
				{
					id: "15",
					title: "What's your Cumulative Sum Insured?*",
					placeholder: "Enter Total Sum Insured",
				},
				{
					id: "16",
					title: "What's your Personal Health Insurance plan's name?",
					placeholder: "Select plan",
				},
			],
			sub_questions: [
				{
					id: "14",
					title: "Insurer Name*",
					label: "Choose Provider",
					// state_key: 'insurerName',
					options: [
						{
							value: " ",
							label: "Select Insurer Provider",
						},
						{
							value: "provider-1",
							label: "Provider 1",
						},
						{
							value: "provider-2",
							label: "Provider 2",
						},
					],
				},
				{
					id: "15",
					title: "What's your sum insured?*",
					placeholder: "Enter amount",
					// state_key: 'sumInsured',
					options: [],
				},
				{
					id: "16",
					title: "What's your insurance plan's name?",
					placeholder: "Select plan",
					// state_key: 'planName'
					options: [
						{
							value: " ",
							label: "Select Plan",
						},
						{
							value: "Plan-a",
							label: "Plan A",
						},
						{
							value: "Plan-b",
							label: "Plan B",
						},
					],
				},
			],
		},
		next_question: "Continue  →",
		submit: "Generate IHR",
		regenerate_ihr: "Regenerate IHR"
	},
	PanQuestions: {
		title: "We want to learn more about you?",
		description:
			"With a short questionnaire we've developed. Just answer 7 simple questions and get your personal risk profile instantly.\n",
		question_0: {
			id: "2",
			title: "When were you born?",
			// meta_title: "Enter Your Age",
			text: "Age impacts risk assessment & insurance need in Insurance health report",
			placeholder: "Enter age",
			message:
				"Your personal details are secure with us. We use it to make your personalized Insurance Health Report",
			options: [],
			sub_questions: [
				{
					id: "21",
					title: "Date of birth",
				},
				{
					id: "22",
					title: "Gender",
					label: "gender",
					options: [
						{
							value: "",
							label: "Select Gender",
						},
						{
							value: "male",
							label: "Male",
						},
						{
							value: "female",
							label: "Female",
						},
					]
				}
			]
		},
		question_1: {
			id: "3",
			title: "What is your current annual income?",
			meta_title: "Amount",
			text: "Your Income helps us understand financial liability & aspirations",
			message:
				"Your personal details are secure with us. We use it to make your personalized Insurance Health Report",
			label: "Chose annual income:",
			options: [
				{
					value: "",
					label: "Select Annual Income",
				},
				{
					value: ">3",
					label: "0-3 Lakh",
				},
				{
					value: "3-6",
					label: "3-6 Lakh",
				},
				{
					value: "6-10",
					label: "6-10 Lakh",
				},
				{
					value: "10-15",
					label: "10-15 Lakh",
				},
				{
					value: "15-25",
					label: "15-25 Lakh",
				},
				{
					value: "25-50",
					label: "25-50 Lakh",
				},
				{
					value: "50+",
					label: "50+ Lakh",
				},
			],
		},
		question_2: {
			id: "4",
			title: "Where do you live?",
			// meta_title: "Enter Pincode",
			text: "Pincode helps us to understand quality of your life",
			message:
				"Your personal details are secure with us. We use it to make your personalized Insurance Health Report",
			placeholder: "Enter Pin Code",
			options: [],
			sub_questions: [
				{
					id: "19",
					title: "Address",
					placeholder: "Enter your full address"
				},
				{
					id: "20",
					title: "Enter pin code",
					placeholder: "Enter pincode"
				}
			]
		},
		question_3: {
			id: "5",
			title: "Please choose the individuals that are part of your immediate family",
			meta_title: "You can select multiple options",
			text: "Your dependents are crucial for insurance and financial planning",
			options: ["Spouse", "Parents", "Children", "None"],
		},
		question_4: {
			id: "6",
			title: "How many children do you have?",
			text: "This guides tailored recommendations based on your family structure",
			// meta_title: "Select one",
			placeholder: "Enter age",
			options: [
				{
					id: "7",
					value: "1",
					label: "1 child",
					sub_questions: [
						{
							title: "What is the age of your child?",
							state_key: "singleChildAge",
						},
					],
				},
				{
					id: "8",
					value: "2",
					label: "2 children",
					sub_questions: [
						{
							title: "What is the age of your first child?",
							state_key: "firstChildAge",
						},
						{
							title: "What is the age of your second child?",
							state_key: "secondChildAge",
						},
					],
				},
				{
					id: "9",
					value: "3+",
					label: "3 or more children",
					sub_questions: [
						{
							title: "What is the age of your eldest child?",
							state_key: "eldestChildAge",
						},
						{
							title: "What is the age of your youngest child?",
							state_key: "youngestChildAge",
						},
					],
				},
			],
		},
		question_5: {
			id: "11",
			title: "At what age are you planning to retire?",
			meta_title: "Enter Retirement Age",
			placeholder: "Enter your Retirement age",
			text: "Planning for retirement is crucial for financial security in the future",
			message: "85% of users have expressed desire to retire at 60",
			options: [],
		},
		question_6: {
			id: "1",
			title: "What is your email id?",
			meta_title: "",
			placeholder: "Enter Email Id",
			text: "To provide updates, policy documents, and important communication regarding their insurance coverage and related services",
			message:
				"Your personal details are secure with us. We use it to verify your identity.",
			options: [],
		},
		question_7: {
			id: "12",
			title: "Do you have a Health Insurance?",
			// meta_title: "Select one",
			options: ["Yes", "No"],
			text: "Your response will help advise you on whether to consider porting your health insurance policy",
			message: "",
			sub_questions: [
				{
					id: "17",
					title: "Is your health insurance provided by your employer?",
					options: ["Yes", "No"]
				},
				{
					id: "18",
					title: "Your health insurance is purchased by:",
					options: ["You", "Your Employer", "Both"]
				}
			]
		},
		question_8: {
			id: "13",
			title: "We want to learn more about you",
			text: "Your response will help advise you on whether to consider porting your health insurance policy",
			plan_questions: [
				{
					id: "14",
					title: "Personal Health Insurer Name*",
				},
				{
					id: "15",
					title: "What's your Cumulative Sum Insured?*",
					placeholder: "Enter Total Sum Insured",
				},
				{
					id: "16",
					title: "What's your Personal Health Insurance plan's name?",
					placeholder: "Select plan",
				},
			],
			sub_questions: [
				{
					id: "14",
					title: "Insurer Name*",
					label: "Choose Provider",
					// state_key: 'insurerName',
					options: [
						{
							value: " ",
							label: "Select Insurer Provider",
						},
						{
							value: "provider-1",
							label: "Provider 1",
						},
						{
							value: "provider-2",
							label: "Provider 2",
						},
					],
				},
				{
					id: "15",
					title: "What's your sum insured?*",
					placeholder: "Enter amount",
					// state_key: 'sumInsured',
					options: [],
				},
				{
					id: "16",
					title: "What's your insurance plan's name?",
					placeholder: "Select plan",
					// state_key: 'planName'
					options: [
						{
							value: " ",
							label: "Select Plan",
						},
						{
							value: "Plan-a",
							label: "Plan A",
						},
						{
							value: "Plan-b",
							label: "Plan B",
						},
					],
				},
			],
		},

		next_question: "Continue  →",
		submit: "Generate IHR",
		regenerate_ihr: "Regenerate IHR"
	},
	// Success: {
	//     title: 'Protection against your risks',
	//     description: 'Select insurance policies that match you best according to your risk profile findings. No more guessing games.'
	// },
	Success: {
		title: "Your Dashboard",
		description:
			"Thank you for providing the information. You can access the report by clicking the link provided below. We will also send the report to you via email and WhatsApp.",
	},
	Dashboard: {
		pageTitle: "Dashboard",
		title: "Your tailored <br/> Insurance Health Report",
		description:
			"This is summary of your Insurance Health Report. You may download your detailed IHR from link below.",
		//You can access your Insurance Health report via the link below, and we\'ll also send it to you by email and WhatsApp',
		download_kicker_text:
			"For Reasons of Recomendation & more information on Estate Planning, Child & Ailment related plan",
		summaryTitle: "Access your own IHR for ₹99",
		summarySuccessTitle: "Download your own IHR Now",
		conversTitle: "Recommended Insurance Covers For You",
		gpaytext: (
			<>
				Have a Discount Code?
				<br /> Enter Here to Get Your Report for{" "}
				<b className="font-bold">FREE</b>
			</>
		),
		discounttext: "Do you have a Coupon Code?",
		sampleheading: "Dummy Insurance Health Report",
		summary: "Summary of Your Insurance Health Report",
	},
	Footer: {
		about_us: "About us",
		copyright: "Copyright @ Insurance Padosi. 2023",
		product_of: "Product of Karnodakshayi Pvt Ltd ",
		email: "Email us: customercare@insurancepadosi.in",
		follow_us: "Follow us:",
		follow_us_links: [
			{
				id: 1,
				label: "Blogs",
				href: "https://insurancepadosi.in/blog",
			},
			{
				id: 2,
				label: "FAQs",
				href: "https://insurancepadosi.in/faq",
			},
		],
		links: [
			{
				id: 1,
				label: "Refund Policy",
				href: "https://insurancepadosi.in/refund-policy",
			},
			{
				id: 2,
				label: "Privacy Policy",
				href: "https://www.insurancepadosi.in/privacy-policy",
			},
			// {
			//     id: 3,
			//     label: 'Disclaimers',
			//     href: 'https://www.insurancepadosi.in/experian-terms-and-conditions'
			// }
		],
		built_by: {
			label: "Built by",
			company_name: "PixelVJ",
			href: "https://pixelvj.com/",
		},
	},
	ErrorMessages: {
		eldestYoungestChildError:
			"The youngest child must be younger than the eldest child.",
		firstSecondChildError:
			"The second child must be younger than the first child.",
		invalidChildAgeError: "Please enter child age below 45 years only",
		MaxRetirementAgeError: "The retirement age cannot be more than 80",
		MinRetirementAgeError: "The retirement age cannot be lesser than 40",
		CurrentRetirementAgeError:
			"The retirement age should be at least 5 years more than the current age.",
	},
};

export default STRINGS;
