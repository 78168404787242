import React, { useEffect, useState } from 'react';
import STRINGS from "../../../../utils/constants/strings";
import loginImg from "../../../../assets/images/login.svg";
import { isPhoneNumberValid } from "../../../../utils/validations";
import { useSelector } from "react-redux";
import { useMaskedMobilePostMutation } from "../../../../store/services/maskedMobile";
import Spinner from "../../../Common/Loaders/Spinner";
import ROUTES from "../../../../utils/constants/Routes";
import { useNavigate } from "react-router-dom";

const MaskNumber = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const recheckPan = useSelector((state) => state?.recheckPan?.recheckPanData);

    // Calculate whether the button should be disabled or not
    const isButtonDisabled = isPhoneNumberValid(mobileNumber);
    const _addBtnDisableClass = isButtonDisabled ? 'disabled:opacity-25' : '';

    // Use the useMaskedMobilePostMutation hook
    const [maskedMobilePost, { data, error, isLoading }] = useMaskedMobilePostMutation();
    const recheckPanData = useSelector((state) => state?.recheckPan?.recheckPanData);

    const handleMaskNumber = () => {
        maskedMobilePost({
            // "userid" : localStorage.getItem('userid'),
            "stgOneHitId": String(recheckPanData?.stgOneHitId),
            "stgTwoHitId": String(recheckPanData?.stgTwoHitId),
            "maskMobileno": mobileNumber,
        });
    };
    const navigate = useNavigate();

    useEffect(() => {
        try {
            if (data?.isburea !== undefined && data?.isburea) {
                // Redirect to the QUESTIONS screen when isPan is false
                navigate(ROUTES.IHR_QUESTIONS);
            } else if (data?.isburea !== undefined && !data?.isburea) {
                navigate(ROUTES.IHR_PAN_QUESTIONS);
            }
        } catch (error) {
            console.log(error);
        }

    }, [data]);

    return (
        <>
            {(isLoading) && <Spinner />}
            <section className="flex justify-between">
                <div className="w-full lg:w-6/12">
                    <h2 className="font-bold text-black text-4xl lg:text-5xl">
                        {STRINGS.Mask.title}
                    </h2>
                    <div className="lg:pl-10 pt-7">
                        <h2 className="text-md">{STRINGS.Mask.sub_title}</h2>
                        <p className="text-md my-4">{recheckPan?.maskMobileno || '98XXXXX876'}</p>
                        <input
                            type={"number"}
                            placeholder={STRINGS.Mask.placeholder}
                            value={mobileNumber}
                            onChange={(event) => setMobileNumber(event.target.value)}
                            className={'border p-4 w-full lg:w-80 rounded-2xl placeholder:text-sm'}
                        />
                        <button
                            type="submit"
                            className={`bg-[#F4CD2A] block rounded py-3 lg:px-20 md:px-20 mt-8 lg:mt-10 w-full md:w-auto lg:w-auto ${_addBtnDisableClass}`}
                            disabled={isButtonDisabled}
                            onClick={handleMaskNumber}
                        >
                            {STRINGS.Mask.btn_text}
                        </button>
                    </div>
                </div>

                <div className="hidden lg:block">
                    <img src={loginImg} alt="Login" />
                </div>
            </section>
        </>
    );
};

export default MaskNumber;