import React from "react";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import somethingWentWrongImg from "../../assets/images/SWEERROR.json";
import notFoundImg from "../../assets/images/404Error.json";
import Lottie from "lottie-react";
import ROUTES from "../../utils/constants/Routes";

const NotFound = ({ product_url }) => {
  const navigate = useNavigate();
  const refreshPage = () => {
    window.location.reload(); // This reloads the page
  };

  const redirectToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  let error = useRouteError();

  return error && error.status !== 404 ? (
    <div className="flex flex-col items-center justify-center h-screen">
      <div>
        <div>
          <Lottie
            width={400}
            height={100}
            animationData={somethingWentWrongImg}
            loop={true}
          />
        </div>
        <button
          className={
            "border-0 font-bold px-7 py-5 w-full rounded-lg block text-white text-xl bg-[#207058]"
          }
          onClick={redirectToLogin}
        >
          Go To Home Page
        </button>
      </div>
    </div>
  ) : (

    <div className="flex flex-col items-center justify-center h-screen">
      <div>
        <div>
          <Lottie animationData={notFoundImg} loop={true} />
        </div>
        <button
          className={
            "border-0 font-bold px-7 py-5 w-full rounded-lg block text-white text-xl bg-[#207058]"
          }
          onClick={redirectToLogin}
        >
          Go To Home Page
        </button>
      </div>
    </div>
  );
};

export default NotFound;
