import React from "react";
import productsConfig from "./productsConfig";

const PricingOptions = ({ product_url, pricing, selectedPricing, handlePricingSelect }) => {
  // Find the plan with the maximum price
  const maxPrice = Math.max(...pricing.map(plan => parseFloat(plan.price)));
  const getPlanName = (price) => (parseFloat(price) === maxPrice ? "Premium Plan" : "Standard Plan");

  // Get benefits based on product url
  const productBenefits = productsConfig[product_url]?.benefits || [];

  return (
    <div className="w-full">
      <div className="bg-white shadow-md rounded-lg p-4 py-8 sm:p-8 border-t">
        <h2 className="text-lg font-bold mb-2">Cover Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {pricing.map((price, index) => (
            <button
              key={index}
              type="button"
              onClick={() => handlePricingSelect(price)}
              className={`border shadow-md rounded-lg p-4 text-left ${selectedPricing?.id === price.id ? 'bg-green-100 border-green-500' : 'border-gray-300'}`}
            >
              <div className="font-bold text-sm text-gray-400 md:text-md">
                {getPlanName(price.price)}
              </div>
              <div className="text-lg font-semibold sm:text-2xl">INR {parseInt(price.price, 10)} per annum</div>
              <ul className="list-disc pl-5 mt-2 text-xs font-bold">
                {productBenefits.map((benefit, i) => (
                  <li key={i}>{benefit.replace("{sum_insured}", parseInt(price.sum_insured, 10))}</li>
                ))}
              </ul>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingOptions;
