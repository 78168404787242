export const isNameValid = (name) => {
    const validName = /^[A-Za-z\s]*$/.test(name);

    if (name=== '') return 'Name cannot be empty';
    if(!validName) return 'Name is not valid';
    return null;
};

export const isPhoneNumberValid = (number) => {
    const validNumber = /^(0|91)?[6-9][0-9]{9}$/.test(number);

    if(number === '' ) return 'Phone Number cannot be empty';
    if(!validNumber) return 'Phone Number is not valid';
};

export const isFiveDigitNumberValid = (number) => {
    const validNumber = /^\d{5}$/.test(number);

    if(number === '' ) return 'Number cannot be empty';
    if(!validNumber) return 'Number is not valid';
};

export const isEmailValid = (email) => {
    const validEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(email);

    if(!email) return 'Email Id is required';
    if(!validEmail) return 'Email Id is not valid';
    return null;
};

export const isPinCodeValid = (pinCode) => {
    const validPinCode = /^[1-9][0-9]{5}$/.test(pinCode);

    if(!pinCode) return 'Pin Code is required';
    if(!validPinCode) return 'Pin Code is not valid';
    return null;
};

export const isPanNumberValid = (panNumber) => {
    const validPanNumber = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(panNumber);

    if(!panNumber) return 'Pan Number is required';
    if(!validPanNumber) return 'Pan Number is not valid';
    return null;
};

export const capitalizeFirstLetter = (string) => {
    if(string[0]){
        return string.replace(/^./, string[0].toUpperCase());
    }
    return '';
}

export const getFirstLetter = (str) => {
    // Check if the input is a non-empty string
    if (typeof str === 'string' && str.length > 0) {
        // Use charAt(0) to get the first character
        return str.charAt(0);
    } else {
        // Return null or any default value for invalid input
        return null;
    }
};

export const isMobile = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the device is a mobile device
    const isMobileDevice = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);

    // Check if it's not an iPad or tablet
    const isNotTablet = !(/ipad/i.test(userAgent) || /tablet/i.test(userAgent));

    // Return true only if it's a mobile device and not an iPad or tablet
    return isMobileDevice && isNotTablet;
};