// LottiePopup.js
import React, { useEffect } from "react";
import Lottie from "lottie-react";

const LottiePopup = ({ animationData, closeDelay }) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			document.getElementById("popup-container").style.display = "none";
		}, closeDelay);

		return () => clearTimeout(timer);
	}, [closeDelay]);

	return (
		<div
			id="popup-container"
			className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
		>
			<Lottie
				style={{ width: "500px", height: "500px" }}
				animationData={animationData}
			/>
		</div>
	);
};

export default LottiePopup;
