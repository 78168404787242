import {createSlice} from "@reduxjs/toolkit";

const maskedMobileSlice = createSlice({
    name: "maskedMobile",
    initialState: {},
    reducers: {
        setMaskedMobileData: (state, action) => {
            state.maskedMobileData = action.payload;
        },
    },
});

export const { setMaskedMobileData } = maskedMobileSlice.actions;

export default maskedMobileSlice.reducer;


