import { createSlice, current } from "@reduxjs/toolkit";

const productSlice = createSlice({
    name: "products",
    initialState: {
        products: null,
        userProducts: null,
        currentProduct: null,
    },
    reducers: {
        setAppProducts: (state, action) => {
            if (action.payload.products) {
                state.products = action.payload.products;
            }
        },
        setUserProducts: (state, action) => {
            state.userProducts = action.payload;
        },
        setCurrentProduct: (state, action) => {
            state.currentProduct = action.payload;
        },
        removeCurrentProduct: (state, action) => {
            state.currentProduct = null;
        }
    },
});

export const { setAppProducts, setUserProducts, setCurrentProduct, removeCurrentProduct } = productSlice.actions;
export default productSlice.reducer;


