import { useState } from "react";
import { getBaseUrl } from "../../utils/config";

export const useApi = (method, url) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const makeCall = async (data) => {
        setIsLoading(true);
        try {
            const response = await fetch(getBaseUrl+url, {
                method: method, // *GET, POST, PUT, DELETE, etc.
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `${window?.localStorage.getItem('userid')}`
                },                
                body: JSON.stringify(data), // body data type must match "Content-Type" header
              });
              if (!response.ok) {
                // Handle non-successful responses (status code other than 2xx)
                let text = await response.json();
                throw new Error(text.error);
            }

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                const responseData = await response.json();
                setData(responseData);
            } else {
                const responseData = await response.text();
                // Handle other content types if needed
                setData(responseData);
            }
        } catch (error) {
            setError(error.message);
        }

        setIsLoading(false);
    };

    return { data, isLoading, error, makeCall };
};
