import React from 'react'
import EnrollButton from './EnrollButton';

function RecommendationCard({ title, imageUrl, recommendationText, redirectUrl }) {
    return (
        <section className="flex flex-col rounded-none px-2">
            <div className="flex flex-col py-5 px-10 w-full md:bg-sky-50 rounded-xl border border-solid border-zinc-500 border-opacity-50 max-md:px-5 max-md:max-w-full">
                <header className="self-end text-xs md:text-base py-1 md:mb-5 font-bold text-center text-white rounded-xl px-5 bg-gradient-to-r from-[#D095E5] to-[#0400CA]">
                    Recommended For You
                </header>
                <div className="flex flex-row justify-center items-center flex-wrap gap-1 md:gap-9">
                    <figure className="flex flex-col items-center justify-center rounded-2xl">
                        <img loading="lazy" src={imageUrl} alt="" className="object-contain aspect-[1.29] md:h-[90px] h-[50px]" />
                    </figure>
                    <div className="flex flex-col justify-between grow shrink-0 self-start mt-1 basis-0 w-fit max-md:max-w-full">
                        <p className="mb-2 md:text-3xl font-semibold text-blue-950 max-md:mr-2.5 max-md:max-w-full">
                            {title}
                        </p>
                        <p className=" mr-12 text-xs md:text-xl text-blue-950 md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                            {recommendationText}
                        </p>
                    </div>
                </div>
                <div className='flex justify-end mt-2'>
                    <EnrollButton redirectUrl={redirectUrl} />
                </div>
            </div>
        </section>
    );
}

export default RecommendationCard;
