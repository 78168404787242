import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {getBaseUrl} from '../../utils/config';

export const dashboardEmailUpdateApi = createApi({
    reducerPath: 'dashboardEmailUpdateSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl,
        prepareHeaders: (headers) => {
            // Add Basic Authentication header
            headers.set('Authorization', `${window?.localStorage.getItem('userid')}`);
            return headers;
        },
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        dashboardEmailUpdatePost: builder.mutation({
            query: (payload) => ({
                url: '/user/update/commemail',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const { useDashboardEmailUpdatePostMutation } = dashboardEmailUpdateApi;