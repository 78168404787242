import React from "react";
import Check from "../../../../assets/images/port-icons/check-circle.svg";
import { formatCash } from "../../../../utils/common";

const TermInsuranceCard = ({ value, retirementYears, canShow }) => {
	const insuranceFeatures = (text) => (
		<div className="flex w-full items-center">
			<img
				loading="lazy"
				src={Check}
				alt="Feature icon"
				className="w-8 aspect-square"
			/>
			<p
				className={`ml-2 text-sm text-[#207058] ${!canShow ? "blur-sm" : ""
					}`}
			>
				{text}
			</p>
		</div>
	);
	return (
		<div className="pl-1.5 rounded-2xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-11/12 md:w-10/12 mt-10 bg-green-300">
			<div className="bg-white rounded-2xl p-5">
				<div className="flex flex-col pl-0 md:pl-5 w-full   max-md:max-w-full">
					<header className="self-start ml-2 text-xl text-green-700 max-md:max-w-full">
						<h1 className="text-2xl font-bold leading-[62px] text-green-700">
							Term Insurance
						</h1>
						<p className="text-zinc-500">
							Secure Your Financial Future with Comprehensive
							Coverage
						</p>
					</header>
					<hr className="shrink-0 mt-6 border-solid bg-teal-800 bg-opacity-80 border-[1px] border-teal-800 border-opacity-80 h-[1px] max-md:max-w-full" />
					<div className="flex text-xl gap-5 self-center px-7 py-4 mt-2 w-full text-1xl text-left md:text-center max-w-[930px] text-indigo-950 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
						<div className="flex-auto">
							<span className="text-indigo-950">Cover : </span>
							<span
								className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
									}`}
							>
								{formatCash(value)}
							</span>
						</div>
						<div className="flex-auto">
							<span className="text-indigo-950">Tenure : </span>
							<span
								className={`text-indigo-950 font-bold ${!canShow ? "blur-lg" : ""
									}`}
							>
								{retirementYears} Year
							</span>
						</div>
					</div>
					<hr className="shrink-0 border-solid bg-teal-800 bg-opacity-80 border-[1px] border-teal-800 border-opacity-80 h-[1px] max-md:max-w-full" />
				</div>
				<div className="flex flex-col pl-5 mt-2 w-full max-md:max-w-full">
					<h2 className="self-start mt-5 ml-2 text-xl font-semibold leading-10 text-indigo-950 max-md:mt-10 max-md:max-w-full">
					Factors to consider before buying term plan –
					</h2>
					<div className="self-center mt-2.5 w-full  max-md:max-w-full">
						<div className="flex flex-col items-center w-10/12 mx-10 my-5 max-md:ml-0 max-md:w-full">
							<div className="w-full ">
								<div className="grid grid-cols-1 md:grid-cols-2 gap-5">
									{insuranceFeatures("Claim settlement ratio >95%")}
									{insuranceFeatures("Sum Assured must cover your liability")}
									{insuranceFeatures("Consider aspirations of your dependent in long run")}
									{insuranceFeatures("Insurer’s reputation ")}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermInsuranceCard;
