import React from 'react';
import FooterFollowUs from './FooterFollowUs';
import Logo from '../../../assets/images/ip-logo.svg';

const MobileFooter = () => {
    return (
        <section className="bg-emerald-700 p-6 py-10 mt-4 md:hidden">
            <div className="flex items-center flex-col mb-4">
                <div className="flex flex-row items-center">
                    <img src={Logo} className="h-12 w-12 md:h-20 md:w-20" alt="Logo" />
                    <p className="text-white font-bold text-xl">insurance<span className="text-amber-400">padosi</span></p>
                </div>
                <p className="text-white text-sm mt-2">Product of Karnodakshayi Insurance Pvt Ltd</p>
                <p className="text-white text-sm mt-2">Email us: customersupport@insurancepadosi.in</p>
            </div>
            <FooterFollowUs />
            <a href="https://www.insurancepadosi.in/blog/" className="text-white underline block mt-2">Blogs</a>
            <a href="https://www.insurancepadosi.in/faq" className="text-white mt-2 underline">FAQs</a>
            <a href="https://www.insurancepadosi.in/about-us.html" className="text-white underline block mx-auto">About Us</a>
            <div className="text-center">
                <hr className="border-gray-500 border-b-2 mt-2" />
                <p className="text-white mt-2" style={{ fontSize: '10px' }}>Karnodakshayi Insurance Private Limited is recognized as a Finance Technology startup by DPIIT | Certificate No. DIPP143332</p>
                <p className="text-white mt-2">Copyright @ insurancepadosi. 2023</p>
                <div className="flex items-center justify-center">
                    <a href="https://www.insurancepadosi.in/refund-policy.html" className="text-white mr-4">Refund Policy</a>
                    <hr className="border-r border-r-2 border-gray-500 h-4 my-1" />
                    <a href="https://www.insurancepadosi.in/privacy-policy.html" className="text-white ml-4">Privacy Policy</a>
                </div>
            </div>
        </section>
    );
};

export default MobileFooter;
