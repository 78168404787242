import React from "react";
import successIcon from "../../assets/images/order-success.svg";
import { Link } from "../../../node_modules/react-router-dom/dist/index";
import ROUTES from "../../utils/constants/Routes";
import { addStarsOnEmail } from "../../utils/common";


const OrderSuccess = ({ sessionData, productData }) => {
    return (
        <div className="text-center py-8 ">
            <div className="bg-green-100 rounded-full w-24 h-24 mx-auto flex items-center justify-center">
                <img src={successIcon} alt="" />
            </div>
            <h1 className="text-2xl font-bold mt-4">Congratulations!</h1>
            <p className="text-[#191919] text-lg">
                Your payment for the {productData?.title} went through successfully.
            </p>
            <p className="text-[#191919] text-lg">
                {/* We will be sending your policy details on k***@g****.com */}
                We will be sending your policy details on{" "}
                {sessionData?.email
                    ? addStarsOnEmail(sessionData?.email)
                    : "your email."}
            </p>
            <div className=" p-4 pt-6 rounded-lg mt-14 inline-block text-xs leading-5 text-left" style={{
                background: 'linear-gradient( rgba(255, 191, 43, 1) 35%, rgba(254, 241, 211, 1) 100%)'
            }}>
                <div className="relative -mt-11 flex flex-row justify-center mb-4  px-0 pb-1 mx-8 clip-path-arrow">
                    <div className="bg-green-700 text-white py-2 px-8 font-bold relative z-10 clip-path-arrow">
                        A MEMBERSHIP LIKE NO OTHER
                    </div>
                </div>
                <p>
                    <strong>Get benefits of Insurance Health Report for free!</strong><br />
                    Discover your holistic insurance needs in just 60 seconds<br />
                    <a href={ROUTES.IHR_LOGIN} className="text-blue-600 underline">Explore benefits &gt;</a>
                </p>
            </div>
            <p className="mt-6 font-semibold underline">
                <a href={ROUTES.DASHBOARD} className="text-blue-600">
                    Go back to homepage
                </a>
            </p>
        </div>
    );
}

export default OrderSuccess;