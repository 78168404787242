import roomRentCappingIcon from "../../assets/images/port-icons/Room-rent-capping.svg";
import dayCareProcedureIcon from "../../assets/images/port-icons/Day-care-procedure.svg";
import restorationIcon from "../../assets/images/port-icons/Restoration.svg";
import prePostHospitalizationIcon from "../../assets/images/port-icons/Pre-post-hospitalisation.svg";
import sumInsuredIcon from "../../assets/images/port-icons/sum-insured.svg";

export const ROOM_RENT_CAPPING_KEY = "room_rent_capping";
export const PRE_POST_HOSPITALIZATION_KEY = "pre_post_hospitalization";
export const RESTORATION_KEY = "restoration";
export const DAY_CARE_KEY = "day_care";
export const SUM_INSURED_KEY = "sum_insured";
export const DAY_CARE_PROCEDURE_KEY = "day_care";
export const CO_PAY_KEY = "co_pay";
export const FAMILY_FLOATER_KEY = "family_floater";
export const CRITICAL_ILLNESS_KEY = "critical_illness";

export const HEALTH_INSURANCE_COMPARE_KEYS = {
	[SUM_INSURED_KEY]: {
		title: "Sum Insured",
		description: "Coverage limit in health insurance policy",
		matchingInfo:
			"Congratulations, you are among 2% of users who have an ideal sum insured. Your ideal sum insured is ",
		notMatchingInfo: "Sum insured is the maximum amount an insurer will pay for covered medical expenses in a policy year.",
		icon: sumInsuredIcon,
	},
	[ROOM_RENT_CAPPING_KEY]: {
		title: "Room Rent Capping",
		description: "Limit on room rent coverage in insurance",
		matchingInfo:
			"Congratulations , you are among 9% of users who have  ideal room rent capping.",
		notMatchingInfo:
			"Room Rent Capping can CRIPPLE your hospital room options, BURDEN you with skyrocketing out-of-pocket costs, and even SQUEEZE coverage for other crucial medical expenses",
		icon: roomRentCappingIcon,
	},
	[DAY_CARE_KEY]: {
		title: "Day Care Procedure",
		description: "Medical procedure without overnight stay",
		matchingInfo:
			"Congratulations, you are among 18% of users who have an ideal  day care procedure  benefit in their policy.",
		notMatchingInfo:
			"Have Daycare procedures in your policy & you can skip unnecessary hospital stays",
		icon: dayCareProcedureIcon,
	},
	[RESTORATION_KEY]: {
		title: "Restoration",
		description: "Policy feature reinstating coverage after claim",
		matchingInfo:
			"Congratulations, you are among 27% of users who have ideal restoration benefits in their policy.",
		notMatchingInfo:
			"No restoration? Then brace for crushing costs & financial meltdown, Restoration safeguards you from maxed-out coverage after a claim",
		icon: restorationIcon,
	},
	[PRE_POST_HOSPITALIZATION_KEY]: {
		title: "Pre/post hospitalization",
		description: "Coverage before and after hospitalization",
		matchingInfo:
			"Congratulations, you are among 11% of users who have ideal  pre- post hospitalisation  benefits in their policy.",
		notMatchingInfo:
			"Pre & Post hospitalization shields you from cost of doctor visits, meds & lab tests before & after your hospital stay which normally ranges from INR 10k to 1 lakh",
		icon: prePostHospitalizationIcon,
	},
	[FAMILY_FLOATER_KEY]: {
		title: "Family Floater",
		description: "Family Floater",
		matchingInfo: "Matching",
		notMatchingInfo: "Not Matching",
		icon: prePostHospitalizationIcon,
	},
};
