import "./styles.css";
import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAuthRedirect from "../hooks/useAuthRedirect";
import AirAmbulanceDetailsBG from "../assets/images/air-ambulance-details.png";
import ISOImage from "../assets/images/iso.svg";
import StartupIndiaImage from "../assets/images/si.svg";
import InsureTechImage from "../assets/images/insuretech.svg";
import IsoMobile from "../assets/images/isoMobile.svg";
import InsureTechMobile from "../assets/images/insuretechMobile.svg";
import StartupMobile from "../assets/images/startupIndiaMobile.svg";
import TruckImage from "../assets/images/truck.png";
import PRODUCT_DETAILS from "../utils/constants/productDetails";
import StepIconBase from "../assets/images/step-icon-base.png";
import StepIconGreen from "../assets/images/step-icon-green.png";
import WebCreator from "../assets/images/web_creater.svg";
import ArrowDown from "../assets/images/arrow-down.png";
import bulbImage from '../assets/images/bulb.png';

const DetailsPage = () => {

  const { product_url } = useParams();
  const loginHref = `/${product_url}/login`;
  let { search, pathname } = useLocation();
  let params = new URLSearchParams(search);
  const sessionData = useSelector((state) => state.session.sessionData);
  const userProducts = useSelector((state) => state.products.userProducts);
  const currentProduct = useSelector((state) => state.products.currentProduct);
  const products = useSelector((state) => state.products.products);
  useAuthRedirect(
    sessionData,
    pathname,
    products,
    product_url,
    userProducts,
    currentProduct,
    params
  );

  const [toggleIncluded, setToggleIncluded] = useState(true);

  function removeBackgroundColorFromClass(className) {
    // Select all elements with the specified className
    var elements = document.querySelectorAll(`.${className}`);

    // Iterate over the NodeList and remove the background color
    elements.forEach(function (element) {
      element.classList.remove("bg-green-50");
    });
  }
  function changeDotColor(dotNumber) {
    const dots = document.querySelectorAll(".dot");
    dots.forEach((dot, index) => {
      if (index === dotNumber - 1) {
        dot.src = StepIconGreen; // Assuming this is the green dot image
      } else {
        dot.src = StepIconBase; // Assuming this is the grey dot image
      }
      removeBackgroundColorFromClass("roller");
      let div = document.getElementById("change-" + dotNumber);
      if (div) {
        div.classList.add("bg-green-50");
      }
    });
  }

  const runSlides = (slides) => {
    let currentSlide = 0;
    const totalSlides = slides.length;
    const slideInterval = 5000; // Change slide every 5 second

    function goToSlide(slideIndex) {
      slides[currentSlide]?.classList?.remove("active");
      slides[slideIndex]?.classList?.add("active");
      currentSlide = slideIndex;
    }

    function nextSlide() {
      const nextSlideIndex = (currentSlide + 1) % totalSlides;
      goToSlide(nextSlideIndex);
    }

    function startSlideShow() {
      nextSlide(); // Immediately show the next slide without delay
      setTimeout(startSlideShow, slideInterval);
    }

    // Initialize the first slide as active
    slides[currentSlide]?.classList?.add("active");
    setTimeout(startSlideShow, 5000);
  };

  useEffect(() => {
    const topSlides = document.querySelectorAll("#tops .carousel__slide");
    const bottomSides = document.querySelectorAll("#bottom .carousel__slide");
    runSlides(topSlides);
    runSlides(bottomSides);

    let i = 1;
    setInterval(() => {
      changeDotColor(i);
      i = i + 1;
      if (i > 4) {
        i = 1;
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const bullets = document.querySelectorAll(".bullet-button img");
    const bulletInterval = 5000; // Change bullet color every 5 seconds

    let currentBulletIndex = 0;
    let intervalId;

    function resetBullets() {
      bullets.forEach((bullet) => {
        bullet.src = StepIconBase; // Reset all bullets to normal state
      });
    }

    function changeBulletColor() {
      resetBullets();
      bullets[currentBulletIndex].src = StepIconGreen; // Set current bullet to green
      currentBulletIndex = (currentBulletIndex + 1) % bullets.length; // Move to the next bullet
    }

    function startBulletInterval() {
      intervalId = setInterval(changeBulletColor, bulletInterval); // Change bullet color every 2 seconds
    }

    startBulletInterval(); // Start the bullet interval when the page loads
    changeBulletColor(); // Initially set the first bullet to green
  }, []);

  function toggleAnswer(index) {
    const answer = document.querySelectorAll(".answer")[index - 1];
    const arrow = document.querySelectorAll(".arrow")[index - 1];
    if (answer.classList.contains("hidden")) {
      answer.classList.remove("hidden");
      setTimeout(() => {
        answer.classList.add("opacity-100");
        // answer.classList.toggle("transition-all");
        answer.classList.remove("opacity-0");
      }, 0);
    } else {
      // answer.classList.toggle("transition-all");
      answer.classList.add("hidden");
      answer.classList.add("opacity-0");
      answer.classList.remove("opacity-100");
    }
    arrow.classList.toggle("rotate-180");
  }

  const details = PRODUCT_DETAILS[product_url] || {}

  return (
    <section className="bg-white flex flex-col items-center justify-center w-full ">
      <section
        className="hidden md:block pb-4 relative h-[95vh] w-full bg-[#207058] pt-14 px-8"
        style={{
          overflow: "hidden !important",
        }}
      >
        <div className="hidden md:block absolute bottom-0 left-0 w-full">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            className="fill-current text-white transform rotate-180 w-full md:w-auto mt-4 md:mt-6"
          >
            <path
              d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>

        {/* Main section */}
        <div className="flex items-center justify-around px-0 md:px-12">
          <div className="w-full md:w-3/5 mb-8 h-full text-white">
            <div className="flex flex-col items-start justify-center h-full px-4">
              <div className="w-full flex flex-col items-start gap-4 md:gap-4">
                <h3 className="text-2xl md:text-xl">
                  {details.heading}
                </h3>
                <h1 className="text-2xl md:text-4xl font-bold">
                  {details.title}
                </h1>
                <ul className="list-disc list-inside">
                  <li>{details.feature1}</li>
                  <li>
                    {details.feature2}
                  </li>
                </ul>
                <div className="w-full flex flex-col items-center justify-center md:items-start">
                  <a
                    // href="/air-ambulance/login"
                    href={loginHref}
                    className="bg-[#F4CD2A] text-blue-950 py-1 md:py-2 px-8 md:px-16 rounded inline-block mb-2 md:mb-4 text-lg md:text-xl font-bold"
                  >
                    {details.buttontext}
                  </a>
                  <p className="text-sm md:text-base">
                    {details.subtext}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center gap-4">
                  <img className="" src={ISOImage} alt="ISO" />
                  <img className="" src={InsureTechImage} alt="InsureTech" />
                  <img className="" src={StartupIndiaImage} alt="StartupIndia" />

                </div>

                <div className="flex flex-row items-center justify-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                    />
                  </svg>

                  <p className="text-sm md:text-base">
                    Your Information is 100% secured with us. <a href="https://insurancepadosi.in/privacy-policy.html" className="underline">Click here</a> to know
                    more.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="hidden w-2/5 md:flex justify-end">
            <img src={details.webimage} class="w-886 h-987" />
          </div>
        </div>
      </section>

      <section className="block md:hidden text-[#020064] py-6">
        <div className="flex flex-col items-center justify-center gap-1 px-4 w-full py-2">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-4">
            {details.mobileheading}
          </h1>
          <p className="text-center font-domine">
            {details.mobiletitle}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-2 w-full px-2 py-4">
          <h1 className="text-2xl font-semibold text-center opacity-80 font-domine">
            {details.mobiledesc}
          </h1>
          <div className="flex flex-col items-center">
            <div className="flex justify-center items-center gap-2">
              <img className="w-12 h-12" src={IsoMobile} alt="Iso" />
              <img className="w-16 h-16" src={InsureTechMobile} alt="InsureTech" />
              <img className="w-20 h-24" src={StartupMobile} alt="StartupIndia" />
            </div>
          </div>
          <div className="flex justify-center">
            <p className="text-center italic text-black font-bold text-xs">
              In partnership with Care Health Insurance
            </p>
          </div>

          <a
            // href="/air-ambulance/login"
            href={loginHref}
          >

            <button
              className="bg-[#F4CD2A] text-black shadow-lg py-2 px-12 md:px-16 rounded-md inline-block mb-2 md:mb-4 text-xl font-bold whitespace-nowrap"
              style={{
                position: "fixed",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 50,
              }}
            >
              {details.buttontext}
            </button>
          </a>
        </div>
        <img
          src={details.mobileimage}
          alt="Air Ambulance Details"
          className="w-full h-[200px] object-cover"
        />
      </section>

      {/* Do you know section */}
      <section className="flex flex-col items-center justify-center gap-4 md:gap-6 w-full px-4 hidden lg:block">
        <div>
          <h1 className="text-[#020064] text-2xl md:text-4xl font-bold text-center">
            {details.doyouknowheading}
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 w-full px-4 md:px-8 py-4 md:py-8">
          {details.facts.map((fact) => (
            <div className="flex flex-col items-center justify-center gap-4 text-center border border-[#E5E5E5] rounded-lg p-4 md:p-6 h-full">
              <img className="w-16" src={fact.icon} alt={fact.description} />
              <p className="text-sm md:text-base max-w-[400px]">
                {fact.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Pro Tip*/}
      <section>
        <div className="mt-6 mx-6 p-4 bg-[#E6F6FF] rounded-lg max-w-lg text-poppins md:hidden">
          <h2 className="text-[#030057] text-md font-bold mb-3 text-poppins text-sm">{details.proTip}</h2>
          <div className="flex items-start bg-white p-2 rounded-lg shadow-lg">
            <img
              src={bulbImage} alt="Pro Tip"
            />
            <p className="text-[#207058] text-xs font-bold">{details.proTipdesc}</p>
          </div>
        </div>
      </section>

      {/* Unlock your health potentials */}
      <section className="bg-white mt-12 pt-8 p-6 py-2 lg:py-0 lg:p-2 hidden md:block">
        <div className="flex flex-col items-start justify-start lg:pl-32">
          <p className="text-2xl lg:text-3xl font-bold text-[#020064]">
            {details.unlockheading}
          </p>
          <p className="mt-4 text-xl lg:text-2xl text-amber-400 font-bold">
            {details.unlocksubheading}
          </p>
        </div>
        <br />
        <br />

        <div className="flex flex-col lg:flex-row justify-between items-center font-poppins">
          <div className="w-full lg:w-1/12 lg:pl-12">
            <img
              id="dot1"
              img
              src={StepIconGreen}
              alt="Dot Image"
              className="mb-4 dot hidden lg:block"
              width="16"
              height="16"
            />
            <img
              id="dot2"
              src={StepIconBase}
              alt="Dot Image"
              className="mb-4 dot hidden lg:block"
              width="16"
              height="16"
            />
            <img
              id="dot3"
              src={StepIconBase}
              alt="Dot Image"
              className="mb-4 dot hidden lg:block"
              width="16"
              height="16"
            />
            <img
              id="dot4"
              src={StepIconBase}
              alt="Dot Image"
              className="dot hidden lg:block"
              width="16"
              height="16"
            />
          </div>

          <div className="w-full lg:w-10/12 max-w-full lg:pl-8 lg:pr-10">
            <div
              id="change-1"
              className="roller hover:bg-green-50 mb-6 lg:mb-2"
              onmouseover="changeDotColor(1)"
            >
              <div className="flex flex-col lg:flex-row items-center">
                <span className="font-bold text-2xl lg:text-2xl text-teal-800 mr-2 mb-2 lg:mb-0">
                  01
                </span>
                <h2 className="font-bold text-gray-700 text-xl lg:text-xl text-center lg:text-left">
                  {details.point1header}
                </h2>
              </div>
              <p className="text-black text-sm lg:ml-10 lg:mr-16 text-center lg:text-left">
                {details.point1desc}
              </p>
            </div>

            <div
              id="change-2"
              className="roller hover:bg-green-50 mb-6 lg:mb-2"
              onmouseover="changeDotColor(2)"
            >
              <div className="flex flex-col lg:flex-row items-center">
                <span className="font-bold text-2xl text-teal-800 mr-2 mb-2 lg:mb-0">
                  02
                </span>
                <h2 className="font-bold text-gray-700 text-xl text-center lg:text-left">
                  {details.point2header}
                </h2>
              </div>
              <p className="text-black text-sm lg:ml-10 lg:mr-16 text-center lg:text-left">
                {details.point2desc}
              </p>
            </div>

            <div
              id="change-3"
              className="roller hover:bg-green-50 mb-6 lg:mb-2"
              onmouseover="changeDotColor(3)"
            >
              <div className="flex flex-col lg:flex-row items-center">
                <span className="font-bold text-2xl text-teal-800 mr-2 mb-2 lg:mb-0">
                  03
                </span>
                <h2 className="font-bold text-gray-700 text-xl text-center lg:text-left">
                  {details.point3header}
                </h2>
              </div>
              <p className="text-black text-sm lg:ml-10 lg:mr-16 text-center lg:text-left">
                {details.point3desc}
              </p>
            </div>

            <div
              id="change-4"
              className="roller hover:bg-green-50 mb-6 lg:mb-2"
              onmouseover="changeDotColor(4)"
            >
              <div className="flex flex-col lg:flex-row items-center">
                <span className="font-bold text-2xl text-teal-800 mr-2 mb-2 lg:mb-0">
                  04
                </span>
                <h2 className="font-bold text-gray-700 text-xl text-center lg:text-left">
                  {details.point4header}
                </h2>
              </div>
              <p className="text-black text-sm lg:ml-10 lg:mr-16 text-center lg:text-left">
                {details.point4desc}
              </p>
            </div>
          </div>

          <div className="w-full lg:w-2/3 flex justify-center lg:justify-end">
            <img
              src={details.unlockimage}
              alt="Login"
              className="hidden lg:block px-16"
            />
          </div>
        </div>
        <br />
        {/* <a href="/air-ambulance/login">
          <div className="bg-emerald-200 text-green-600 py-4 px-12 rounded text-center block mb-8 text-xl font-bold mx-auto lg:mx-0 lg:ml-28 lg:mr-auto lg:max-w-full lg:inline-block hidden md:block">
            GET STARTED
          </div>
        </a> */}
      </section>

      <section className="bg-white mt-10 pt-2 p-2 lg:py-0 lg:p-2 md:hidden">
        <div className="text-left lg:text-left lg:pl-12 mb-4">
          <p className="text-xl lg:text-3xl ml-2 font-bold text-blue-950 lg:pl-40 text-center">
            {details.unlockheading}
          </p>
          <p className="mt-4 text-sm lg:text-2xl ml-2 text-amber-400 font-semibold lg:pl-56 text-center">
            {details.unlocksubheading}
          </p>
        </div>

        <div className="lg:w-1/3 flex justify-center items-center lg:justify-end w-full">
          <img className="w-48 h-48" src={details.unlockimage} alt="Login" />
        </div>
      </section>
      <section
        id="tops"
        className="carousel text-white md:hidden w-full"
        aria-label="Gallery"
      >
        <ol className="carousel__viewport w-full">
          <li id="carousel__slide1" tabindex="0" className="carousel__slide">
            <div className="carousel__snapper justify-center items-center">
              <div className="mx-auto text-white px-4 lg:px-12 justify-center items-center">
                <div
                  className="rounded-lg border border-black border-opacity-50 p-4 mb-6 lg:mb-0"
                  id="slide-1"
                >
                  <div className="flex flex-row items-start justify-center mb-2">
                    <h2 className="font-bold text-gray-700 text-xl lg:text-xl text-center">
                      {details.point1header}
                    </h2>
                  </div>
                  <p className="text-black text-sm lg:ml-10 lg:mr-16 text-left ">
                    {details.point1desc}
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li id="carousel__slide2" tabindex="0" className="carousel__slide">
            <div className="carousel__snapper justify-center items-center">
              <div className="mx-auto justify-between items-start text-white px-4 lg:px-12">
                <div
                  className="rounded-lg border border-black border-opacity-50 mb-6 lg:mb-0 p-4"
                  id="slide-2"
                >
                  <div className="flex flex-row items-start justify-center mb-2">
                    <h2 className="font-bold text-gray-700 text-xl text-center lg:text-left">
                      {details.point2header}
                    </h2>
                  </div>
                  <p className="text-black text-sm lg:ml-10 lg:mr-16 text-left">
                    {details.point2desc}
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li id="carousel__slide3" tabindex="0" className="carousel__slide">
            <div className="carousel__snapper justify-center items-center">
              <div className="mx-auto justify-between items-start text-white px-4 lg:px-12">
                <div
                  className="rounded-lg border border-black border-opacity-50 mb-6 p-4 lg:mb-0"
                  id="slide-3"
                >
                  <div className="flex flex-row items-start justify-center mb-2">
                    <h2 className="font-bold text-gray-700 text-xl text-center ">
                      {details.point3header}
                    </h2>
                  </div>
                  <p className="text-black text-sm lg:ml-10 lg:mr-16 text-left lg:text-left">
                    {details.point3desc}
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li id="carousel__slide4" tabindex="0" className="carousel__slide">
            <div className="carousel__snapper justify-center items-center">
              <div className="mx-auto justify-between items-start text-white px-4 lg:px-12">
                <div
                  className="rounded-lg border border-black border-opacity-50 mb-6 p-4 lg:mb-0"
                  id="slide-4"
                >
                  <div className="flex flex-row items-start justify-center mb-2">
                    <h2 className="font-bold text-gray-700 text-xl text-center lg:text-left">
                      {details.point4header}
                    </h2>
                  </div>
                  <p className="text-black text-sm lg:ml-10 lg:mr-16 text-left">
                    {details.point4desc}
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </section>
      <div className="w-full flex flex-row justify-center items-center gap-2">
        <button
          id="bullet1"
          className="mb-4 bullet-button md:hidden"
          aria-label="Bullet Button 1"
        >
          <img src={StepIconBase} alt="Bullet Image" width="16" height="16" />
        </button>
        <button
          id="bullet2"
          className="mb-4 bullet-button md:hidden"
          aria-label="Bullet Button 2"
        >
          <img src={StepIconBase} alt="Bullet Image" width="16" height="16" />
        </button>
        <button
          id="bullet3"
          className="mb-4 bullet-button md:hidden"
          aria-label="Bullet Button 3"
        >
          <img src={StepIconBase} alt="Bullet Image" width="16" height="16" />
        </button>
        <button
          id="bullet4"
          className="mb-4 bullet-button md:hidden"
          aria-label="Bullet Button 4"
        >
          <img src={StepIconBase} alt="Bullet Image" width="16" height="16" />
        </button>
      </div>

      {/* Why is it Required */}
      <section
        className="w-full mt-8 py-6 p-6 lg:p-2 hidden md:block"
        style={{
          backgroundColor: "rgba(246, 255, 251, 1)",
        }}
      >
        <div>
          <p className="text-xl md:text-3xl font-bold text-center mt-4">
            {details.requiredheading}
          </p>
          <p className="mt-4 text-sm md:text-xl text-center">
            {/* We're not your typical insurance platform. What sets us apart is our{" "}
            <br />
            unwavering commitment to — */}
            {details.requiredsubheading}
          </p>
        </div>
        <br />
        <br />

        <div className="w-full mx-auto lg:mt-8 px-4 lg:px-20">
          <div className="w-full flex flex-col md:flex-row justify-center items-center mb-0 lg:mb-8">
            <div className="bg-transparent border border-black rounded-lg shadow-md p-4 mx-4 lg:mx-8 mb-4 lg:mb-0 lg:w-96" style={{ height: '230px' }}>
              <div className="flex flex-col items-center">
                <img
                  src={details.box1image}
                  alt="Image"
                  className="w-16 h-16 mb-2 lg:w-24 lg:h-24"
                />
                <p className="text-center font-bold text-lg lg:text-xl">
                  {details.box1header}
                </p>
                <p className="text-center text-xs lg:text-sm mt-2">
                  {details.box1desc}
                </p>
              </div>
            </div>

            <div className="bg-transparent border border-black rounded-lg shadow-md p-4 mx-4 lg:mx-8 mb-4 lg:mb-0 lg:w-96" style={{ height: '230px' }} >
              <div className="flex flex-col items-center">
                <img
                  src={details.box2image}
                  alt="Image"
                  className="w-16 h-16 mb-2 lg:w-24 lg:h-24"
                />
                <p className="text-center font-bold text-lg lg:text-xl">
                  {details.box2header}
                </p>
                <p className="text-center text-xs lg:text-sm mt-2">
                  {details.box2desc}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="bg-transparent border border-black rounded-lg shadow-md p-4 mx-4 lg:mx-8 mb-4 lg:mb-0 lg:w-96" style={{ height: '230px' }}>
              <div className="flex flex-col items-center">
                <img
                  src={details.box3image}
                  alt="Image"
                  className="w-16 h-16 mb-2 lg:w-24 lg:h-24"
                />
                <p className="text-center font-bold text-lg lg:text-xl">
                  {details.box3header}
                </p>
                <p className="text-center text-xs lg:text-sm mt-2">
                  {details.box3desc}
                </p>
              </div>
            </div>

            <div className="bg-transparent border border-black rounded-lg shadow-md p-4 mx-4 lg:mx-8 mb-4 lg:mb-0 lg:w-96" style={{ height: '230px' }}>
              <div className="flex flex-col items-center">
                <img
                  src={details.box4image}
                  alt="Image"
                  className="w-16 h-16 mb-2 lg:w-24 lg:h-24"
                />
                <p className="text-center font-bold text-lg lg:text-xl">
                  {details.box4header}
                </p>
                <p className="text-center text-xs lg:text-sm mt-2">
                  {details.box4desc}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-content text-center mt-16 hidden sm:block">
          <a
            // href="/air-ambulance/login"
            href={loginHref}
          >
            <div className="bg-emerald-200 text-green-600 py-2 px-16 rounded inline-block mb-8 text-xl font-bold">
              {details.buttontext}
            </div>
          </a>
        </div>
      </section>

      <section
        className="mt-4 py-6 lg:p-2 md:hidden"
        style={{
          backgroundColor: "rgba(246, 255, 251, 1)",
        }}
      >
        <div>
          <p className="text-2xl md:text-3xl font-bold text-black text-center mt-4">
            {details.requiredheading}
          </p>
        </div>
        <br />

        <div className="container mx-auto px-3 lg:mt-8 lg:px-20">
          <div className="flex flex-col md:flex-row items-start justify-start mb-4 gap-4">
            <div className="bg-transparent rounded-lg flex items-center justify-center">
              <img
                src={details.box1image}
                alt="Image"
                className="h-12 w-12 mb-2 lg:w-24 lg:h-24"
              />
              <div className="pl-4">
                <p className="text-left font-bold text-sm lg:text-xl">
                  {details.box1header}
                </p>
                <p className="text-left text-xs lg:text-sm mt-2">
                  {details.box1desc}
                </p>
              </div>
            </div>

            <div className="bg-transparent rounded-lg flex items-center justify-center">
              <img
                src={details.box2image}
                alt="Image"
                className="h-12 w-12 mb-2 lg:w-24 lg:h-24"
              />
              <div className="pl-4">
                <p className="text-left font-bold text-sm lg:text-xl">
                  {details.box2header}
                </p>
                <p className="text-left text-xs lg:text-sm mt-2">
                  {details.box2desc}
                </p>
              </div>
            </div>
            <div className="bg-transparent rounded-lg flex items-center justify-center">
              <img
                src={details.box3image}
                alt="Image"
                className="h-12 w-12 mb-2 lg:w-24 lg:h-24"
              />
              <div className="pl-4">
                <p className="text-left font-bold text-sm lg:text-xl">
                  {details.box3header}
                </p>
                <p className="text-left text-xs lg:text-sm mt-2">
                  {details.box3desc}
                </p>
              </div>
            </div>
            <div className="bg-transparent rounded-lg flex items-center justify-center">
              <img
                src={details.box4image}
                alt="Image"
                className="h-12 w-12 mb-2 lg:w-24 lg:h-24"
              />
              <div className="pl-4">
                <p className="text-left font-bold text-sm lg:text-xl">
                  {details.box4header}
                </p>
                <p className="text-left text-xs lg:text-sm mt-2">
                  {details.box4desc}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* What is covered included and excluded */}
      <section className="px-6 md:px-12 py-4 lg:py-16 w-full">
        <div>
          <p className="text-xl lg:text-3xl font-bold text-left md:text-center text">
            {details.coveredheading}
          </p>
          <p className="mt-4 text-sm md:text-xl text-center">
            {details.coveredsubheading}
          </p>
        </div>
        <div className="flex flex-col justify-center items-center gap-4 md:gap-6 py-4">
          <div className="bg-[#7B7D7E33] px-4 py-2 md:py-2.5 rounded-md">
            <button
              className={`${toggleIncluded ? "bg-white text-[#066C42]" : "text-[#ADADAD]"} px-4 py-2 rounded-md text-base md:text-xl font-bold`}
              onClick={() => setToggleIncluded(true)}
            >
              Included
            </button>
            <button
              className={`${!toggleIncluded ? "bg-white text-[#DA0B0B]" : "text-[#ADADAD]"} px-4 py-2 rounded-md text-base md:text-xl font-bold`}
              onClick={() => setToggleIncluded(false)}
            >
              Excluded
            </button>
          </div>
          {toggleIncluded ? (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 items-center justify-center mt-4 md:mt-6">
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img className="w-14 h-14" src={details.include1image} alt="money-icon" />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">{details.include1header}</p>
                  <p>
                    {details.include1desc}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img className="w-14 h-14" src={details.include2image} alt="money-icon" />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">{details.include2header}</p>
                  <p>
                    {details.include2desc}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img
                  className="w-14 h-14"
                  src={details.include3image}
                  alt="money-icon"
                />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">{details.include3header}</p>
                  <p>
                    {details.include3desc}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img
                  className="w-14 h-14"
                  src={details.include4image}
                  alt="money-icon"
                />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">{details.include4header}</p>
                  <p>
                    {details.include4desc}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 items-start justify-start mt-4 md:mt-6">
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img
                  className="w-10 h-14"
                  src={details.exclude1image}
                  alt="money-icon"
                />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">{details.exclude1header}</p>
                  <p>{details.exclude1desc}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img
                  className="w-6 h-14"
                  src={details.exclude2image}
                  alt="money-icon"
                />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">{details.exclude2header}</p>
                  <p>
                    {details.exclude2desc}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img
                  className="w-14 h-14"
                  src={details.exclude3image}
                  alt="money-icon"
                />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">{details.exclude3header}</p>
                  <p>{details.exclude3desc}</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-4 h-full">
                <img
                  className="w-15 h-14"
                  src={details.exclude4image}
                  alt="money-icon"
                />
                {/* grey line */}
                <div className="w-full h-0.5 bg-[#7B7D7E33] opacity-80"></div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-bold pb-5">
                    {details.exclude4header}
                  </p>
                  <p>
                    {details.exclude4desc}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* claim */}
      <section
        className="mt-12 py-4 lg:py-16 p-6 w-full"
        // style="background-color: rgba(246, 255, 251, 1)"
        style={{
          backgroundColor: "rgba(246, 255, 251, 1)",
        }}
      >
        <div>
          <p className="text-xl lg:text-3xl font-bold text-left md:text-center">
            {details.claimheader}
          </p>
        </div>

        <div className="container mx-auto flex flex-col lg:flex-row justify-between lg:px-0 mt-8 font-poppins">
          <div className="lg:w-3/5 max-w-md lg:pl-28 whitespace-normal lg:whitespace-nowrap">
            <div className="text-content mr-16 mb-2">
              <p className="mb-2 bg-emerald-200 text-green-600 py-1 px-6 rounded inline-block text-sm font-bold">
                Step 1
              </p>
              <p className="mb-2 font-bold text-xl text-black">
                Claim Intimation
              </p>
              <p className="mb-2 text-black text-sm">
                Fill the claim form{" "}
                <a
                  href="https://www.careinsurance.com/rhicl/claim/login"
                  class="font-bold"
                >
                  here
                </a>{" "}
                or Call CARE service at{" "}
                <span className="md:hidden">
                  24*7 helpline number- 1800-102-4488/ 1800-102-6655
                  or connect at WhatsApp 8860402452
                </span>
              </p>
              <p className="hidden md:inline-block mb-2 text-black text-sm">
                24*7 helpline number- 1800-102-4488/ 1800-102-6655 or connect at WhatsApp 8860402452
              </p>
            </div>
            <br />

            <div className="text-content mr-16 mb-2">
              <p className="mb-2 bg-emerald-200 text-green-600 py-1 px-6 rounded inline-block text-sm font-bold">
                Step 2
              </p>
              <p className="mb-2 font-bold text-xl text-black">
                Upload Documents
              </p>
              <p className="mb-2 text-black text-sm">
                Upload all documents in seconds
              </p>
            </div>
            <br />

            <div className="text-content mr-16 mb-2">
              <p className="mb-2 bg-emerald-200 text-green-600 py-1 px-6 rounded inline-block text-sm font-bold">
                Step 3
              </p>
              <p className="mb-2 font-bold text-xl text-black">
                Claim Tracking
              </p>
              <p className="mb-2 text-black text-sm">
                Track your Claim Status / Know your claim status
              </p>
            </div>
            <br />

            {/* <div className="text-content mr-16 md:mr-32 mb-2">
              <div className="bg-emerald-200 text-green-600 py-2 px-12 rounded inline-block mb-8 text-sm md:text-xl font-bold hidden md:block">
                <a href="/air-ambulance/login">TRY NOW</a>
              </div>
            </div> */}
          </div>

          <div className="lg:w-2/5 lg:flex lg:justify-end lg:mr-16 hidden lg:block">
            <img src={WebCreator} className="w-full lg:w-886 h-auto" />
          </div>
        </div>
      </section>

      {/* FAQs */}
      <section className="w-full bg-white py-8 lg:py-10 mt-4">
        <div className="flex flex-col lg:flex-row justify-center items-center md:items-start lg:px-28 gap-16">
          <div className="w-3/5 md:w-2/5">
            <h2 className="font-bold text-black text-xl md:text-3xl">
              Have Questions?
            </h2>
            <h2 className="font-bold text-black text-xl md:text-3xl">
              We have answers.
            </h2>
          </div>
          <div className="md:w-3/5 w-4/5">
            <div className="question flex flex-col justify-between mb-4">
              <div className="flex items-center justify-between w-full">
                <h3
                  className="mr-4 font-bold text-black mt-2 cursor-pointer"
                  onClick={() => toggleAnswer(1)}
                >
                  {details.faqs[0].ques1}
                </h3>
                <img
                  className="arrow cursor-pointer w-3 ml-auto"
                  src={ArrowDown}
                  alt="Arrow"
                  onClick={() => toggleAnswer(1)}
                />
              </div>
              <p className="answer hidden opacity-0 mt-2 transition-opacity ease-in-out delay-150 duration-300">
                {details.faqs[0].ans1}
              </p>
            </div>
            <hr />
            <div className="question flex flex-col justify-between mb-4">
              <div className="flex items-center justify-between w-full">
                <h3
                  className="mr-4 font-bold text-black mt-2 cursor-pointer"
                  onClick={() => toggleAnswer(2)}
                >
                  {details.faqs[0].ques2}
                </h3>
                <img
                  className="arrow cursor-pointer w-3 ml-auto"
                  src={ArrowDown}
                  alt="Arrow"
                  onClick={() => toggleAnswer(2)}
                />
              </div>
              <p className="answer hidden opacity-0 mt-2 transition-opacity ease-in-out delay-150 duration-300">
                {/* {details.faqs[0].ans2} */}
                {details.faqs[0].ans2.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
            <hr />
            <div className="question flex flex-col justify-between mb-4">
              <div className="flex items-center justify-between w-full">
                <h3
                  className="mr-4 font-bold text-black mt-2 cursor-pointer"
                  onClick={() => toggleAnswer(3)}
                >
                  {details.faqs[0].ques3}
                </h3>
                <img
                  className="arrow cursor-pointer w-3 ml-auto"
                  src={ArrowDown}
                  alt="Arrow"
                  onClick={() => toggleAnswer(3)}
                />
              </div>
              <p className="answer hidden opacity-0 mt-2 transition-opacity ease-in-out delay-150 duration-300">
                {details.faqs[0].ans3}
              </p>
            </div>
            <hr />
            <div className="question flex flex-col justify-between mb-4">
              <div className="flex items-center justify-between w-full">
                <h3
                  className="mr-4 font-bold text-black mt-2 cursor-pointer"
                  onClick={() => toggleAnswer(4)}
                >
                  {details.faqs[0].ques4}
                </h3>
                <img
                  className="arrow cursor-pointer w-3 ml-auto"
                  src={ArrowDown}
                  alt="Arrow"
                  onClick={() => toggleAnswer(4)}
                />
              </div>
              <p className="answer hidden opacity-0 mt-2 transition-opacity ease-in-out delay-150 duration-300">
                {details.faqs[0].ans4.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
            <hr />
            <div className="question flex flex-col justify-between mb-4">
              <div className="flex items-center justify-between w-full">
                <h3
                  className="mr-4 font-bold text-black mt-2 cursor-pointer"
                  onClick={() => toggleAnswer(5)}
                >
                  {details.faqs[0].ques5}
                </h3>
                <img
                  className="arrow cursor-pointer w-3 ml-auto"
                  src={ArrowDown}
                  alt="Arrow"
                  onClick={() => toggleAnswer(5)}
                />
              </div>
              <p className="answer hidden opacity-0 mt-2 transition-opacity ease-in-out delay-150 duration-300">
                {details.faqs[0].ans5.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
            <hr />
          </div>
        </div>
      </section>









    </section>
  );
};


export default DetailsPage;