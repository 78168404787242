// UserForm.jsx
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import REGEX from "../../../../utils/constants/regex";
import {
  validateAgeForAdults, validateAddress, validateName
} from "../../../../utils/helpers/questions";
import { getPincodeDetails } from "../../../../utils/helpers/products";
import { useApi } from "../../../../store/services/useApi";

const UserForm = ({ name, productDetails, productConfig, onDOBChange }) => {
  const {
    register,
    formState: { errors },
    trigger,
    watch,
    setError,
    clearErrors,
  } = useFormContext();
  const watchedPincode = watch('pincode');
  const watchedDOB = watch('dob'); 

  const {
    data: pincodeDetails,
    error: pincodeError,
    isLoading: pincodeLoading,
    makeCall: handlePincode,
  } = useApi("GET", "/get-pincode-details?pincode=" + watchedPincode);

  useEffect(() => {
    if (watchedPincode?.length === 6) {
      handlePincode()
    }
  }, [watchedPincode]);

  useEffect(() => {
    if (pincodeDetails === null) {
      setError("pincode", { type: "validate", message: "Invalid Pincode" });
    } else {
      clearErrors("pincode");
    }
  }, [pincodeDetails, setError, clearErrors]);

  useEffect(() => {
    if (onDOBChange) {
      onDOBChange(watchedDOB);
    }
  }, [watchedDOB, onDOBChange]);

  return (
    <div>
      <div className="pl-8 pt-8">
        <h2 className="text-xl font-bold mb-4">Hello {name}!</h2>
        <p className="mb-4 text-xs">
          Confirm the details to get enroll in {productDetails?.title}
        </p>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2 p-8 pt-0">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Your Full Name <span className="text-xs text-[#838080] opacity-80">(As written in AADHAR card)</span>
          </label>
          <input
            type="text"
            {...register("fullName",
              {
                required: "Full name is required",
                validate: (value) => validateName(value),
              })}
            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.fullName && (
            <span className="text-red-600 text-sm">
              {errors.fullName.message?.length > 0
                ? errors.fullName.message
                : "Full name is required"}
            </span>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Mobile
          </label>
          <input
            type="number"
            {...register("mobile", {
              required: "Mobile number is required",
              minLength: 10,
              maxLength: 10,
              message: "Invalid phone number",
            })}
            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.mobile && (
            <span className="text-red-600 text-sm">
              {errors.mobile.message?.length > 0
                ? errors.mobile.message
                : "Mobile number is required"}
            </span>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Date of Birth
          </label>
          <input
            type="date"
            {...register("dob", { required: "Date of birth is required", validate: (value) => validateAgeForAdults(value, productConfig) })}
            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors?.dob && (
            <span className="text-red-600 text-sm">{errors?.dob?.message}</span>
          )}
          {
            errors?.dob?.type === "validate" && <span className="text-red-600 text-sm">
            Age should be greater than 18 years and less than 65 years
          </span>
          }
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: { value: REGEX.EMAIL, message: "Invalid email" },
            })}
            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.email && (
            <span className="text-red-600 text-sm">{errors.email.message}</span>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Gender
          </label>
          <select
            {...register("gender", { required: "Gender is required" })}
            className="h-10 px-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Select</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
          </select>
          {errors.gender && (
            <span className="text-red-600 text-sm">
              {errors.gender.message}
            </span>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <textarea
            rows="3"
            {...register("address", {
              required: "Address is required",
              maxLength: {
                value: 120,
                message: "Address cannot exceed 120 characters"
              },
              validate: (value) => validateAddress(value),
              onChange: (e) => trigger("address"), // Trigger validation on change
            })}
            className=" p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.address && (
            <span className="text-red-600 text-sm">
              {errors.address.message}
            </span>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Pincode
          </label>
          <input
            type="text"
            {...register('pincode', {
              required: 'Pincode is required',
              validate: {
                pattern: (value) => /^[1-9][0-9]{5}$/.test(value) || 'Invalid Pincode',
                apiValidation: () => pincodeDetails !== null || 'Invalid Pincode'
              }
            })}
            className="h-10 p-3 border mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.pincode ? (
            <span className="text-red-600 text-sm">
              {errors.pincode.message}
            </span>
          ) : (
            pincodeDetails && pincodeDetails.city && pincodeDetails.city.length > 0 && (
              <span className="text-sm text-green-600 ml-1">
                {pincodeDetails.city}, {pincodeDetails.state}
              </span>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
