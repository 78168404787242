import { auth } from '../../firebase'; // Import your Firebase authentication module

const refreshToken = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      // Get the current user's ID token result
      const idTokenResult = await currentUser.getIdTokenResult();
      // Force token refresh by setting the forceRefresh option to true
      const refreshedToken = await idTokenResult.token.refresh(true);
      // Optionally update the user's ID token in local storage or state
      // Example: localStorage.setItem('userToken', refreshedToken);
    //   localStorage.setItem("userid", refreshedToken);
      return refreshedToken;
    } else {
      throw new Error('No user is currently signed in.');
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export  {refreshToken};
