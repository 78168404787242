import React from "react";
import successIcon from "../../assets/images/order-success.svg";
import { Link } from "../../../node_modules/react-router-dom/dist/index";
import ROUTES from "../../utils/constants/Routes";
import { addStarsOnEmail } from "../../utils/common";


const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const PaymentSuccess = ({ productDetails, sessionData, productData, productUrl }) => {
  const isSuperTopup = productUrl === "super-topup";
  const superTopupPolicyNumber = isSuperTopup ? productDetails?.policies[0]?.policy_number : null;

  return (
    <div className="text-center py-12">
      <div className="relative bg-green-100 flex flex-col items-center justify-center gap-2 py-6 md:py-8">
        <div className="rounded-full w-24 h-24 mx-auto flex items-center justify-center absolute top-[-10%] md:top-[-18%] left-[53%] sm:left-[50%] -translate-x-1/2">
          <img src={successIcon} alt="" />
        </div>
        <div className="flex flex-col items-center justify-center gap-2 py-6 px-4 md:px-8">
          <h1 className="text-2xl font-bold mt-4">
            You are already enrolled for our {productData?.title}
          </h1>
          {productDetails?.policies[0]?.policy_number ? (
            <p className="text-[#191919] text-lg">
              {/* We will be sending your policy details on k***@g****.com */}
              We have sent policy details at{" "}
              {sessionData?.email
                ? addStarsOnEmail(sessionData?.email)
                : "your email."}
            </p>
          ) : (
            <p className="text-[#191919] text-lg">
              {/* We will be sending your policy details on k***@g****.com */}
              We will be sending your policy details on{" "}
              {sessionData?.email
                ? addStarsOnEmail(sessionData?.email)
                : "your email."}
            </p>
          )}
        </div>
        {/* line */}
        <div className="w-[80%] h-[1px] bg-[#E0E0E0]"></div>

        {/* Headings for policy details */}
        <div className="flex items-center justify-center w-full max-w-4xl px-4 md:px-8 mt-6">
          <div className="grid grid-cols-4 gap-2 w-full text-[#838080] text-sm lg:text-lg">
            <div className="text-center">Policy Number</div>
            <div className="text-center">Members Enrolled</div>
            <div className="text-center">Start Date</div>
            <div className="text-center">End Date</div>
          </div>
        </div>

        {/* Loop through policies */}
        {productDetails?.policies.map((policy, index) => (
          <div
            key={index}
            className="flex items-center justify-center w-full max-w-4xl px-4 md:px-8 py-2"
          >
            <div className="grid grid-cols-4 gap-2 w-full text-[#191919] text-xs lg:text-lg">
              <div className="text-center">
                {isSuperTopup
                  ? superTopupPolicyNumber || "Pending"
                  : policy?.policy_number || "Pending"}
              </div>
              <div className="text-center">
                {policy.relationship.firstname} {policy.relationship.lastname}
              </div>
              <div className="text-center">
                {policy.starts_on ? formatDate(policy.starts_on) : "N/A"}
              </div>
              <div className="text-center">
                {policy.expires_on ? formatDate(policy.expires_on) : "N/A"}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="w-full p-4 pt-6 rounded-lg mt-14 text-xs leading-5 text-left"
        style={{
          background:
            "linear-gradient( rgba(255, 191, 43, 1) 35%, rgba(254, 241, 211, 1) 100%)",
        }}
      >
        <div className="relative -mt-11 flex flex-row justify-center mb-4  px-0 pb-1 mx-8 clip-path-arrow">
          <div className="bg-green-700 text-white py-2 px-8 font-bold relative z-10 clip-path-arrow">
            A MEMBERSHIP LIKE NO OTHER
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-2">
          <p>
            <strong>Get benefits of Insurance Health Report for free!</strong><br />
            Discover your holistic insurance needs in just 60 seconds<br />
            <a href={ROUTES.IHR_LOGIN} className="text-blue-600 underline">Explore benefits &gt;</a>
          </p>
          <Link to={ROUTES.IHR_LOGIN} className="text-blue-600 underline">
            Explore now {">"}
          </Link>
        </div>
      </div>
      <p className="mt-6 font-semibold underline">
        <a href={ROUTES.DASHBOARD} className="text-blue-600">
          Go back to homepage
        </a>
      </p>
    </div >
  );
};

export default PaymentSuccess;
