import React from "react";

import matchingIcon from "../../../../../assets/images/port-icons/Matching.svg";
import notMatchingIcon from "../../../../../assets/images/port-icons/Not-matching.svg";
import { formatCash } from "../../../../../utils/common";
import { HEALTH_INSURANCE_COMPARE_KEYS, PRE_POST_HOSPITALIZATION_KEY, SUM_INSURED_KEY } from "../../../../../utils/constants/porting";

function CompareCard({ type, isMatching, actualValue, suggestedValue }) {
	let cardProps = HEALTH_INSURANCE_COMPARE_KEYS[type];
	const round_to_lowest_50000 = (number) => {
		if (number > 500000) {
			return number - (number % 500000);
		} else {
			return number;
		}
	};

	return (
		<div
			className={`pl-1 rounded-2xl shadow-xl ${isMatching || isMatching == null
				? "bg-[#44D7B7]"
				: "bg-[#FF5C5C]"
				}`}
		>
			<div className="flex flex-col pb-2 w-full bg-white rounded-2xl mt-0 shadow-[0_3px_10px_rgb(0,0,0,0.2)] max-md:mt-0 max-md:max-w-full">
				<div className="self-center w-full pb-4 px-6 bg-[#e6f0f5] rounded-t-xl">
					<div className="flex  gap-5 max-md:gap-0">
						<div className="flex flex-col justify-center w-9/12 max-md:ml-0 max-md:w-full">
							<div className="flex flex-col grow justify-center ">
								{isMatching != null && (
									<div
										className={`w-fit px-6 py-1 rounded-lg ${isMatching
											? "bg-[#44D7B7]"
											: "bg-[#FF5C5C]"
											}`}
									>
										<h3
											className={`text-sm text-left font-bold text-white`}
										>
											{isMatching
												? "Optimum Cover"
												: "Needs Attention"}
										</h3>
									</div>
								)}
								<h2 className="mt-1.5 font-bold text-xl text-indigo-950 text-opacity-70 whitespace-nowrap">
									{cardProps.title}
									{/* <br /> */}
								</h2>
								<span className="text-basic font-normal ">
									{cardProps.description}
								</span>
							</div>
						</div>
						<div className="md:flex flex-col ">
							<img
								loading="lazy"
								src={cardProps.icon}
								alt="Co-pay icon"
								className="shrink-0 max-w-full aspect-square w-[100px] max-md:mt-10"
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col max-md:max-w-full">
					<div className="shrink-0 h-0.5 border border-solid bg-zinc-500 bg-opacity-20 border-zinc-500 border-opacity-20 max-md:max-w-full"></div>
					<div className="flex px-6 gap-1 justify-between self-end max-w-full w-[482px]">
						{isMatching != null && (
							<>
								<div className="flex flex-col  py-2">
									<div
										className={`flex flex-col justify-center ${isMatching
											? "text-teal-400"
											: "text-red-400"
											} `}
									>
										<div
											className={`justify-center text-xs px-4 py-2.5 text-center font-bold  rounded-xl max-md:px-2 ${isMatching
												? "bg-emerald-50"
												: "bg-rose-50"
												}`}
										>
											CURRENT
										</div>
									</div>
									<div className="flex items-center gap-2.5 mt-2">
										<img
											loading="lazy"
											src={
												isMatching
													? matchingIcon
													: notMatchingIcon
											}
											alt="Payment by you icon"
											className="shrink-0 self-start aspect-square w-[18px] h-full"
										/>
										<div className="flex flex-col">
											<p
												className={`font-semibold text-indigo-950 ${type === SUM_INSURED_KEY ? "text-2xl" : "text-lg"
													}`}
											>
												{typeof actualValue ===
													"boolean"
													? actualValue
														? "Included"
														: type ===
															PRE_POST_HOSPITALIZATION_KEY
															? "Not ideal"
															: "Not included"
													: formatCash(actualValue)}
											</p>
										</div>
									</div>
								</div>
								{isMatching === false && (
									<>
										<div className=" shrink-0 border border-solid bg-zinc-500 bg-opacity-20 border-zinc-500 border-opacity-20"></div>
										{/* <div className="md:hidden flex w-full shrink-0 h-0.5 border border-solid bg-zinc-500 bg-opacity-20 border-zinc-500 border-opacity-20 "></div> */}
									</>
								)}
							</>
						)}
						{isMatching !== true && (
							<div className="flex flex-col items-center py-2">
								<div className="flex flex-col justify-center text-teal-400">
									<div className="justify-center text-xs px-4 py-2.5 bg-emerald-50 text-center font-bold rounded-xl max-md:pl-5">
										RECOMMENDED
									</div>
								</div>
								<div className="flex flex-col ">
									<div className="flex gap-2.5 mt-2">
										<img
											loading="lazy"
											src={matchingIcon}
											alt="No co-pay required icon"
											className="shrink-0 self-start aspect-square w-[18px] h-full"
										/>
										<div className="flex flex-col">
											<p className="text-lg font-semibold text-indigo-950">
												{typeof suggestedValue ===
													"boolean"
													? suggestedValue
														? type ===
															PRE_POST_HOSPITALIZATION_KEY
															? "60-120 Days"
															: "Required"
														: "Not Required"
													: formatCash(
														suggestedValue
													)}
											</p>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="shrink-0 h-0.5 border border-solid bg-zinc-500 bg-opacity-20 border-zinc-500 border-opacity-20 max-md:max-w-full"></div>
				<div className="m-2 flex flex-row items-start max-md:max-w-full">
					<div className="mt-2">
						<svg
							className="h-6 w-6 mr-2 text-yellow-500"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
					</div>
					<div className="flex items-center md:h-12">
						<p className="text-xs pt-1">
							{HEALTH_INSURANCE_COMPARE_KEYS[type][
								isMatching ? "matchingInfo" : "notMatchingInfo"
							] +
								(type === SUM_INSURED_KEY && isMatching
									? formatCash(
										round_to_lowest_50000(actualValue)
									)
									: "")}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CompareCard;
